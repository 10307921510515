import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography } from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

const ContactFormatter = ({ canView, row }) => {
    const { first_lastname, id, name, second_lastname } = get(
        row,
        'contact',
        {},
    );
    if (canView)
        return (
            <Link
                color="inherit"
                component={RouterLink}
                to={`/record/${id}`}
                underline="none"
            >
                <Typography variant="caption" fontWeight="bold">
                    {`${name} ${first_lastname} ${second_lastname}`}
                </Typography>
            </Link>
        );
    return (
        <Typography variant="caption">
            {`${name} ${first_lastname} ${second_lastname}`}
        </Typography>
    );
};

ContactFormatter.propTypes = {
    canView: PropTypes.bool,
    row: PropTypes.object,
};

export default withPermissions(PERMISSION_LIST.CONTACTS_PERMISSION)(
    ContactFormatter,
);
