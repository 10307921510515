import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Card,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
} from 'sunwise-ui';

import {
    BulkEditionMenu,
    InputSearch,
    ManageCsvDropdown,
    StatusFilterSelect,
} from 'common/components';
import { getAgentOptions } from 'common/utils/helpers';
import { getIsOnlyContacts } from 'common/utils/helpers/session';

const BulkOperationsCard = ({
    agents,
    canDelete,
    canModify,
    downloadFile,
    filterData,
    handleClickBulkItems,
    handleOnChangeSearch,
    handleOnSelectAgent,
    handleOnSelectStatus,
    selectionModel,
    setSelectionModel,
    uploadFile,
}) => {
    const { t } = useTranslation();
    const isArchivedList = filterData?.status === 'archived';
    const isOnlyContacts = getIsOnlyContacts();
    const callback = () => setSelectionModel([]);

    return (
        <Card>
            <Card.Body>
                <Grid container alignItems="end">
                    <Grid
                        item
                        lg={
                            isOnlyContacts
                                ? isEmpty(selectionModel)
                                    ? 14
                                    : 10
                                : isEmpty(selectionModel)
                                  ? 11
                                  : 7
                        }
                        sx={{ order: 1 }}
                        xs={16}
                    >
                        <InputSearch
                            id="input-search"
                            onChange={handleOnChangeSearch}
                            placeholder={t('Search contact')}
                            value={filterData?.searchText}
                        />
                    </Grid>

                    <Grid
                        className="__userguiding_contact_filter_by"
                        id="contact-filter-by"
                        item
                        lg={3}
                        md={isEmpty(selectionModel) ? 9 : 6}
                        sx={{ order: { lg: 2, xs: 3 } }}
                        xs={18}
                    >
                        <StatusFilterSelect
                            filterBy={filterData?.status}
                            handleSelected={handleOnSelectStatus}
                            variant="standard"
                        />
                    </Grid>

                    {!isOnlyContacts && (
                        <Grid
                            item
                            xs={18}
                            md={isEmpty(selectionModel) ? 9 : 6}
                            lg={3}
                            sx={{ order: { xs: 4, lg: 3 } }}
                        >
                            <FormControl fullWidth size="small">
                                <InputLabel>{t('User')}</InputLabel>
                                <Select
                                    label={t('User')}
                                    onChange={(e) =>
                                        handleOnSelectAgent(e.target)
                                    }
                                    value={filterData?.agentId}
                                    variant="standard"
                                >
                                    {getAgentOptions(agents).map(
                                        ({ label, value, disabled }) => (
                                            <MenuItem
                                                disabled={disabled}
                                                key={`agent-select-${value}-${label}`}
                                                value={value}
                                            >
                                                {label}
                                            </MenuItem>
                                        ),
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}

                    {!isEmpty(selectionModel) && (
                        <Grid
                            item
                            lg={4}
                            md={6}
                            sx={{ order: { lg: 4, xs: 5 } }}
                            xs={18}
                        >
                            <BulkEditionMenu
                                callback={callback}
                                items={[
                                    {
                                        label: t('Assign new agent'),
                                        handleClick: () =>
                                            handleClickBulkItems(
                                                selectionModel,
                                                'reasign_agent',
                                                filterData,
                                                callback,
                                            ),
                                        visible: canModify,
                                    },
                                    {
                                        label: t('Archive'),
                                        handleClick: () =>
                                            handleClickBulkItems(
                                                selectionModel,
                                                'archived',
                                                filterData,
                                                callback,
                                            ),
                                        visible: canDelete && !isArchivedList,
                                    },
                                    {
                                        label: t('Unarchive'),
                                        handleClick: () =>
                                            handleClickBulkItems(
                                                selectionModel,
                                                'active',
                                                filterData,
                                                callback,
                                            ),
                                        visible: canDelete && isArchivedList,
                                    },
                                ]}
                                selectionModel={selectionModel}
                            />
                        </Grid>
                    )}

                    <Grid
                        item
                        lg={1}
                        sx={{ order: { xs: 2, lg: 5 }, textAlign: 'right' }}
                        xs={2}
                    >
                        <ManageCsvDropdown
                            canModify={canModify}
                            handleDownload={downloadFile}
                            handleUpload={uploadFile}
                        />
                    </Grid>
                </Grid>
            </Card.Body>
        </Card>
    );
};

BulkOperationsCard.propTypes = {
    agents: PropTypes.array,
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    downloadFile: PropTypes.func,
    filterData: PropTypes.object,
    handleClickBulkItems: PropTypes.func,
    handleOnChangeSearch: PropTypes.func,
    handleOnSelectAgent: PropTypes.func,
    handleOnSelectStatus: PropTypes.func,
    selectionModel: PropTypes.array,
    setSelectionModel: PropTypes.func,
    uploadFile: PropTypes.func,
};

export default BulkOperationsCard;
