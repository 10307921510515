import { NAME } from './constants';

export const FETCH_AGENTS = `${NAME}/FETCH_AGENTS`;
export const FETCH_AGENTS_FAILURE = `${NAME}/FETCH_AGENTS_FAILURE`;
export const FETCH_AGENTS_SUCCESS = `${NAME}/FETCH_AGENTS_SUCCESS`;

export const FETCH_DASHBOARD_CONFIG = `${NAME}/FETCH_DASHBOARD_CONFIG`;
export const FETCH_DASHBOARD_CONFIG_FAILURE = `${NAME}/FETCH_DASHBOARD_CONFIG_FAILURE`;
export const FETCH_DASHBOARD_CONFIG_SUCCESS = `${NAME}/FETCH_DASHBOARD_CONFIG_SUCCESS`;

export const FETCH_RATES = `${NAME}/FETCH_RATES`;
export const FETCH_RATES_FAILURE = `${NAME}/FETCH_RATES_FAILURE`;
export const FETCH_RATES_SUCCESS = `${NAME}/FETCH_RATES_SUCCESS`;

export const FETCH_COMMERCIAL_OFFERS = `${NAME}/FETCH_COMMERCIAL_OFFERS`;
export const FETCH_COMMERCIAL_OFFERS_FAILURE = `${NAME}/FETCH_COMMERCIAL_OFFERS_FAILURE`;
export const FETCH_COMMERCIAL_OFFERS_SUCCESS = `${NAME}/FETCH_COMMERCIAL_OFFERS_SUCCESS`;

export const FETCH_COMMERCIAL_OFFERS_BY_RATE = `${NAME}/FETCH_COMMERCIAL_OFFERS_BY_RATE`;
export const FETCH_COMMERCIAL_OFFERS_BY_RATE_FAILURE = `${NAME}/FETCH_COMMERCIAL_OFFERS_BY_RATE_FAILURE`;
export const FETCH_COMMERCIAL_OFFERS_BY_RATE_SUCCESS = `${NAME}/FETCH_COMMERCIAL_OFFERS_BY_RATE_SUCCESS`;

export const FETCH_CONTACTS_TRACKINGS = `${NAME}/FETCH_CONTACTS_TRACKINGS`;
export const FETCH_CONTACTS_TRACKINGS_FAILURE = `${NAME}/FETCH_CONTACTS_TRACKINGS_FAILURE`;
export const FETCH_CONTACTS_TRACKINGS_SUCCESS = `${NAME}/FETCH_CONTACTS_TRACKINGS_SUCCESS`;

export const FETCH_CONTACTS_TRACKINGS_AGENTS = `${NAME}/FETCH_CONTACTS_TRACKINGS_AGENTS`;
export const FETCH_CONTACTS_TRACKINGS_AGENTS_FAILURE = `${NAME}/FETCH_CONTACTS_TRACKINGS_AGENTS_FAILURE`;
export const FETCH_CONTACTS_TRACKINGS_AGENTS_SUCCESS = `${NAME}/FETCH_CONTACTS_TRACKINGS_AGENTS_SUCCESS`;

export const FETCH_LAST_CONTACTS = `${NAME}/FETCH_LAST_CONTACTS`;
export const FETCH_LAST_CONTACTS_FAILURE = `${NAME}/FETCH_LAST_CONTACTS_FAILURE`;
export const FETCH_LAST_CONTACTS_SUCCESS = `${NAME}/FETCH_LAST_CONTACTS_SUCCESS`;

export const FETCH_PROJECTS_COUNTERS = `${NAME}/FETCH_PROJECTS_COUNTERS`;
export const FETCH_PROJECTS_COUNTERS_FAILURE = `${NAME}/FETCH_PROJECTS_COUNTERS_FAILURE`;
export const FETCH_PROJECTS_COUNTERS_SUCCESS = `${NAME}/FETCH_PROJECTS_COUNTERS_SUCCESS`;

export const FETCH_PROJECTS_SALES_STAGE = `${NAME}/FETCH_PROJECTS_SALES_STAGE`;
export const FETCH_PROJECTS_SALES_STAGE_FAILURE = `${NAME}/FETCH_PROJECTS_SALES_STAGE_FAILURE`;
export const FETCH_PROJECTS_SALES_STAGE_SUCCESS = `${NAME}/FETCH_PROJECTS_SALES_STAGE_SUCCESS`;

export const FETCH_PROPOSALS_TOTALS = `${NAME}/FETCH_PROPOSALS_TOTALS`;
export const FETCH_PROPOSALS_TOTALS_FAILURE = `${NAME}/FETCH_PROPOSALS_TOTALS_FAILURE`;
export const FETCH_PROPOSALS_TOTALS_SUCCESS = `${NAME}/FETCH_PROPOSALS_TOTALS_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const SAVE_DASHBOARD_CONFIG = `${NAME}/SAVE_DASHBOARD_CONFIG`;
export const SAVE_DASHBOARD_CONFIG_FAILURE = `${NAME}/SAVE_DASHBOARD_CONFIG_FAILURE`;
export const SAVE_DASHBOARD_CONFIG_SUCCESS = `${NAME}/SAVE_DASHBOARD_CONFIG_SUCCESS`;
