import { number, object } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { NUMBER_TYPE_TEXT, REQUIRED_TEXT } = getValidationTexts();

    return object().shape({
        price_per_watt: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .nullable(),
        subtotal_amount: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .nullable(),
    });
};
