import i18next from 'i18next';
import get from 'lodash/get';

import { duplicateItem } from 'common/api/v1/proposalsSummary';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    DUPLICATE_SUMMARY,
    DUPLICATE_SUMMARY_FAILURE,
    DUPLICATE_SUMMARY_SUCCESS,
} from '../actionTypes';
import { proposalSummaryActions } from '../reducer';

import fetchSummaries from './fetchSummaries';

export default (values, isArchivedFilter) => (dispatch) => {
    dispatch(proposalSummaryActions[DUPLICATE_SUMMARY]());

    const id = get(values, 'id', null);
    const projectId = get(values.project, 'id', null);

    duplicateItem(id, {
        name: values.name,
    })
        .then(() => {
            dispatch(proposalSummaryActions[DUPLICATE_SUMMARY_SUCCESS]());
            dispatch(alerts.actions.close());
            dispatch(
                fetchSummaries({ isArchived: isArchivedFilter, projectId }),
            );
            showToast({
                body: i18next.t('Successfully duplicated'),
            });
        })
        .catch((error) => {
            dispatch(
                proposalSummaryActions[DUPLICATE_SUMMARY_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
