import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Card } from 'sunwise-ui';

import PlaceholderCardContactsDashboard from 'common/components/placeholder/PlaceholderCardContactsDashboard';
import TitleWithDetail from 'common/components/TitleWithDetail';
import { useBreakpoint } from 'common/hooks';
import { scroll } from 'common/utils/mixins';

import * as actions from '../actions';
import * as selectors from '../selectors';

import ProjectStatusNormalTable from './ProjectStatusNormalTable';
import ProjectStatusTransposedTable from './ProjectStatusTransposedTable';

const Container = ({
    currencyIso,
    currencyLocale,
    fetchProjectsSalesStage,
    isLoading,
    menuOptions,
    projectsSalesStageData,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    useEffect(() => {
        fetchProjectsSalesStage();
    }, []);

    return (
        <Card sx={{ mb: 0, minHeight: '100%', width: '100%' }}>
            <Card.Header
                sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <TitleWithDetail variant="body2">
                    {t('Projects status')}
                </TitleWithDetail>
                {menuOptions}
            </Card.Header>

            <PlaceholderCardContactsDashboard ready={isLoading}>
                <Card.Body
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'calc(100% - 73px)',
                        overflowY: 'auto',
                        ...scroll.object,
                    }}
                >
                    {projectsSalesStageData.columns.length > 0 ? (
                        <>
                            {breakpoint !== 'xs' && (
                                <ProjectStatusNormalTable
                                    currencyIso={currencyIso}
                                    currencyLocale={currencyLocale}
                                    projectsSalesStageData={
                                        projectsSalesStageData
                                    }
                                />
                            )}

                            {breakpoint === 'xs' && (
                                <ProjectStatusTransposedTable
                                    currencyIso={currencyIso}
                                    currencyLocale={currencyLocale}
                                    projectsSalesStageData={
                                        projectsSalesStageData
                                    }
                                />
                            )}
                        </>
                    ) : (
                        <div>
                            <small>
                                <p>{t('Does not yet have proposals')}</p>
                            </small>
                        </div>
                    )}
                </Card.Body>
            </PlaceholderCardContactsDashboard>
        </Card>
    );
};

const mapStateToProps = createStructuredSelector({
    isLoading: selectors.getProjectsSalesStageIsLoading,
    projectsSalesStageData: selectors.getProjectsSalesStageData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchProjectsSalesStage: () => dispatch(actions.fetchProjectsSalesStage()),
});

Container.propTypes = {
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    fetchProjectsSalesStage: PropTypes.func,
    isLoading: PropTypes.bool,
    menuOptions: PropTypes.object,
    projectsSalesStageData: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
