import React, { useState } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from 'sunwise-ui';

import { numberFormat } from 'common/utils/helpers';

const PriceRangeDropDown = ({ ranges }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);

    const currencyIso = 'USD';
    const currencyLocale = 'en-Es';
    const currencySymbol = '$';
    const open = Boolean(anchorEl);

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const renderContent = () => {
        if (isEmpty(ranges))
            return (
                <Typography fontWeight={500} variant="body2">
                    {t('There is no range configuration for this plan')}
                </Typography>
            );
        return (
            <TableContainer component={Paper}>
                <Table size="small" sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                {i18next.t('Min')}
                            </TableCell>
                            <TableCell align="center">
                                {i18next.t('Max')}
                            </TableCell>
                            <TableCell align="center">
                                {i18next.t('Cost')} ({currencyIso})
                            </TableCell>
                            <TableCell align="center">
                                {i18next.t('Margin')}
                            </TableCell>
                            <TableCell align="center">
                                {i18next.t('Final price')} ({currencyIso})
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ranges.map((item, index) => (
                            <TableRow key={`range-${index}`}>
                                <TableCell align="center">
                                    {item?.min}
                                </TableCell>
                                <TableCell align="center">{item.max}</TableCell>
                                <TableCell align="center">
                                    {numberFormat(item?.cost, {
                                        currency: currencyIso,
                                        decimals: 2,
                                        locale: currencyLocale,
                                        style: 'currency',
                                        symbol: currencySymbol,
                                    })}
                                </TableCell>
                                <TableCell align="center">
                                    {item?.margin}%
                                </TableCell>
                                <TableCell align="center">
                                    {numberFormat(item?.price, {
                                        currency: currencyIso,
                                        decimals: 2,
                                        locale: currencyLocale,
                                        style: 'currency',
                                        symbol: currencySymbol,
                                    })}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    return (
        <>
            <IconButton onClick={handleClick} sx={{ ml: 1 }}>
                <InfoIcon />
            </IconButton>

            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem>{renderContent()}</MenuItem>
            </Menu>
        </>
    );
};

PriceRangeDropDown.propTypes = {
    ranges: PropTypes.array,
};

export default PriceRangeDropDown;
