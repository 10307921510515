import React from 'react';

import { TableHead, TableRow } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';

import { COLOR_DELTA, generateColorTone } from 'common/utils/helpersChart';

import HeaderCell from './HeaderCell';

const TableHeader = ({ columnGroups }) => (
    <TableHead>
        <TableRow>
            {columnGroups.map((group) => (
                <HeaderCell
                    bgColor={group.bgColor}
                    colSpan={group.columns?.length || 0}
                    isCollapsible={group.isCollapsible}
                    isExpanded={group.isExpanded}
                    isSticky={group.isSticky}
                    key={group.label + group.bgColor}
                    label={group.label}
                    onClick={group.onClick}
                />
            ))}
        </TableRow>

        <TableRow>
            {columnGroups.map((group) =>
                group.columns.map((column, index) => (
                    <HeaderCell
                        bgColor={
                            group.useSameColor
                                ? group.bgColor
                                : generateColorTone(
                                      group.bgColor,
                                      index * COLOR_DELTA,
                                  )
                        }
                        isExpanded={group.isExpanded}
                        isSticky={group.isSticky}
                        key={group.label + column.label}
                        label={column.label}
                        onClick={group.onClick}
                    />
                )),
            )}
        </TableRow>
    </TableHead>
);

TableHeader.propTypes = {
    columnGroups: PropTypes.array,
};

export default TableHeader;
