import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useFieldArray, useWatch } from 'react-hook-form';
import { Box } from 'sunwise-ui';

import { ReactHookFormSelect } from 'common/components/form/bootstrap';

const ReduxFieldArrayItems = ({
    control,
    disabled,
    name,
    offersToSelect,
    setSelectedOffers,
}) => {
    const { fields } = useFieldArray({ control, name });
    const fieldsKeys = useWatch({ control, name });

    useEffect(() => {
        const offers = fieldsKeys
            .filter((item) => item.commercial_offer)
            .map((item) => item.commercial_offer);
        setSelectedOffers(offers);
    }, [fieldsKeys]);

    return fields.map((item, i) => (
        <Box key={`catalog-field-${i}`}>
            <ReactHookFormSelect
                control={control}
                disabled={disabled}
                label={item?.name}
                name={`${name}.${i}.commercial_offer`}
                options={offersToSelect}
            />
        </Box>
    ));
};

ReduxFieldArrayItems.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    error: PropTypes.object,
    name: PropTypes.string,
    offersToSelect: PropTypes.array,
    setSelectedOffers: PropTypes.func,
};

export default ReduxFieldArrayItems;
