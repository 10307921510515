import React, { useContext, useEffect, useState } from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from 'sunwise-ui';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';
import {
    ReactHookFormInput,
    ReactHookFormInputDatePicker,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';
import { DEBOUNCE_TIME_500 } from 'common/constants';
import { LoadingContext } from 'common/utils/contexts';

import { buildFilterQuery } from '../helpers';

import FilterDropdown from './FilterDropdown';
import ViewModeSelector from './ViewModeSelector';

const MIN_DATE = new Date(2005, 0, 1);

const Filters = ({
    downloadProjectsFile,
    filterItemSelected,
    filterName,
    handleSetViewMode,
    isLoadingFunnelProjects,
    isLoadingListProjects,
    selectFilterItem,
    setFilterName,
    viewMode,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);
    const [timer, setTimer] = useState(null);

    const initialValues = { ...filterItemSelected };
    const { control, reset } = useForm({ defaultValues: initialValues });
    useEffect(() => reset(initialValues), []);

    const handleChange = (value) => {
        clearTimeout(timer);
        setTimer(setTimeout(() => setFilterName(value), DEBOUNCE_TIME_500));
    };

    const tempfilterTypeItemsToSelect = [
        {
            label: t('None'),
            value: '',
        },
        {
            label: t('User'),
            value: 'agent',
        },
        {
            label: t('Rate'),
            value: 'rate',
        },
    ];

    const tempfilterAfterSales = [
        {
            label: t('All text', { count: 2 }),
            value: '',
        },
        {
            label: t('With after sales'),
            value: true,
        },
        {
            label: t('Without after sales'),
            value: false,
        },
    ];

    const filterTypeItemsToSelect =
        viewMode === 'column'
            ? tempfilterTypeItemsToSelect
            : [
                  ...tempfilterTypeItemsToSelect,
                  { label: t('Status'), value: 'status' },
              ];

    const isLoadingProjects =
        viewMode === 'column' ? isLoadingFunnelProjects : isLoadingListProjects;

    return (
        <Box sx={{ mb: 2 }}>
            <Grid container sx={{ mb: 1, alignItems: 'center' }}>
                <Grid item xs>
                    <ReactHookFormInput
                        control={control}
                        id="projects-search-bar"
                        label={' '}
                        name="search"
                        onChange={(e) => handleChange(e.target.value)}
                        placeholder={t('Search')}
                        prepend={<SearchIcon />}
                        variant="standard"
                    />
                </Grid>

                <Grid item>
                    <ViewModeSelector
                        handleSetViewMode={handleSetViewMode}
                        viewMode={viewMode}
                    />
                </Grid>

                <Grid item>
                    <DropdownCardMenu
                        items={[
                            {
                                handleClick: () => {
                                    const filteredQuiery = buildFilterQuery({
                                        ...filterItemSelected,
                                        project_name: filterName,
                                    });
                                    loadingContext.openLoading(
                                        t('Preparing download').concat('...'),
                                    );
                                    downloadProjectsFile(filteredQuiery, () =>
                                        loadingContext.closeLoading(),
                                    );
                                },
                                icon: <DownloadIcon fontSize="small" />,
                                label: t('Download', { count: 1 }),
                            },
                        ]}
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={18} md={10}>
                    <Box
                        display="flex"
                        alignItems="center"
                        gap={2}
                        justifyContent="end"
                    >
                        <ReactHookFormSelect
                            control={control}
                            id="projects-filter-1"
                            label={t('Filter by')}
                            name="type"
                            onChange={(e) => {
                                selectFilterItem({
                                    ...filterItemSelected,
                                    agent: '',
                                    value: '',
                                    status: '',
                                    type: e.target.value,
                                });
                            }}
                            options={filterTypeItemsToSelect}
                            variant="standard"
                        />

                        <FilterDropdown
                            filterItemSelected={filterItemSelected}
                            isLoadingProjects={isLoadingProjects}
                            selectFilterItem={selectFilterItem}
                        />

                        <ReactHookFormSelect
                            control={control}
                            id="projects-filter-aftersales"
                            label={t('Project', { count: 2 })}
                            name="aftersales"
                            onChange={(e) => {
                                selectFilterItem({
                                    ...filterItemSelected,
                                    aftersales: e.target.value,
                                });
                            }}
                            options={tempfilterAfterSales}
                            variant="standard"
                        />
                    </Box>
                </Grid>

                <Grid item xs={18} md={4}>
                    <ReactHookFormInputDatePicker
                        control={control}
                        disableFuture
                        label={t('Initial date')}
                        minDate={MIN_DATE}
                        name="initial_date"
                        onChange={(e) =>
                            selectFilterItem({
                                ...filterItemSelected,
                                initial_date: e,
                            })
                        }
                        variant="standard"
                    />
                </Grid>

                <Grid item xs={18} md={4}>
                    <ReactHookFormInputDatePicker
                        control={control}
                        disableFuture
                        label={t('Final date')}
                        minDate={MIN_DATE}
                        name="final_date"
                        onChange={(e) =>
                            selectFilterItem({
                                ...filterItemSelected,
                                final_date: e,
                            })
                        }
                        variant="standard"
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

Filters.propTypes = {
    downloadProjectsFile: PropTypes.func,
    filterItemSelected: PropTypes.object,
    filterName: PropTypes.string,
    handleSetViewMode: PropTypes.func,
    isLoadingFunnelProjects: PropTypes.bool,
    isLoadingListProjects: PropTypes.bool,
    selectFilterItem: PropTypes.func,
    setFilterName: PropTypes.func,
    viewMode: PropTypes.string,
};

export default Filters;
