import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from 'sunwise-ui';

import { ReactHookFormInputDatePicker } from 'common/components/form/bootstrap';
import { DEBOUNCE_TIME_1000 } from 'common/constants';

import { MIN_DATE } from '../../../afterSalesSettings/constants';

const DateFields = ({ control, disabled, handleOnChangeDate }) => {
    const [timer, setTimer] = useState(null);
    const { t } = useTranslation();

    const handleOnChange = () => {
        clearTimeout(timer);
        const newTimer = setTimeout(
            () => handleOnChangeDate(),
            DEBOUNCE_TIME_1000,
        );
        setTimer(newTimer);
    };

    return (
        <Grid container>
            <Grid item xs={18} sm={9}>
                <ReactHookFormInputDatePicker
                    control={control}
                    disableFuture
                    disabled={disabled}
                    label={t('Initial date')}
                    minDate={MIN_DATE}
                    name="initial_date"
                    onChange={handleOnChange}
                    variant="standard"
                />
            </Grid>

            <Grid item xs={18} sm={9}>
                <ReactHookFormInputDatePicker
                    control={control}
                    disableFuture
                    disabled={disabled}
                    label={t('Final date')}
                    minDate={MIN_DATE}
                    name="final_date"
                    onChange={handleOnChange}
                    variant="standard"
                />
            </Grid>
        </Grid>
    );
};

DateFields.propTypes = {
    control: PropTypes.any,
    disabled: PropTypes.bool,
    handleOnChangeDate: PropTypes.func,
};

export default DateFields;
