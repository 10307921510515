import { bulkProducts } from 'common/api/v2/bulkOfferProducts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import eventsAfterUpdate from '../../proposalGeneratorOneColumn/actions/eventsAfterUpdate';
import {
    SAVE_BULK_UPDATE_PRODUCTS,
    SAVE_BULK_UPDATE_PRODUCTS_FAILURE,
    SAVE_BULK_UPDATE_PRODUCTS_SUCCESS,
} from '../actionTypes';
import { proposalGeneratorQuoteActions } from '../reducer';
import * as selectors from '../selectors';

import closeModal from './closeModal';

export default (proposalId, values, deleteAction = null) =>
    (dispatch, getState) => {
        const action = selectors.getBulkAction(getState());
        dispatch(proposalGeneratorQuoteActions[SAVE_BULK_UPDATE_PRODUCTS]());

        let newValues = {
            affected_elements: values?.selectionModel,
        };
        if (deleteAction) newValues[deleteAction] = true;
        else newValues[action] = values[action];

        bulkProducts(proposalId, newValues)
            .then((response) => {
                dispatch(
                    proposalGeneratorQuoteActions[
                        SAVE_BULK_UPDATE_PRODUCTS_SUCCESS
                    ](),
                );
                dispatch(closeModal());
                dispatch(eventsAfterUpdate(response.data.data, proposalId));
                if (values.callback) values.callback();
            })
            .catch((error) => {
                dispatch(
                    proposalGeneratorQuoteActions[
                        SAVE_BULK_UPDATE_PRODUCTS_FAILURE
                    ](error?.response?.data?.errors),
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
