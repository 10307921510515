import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import { COLUMN_TYPES, CSVImporter } from '@sunwisesoftware/csv-importer';
import isNull from 'lodash/isNull';
import isNumber from 'lodash/isNumber';
import isUndefined from 'lodash/isUndefined';
import PropTypes from 'prop-types';
import { useFieldArray, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, Divider, Grid, IconButton } from 'sunwise-ui';

import { LabelError, TitleWithDetail } from 'common/components';
import AccordionCard from 'common/components/accordions/AccordionCard';
import ReactHookFormIntlNumberInput from 'common/components/form/bootstrap/ReactHookFormIntlNumberInput';
import getValidationTexts from 'common/utils/getValidationTexts';
import {
    calculateFinalCost,
    calculateMargin,
    calculateRangesPriceFrom,
    calculateRangesPriceTo,
    calculateRangesPriceToByNextRange,
    getCurrencyById,
    getCurrencyIso,
    getLabelWithCurrency,
} from 'common/utils/helpers';
import { getCsvImporterTranslations } from 'common/utils/helpers/csv';

const CostArrayRanges = ({
    control,
    currencies,
    getValues,
    isDisabled,
    name,
    setValue,
    title,
}) => {
    const { t } = useTranslation();

    const { fields, append, remove } = useFieldArray({
        control,
        name,
    });

    const { getMaxValueText, getMinValueText } = getValidationTexts();

    const [_currency, rangePrices] = useWatch({
        control,
        name: ['currency', name],
    });
    const currency = getCurrencyById(currencies, _currency);
    const currencyIso = getCurrencyIso(currency);

    const handleOnChange = (i) => {
        let lastMax = rangePrices[i]?.max;

        for (let j = i + 1; j < rangePrices.length; j++) {
            let range = rangePrices[j];
            if (!isNull(range)) {
                let lastMin = Number(range.min);

                if (Number(lastMax) !== lastMin - 1) {
                    lastMin = calculateRangesPriceFrom(lastMax);

                    setValue(`${name}.${j}.min`, lastMin);
                }
                if (Number(lastMin) > Number(range.max)) {
                    lastMax = calculateRangesPriceTo(lastMin);

                    setValue(`${name}.${j}.max`, lastMax);
                } else {
                    lastMax = range.max;
                }
            }
        }
    };

    const handleNewItem = (lastField) => {
        const newMin = !isUndefined(lastField)
            ? calculateRangesPriceFrom(lastField.max)
            : 1;
        const newMax = calculateRangesPriceTo(newMin);

        append({
            cost: 0,
            margin: 0,
            max: newMax,
            min: newMin,
            price: 0,
        });
    };

    const handleRemove = (index) => {
        if (index === 1) {
            setValue(`${name}.0.min`, 1);
            setValue(`${name}.0.max`, 10);
        }
        remove(index);

        setValue(name, getValues(name));
    };

    const validateToPriceField = (event, value, i) => {
        event.preventDefault();

        const currentRange = getValues(`${name}.${i}`);

        if (!value || parseInt(currentRange.min) > parseInt(value)) {
            let lastMax = calculateRangesPriceTo(currentRange.min);

            const nextRange = getValues(`${name}.${i + 1}`);
            if (nextRange)
                lastMax = calculateRangesPriceToByNextRange(nextRange);

            setValue(`${name}.${i}.max`, lastMax);
        }
    };

    const handleEnterKey = (event, value, i) => {
        if (event.key === 'Enter') validateToPriceField(event, value, i);
    };

    const handleChangeCostValue = (i) => {
        const field = getValues(`${name}.${i}`);

        const value = field?.cost;
        if (!value) setValue(`${name}.${i}.cost`, 0);

        setValue(
            `${name}.${i}.price`,
            calculateFinalCost(
                !isNumber(parseInt(value)) ? 0 : value,
                field?.margin,
            ),
        );
    };

    const handleChangeMarginValue = (i) => {
        let value = getValues(`${name}.${i}.margin`);

        if (!value) setValue(`${name}.${i}.margin`, 0);
        else if (parseFloat(value) >= 100) {
            setValue(`${name}.${i}.margin`, 99.99);
            value = 99.99;
        }

        setValue(
            `${name}.${i}.price`,
            calculateFinalCost(
                getValues(`${name}.${i}.cost`),
                !isNumber(parseInt(value)) ? 0 : value,
            ),
        );
    };

    const handleChangeFinalCostValue = (i) => {
        let value = getValues(`${name}.${i}.price`),
            costValue = getValues(`${name}.${i}.cost`);

        if (!value || parseFloat(value) < parseFloat(costValue)) {
            setValue(`${name}.${i}.price`, costValue);
            setValue(`${name}.${i}.margin`, 0);
        } else if (parseFloat(costValue) === 0) {
            setValue(`${name}.${i}.margin`, 0);
            setValue(`${name}.${i}.cost`, value);
        } else if (calculateMargin(value, costValue) >= 100) {
            setValue(`${name}.${i}.margin`, 99.99);
            setValue(
                `${name}.${i}.price`,
                calculateFinalCost(costValue, 99.99),
            );
        } else
            setValue(`${name}.${i}.margin`, calculateMargin(value, costValue));
    };

    return (
        <AccordionCard
            defaultExpanded
            customTitle={
                <Box alignItems="center" display="flex" sx={{ width: '100%' }}>
                    <TitleWithDetail variant="body2">{title}</TitleWithDetail>
                    <Box sx={{ ml: 'auto', width: '150px' }}>
                        <CSVImporter
                            buttonProps={{
                                disabled: isDisabled,
                                size: 'small',
                                startIcon: <UploadIcon />,
                                sx: { '&.MuiButton-root': { mb: 0 } },
                                type: 'button',
                                variant: 'text',
                            }}
                            buttonText={t('Import CSV')}
                            columns={[
                                {
                                    columnName: 'max',
                                    displayLabel: t('Maximum'),
                                    infoHint: t(
                                        'Maximum number of units that will apply this price',
                                    ),
                                    matchAliases: [
                                        'final',
                                        'hasta',
                                        'maximum',
                                        'máximo',
                                    ],
                                    required: true,
                                    type: COLUMN_TYPES.INTEGER,
                                    unique: true,
                                },
                                {
                                    columnName: 'cost',
                                    displayLabel: t('Cost'),
                                    matchAliases: [
                                        'amount',
                                        'cost',
                                        'costo',
                                        'monto',
                                        'precio',
                                        'price',
                                    ],
                                    required: true,
                                    type: COLUMN_TYPES.NUMBER,
                                },
                                {
                                    columnName: 'margin',
                                    displayLabel: `${t('Margin')} (%)`,
                                    matchAliases: ['margin', 'margen'],
                                    type: COLUMN_TYPES.NUMBER,
                                    validate: (value) => {
                                        if (value < 0)
                                            return {
                                                success: false,
                                                message: getMinValueText(0),
                                            };

                                        if (value >= 100)
                                            return {
                                                success: false,
                                                message: getMaxValueText(99.99),
                                            };

                                        return { success: true };
                                    },
                                },
                            ]}
                            returnFormattedData
                            onImport={(result) => {
                                const validData = result
                                    .filter((range) => range.max)
                                    .sort((a, b) => a.max - b.max);

                                const newRanges = validData.map((range, i) => {
                                    const cost = range.cost || 0;
                                    const margin = range.margin || 0;
                                    const price = calculateFinalCost(
                                        cost,
                                        margin,
                                    );

                                    const min = validData[i - 1]?.max + 1 || 1;
                                    const max = range.max;

                                    return {
                                        cost,
                                        price,
                                        margin,
                                        min,
                                        max,
                                    };
                                });

                                setValue(name, newRanges.filter(Boolean));
                            }}
                            textOverrides={getCsvImporterTranslations()}
                            title={t('Import CSV')}
                        />
                    </Box>
                </Box>
            }
            sxCard={{ boxShadow: 'none', mb: 0 }}
        >
            {fields.map((item, i) => (
                <Grid container mt={i > 0 ? 1 : 0} mb={1} key={item.id}>
                    <Grid item xs={18} lg={3}>
                        <ReactHookFormIntlNumberInput
                            allowNegativeValue={false}
                            control={control}
                            disabled
                            fullWidth
                            label={t('Minimum')}
                            name={`${name}.${i}.min`}
                        />
                    </Grid>
                    <Grid item xs={18} lg={3}>
                        <ReactHookFormIntlNumberInput
                            allowNegativeValue={false}
                            allowDecimals={false}
                            control={control}
                            disabled={isDisabled}
                            fullWidth
                            label={t('Maximum')}
                            name={`${name}.${i}.max`}
                            onBlur={({ event, value }) => {
                                validateToPriceField(event, value, i);
                                handleOnChange(i);
                            }}
                            onKeyDown={({ event, value }) =>
                                handleEnterKey(event, value, i)
                            }
                        />
                    </Grid>
                    <Grid item xs={18} md={4}>
                        <ReactHookFormIntlNumberInput
                            allowNegativeValue={false}
                            control={control}
                            disabled={isDisabled}
                            fullWidth
                            label={getLabelWithCurrency(currencyIso, t('Cost'))}
                            min="0"
                            name={`${name}.${i}.cost`}
                            onBlur={() => handleChangeCostValue(i)}
                            prepend="$"
                        />
                    </Grid>
                    <Grid item xs={18} md={4}>
                        <ReactHookFormIntlNumberInput
                            allowNegativeValue={false}
                            append="%"
                            control={control}
                            disabled={isDisabled}
                            fullWidth
                            label={t('Margin')}
                            min="0"
                            name={`${name}.${i}.margin`}
                            onBlur={() => handleChangeMarginValue(i)}
                        />
                    </Grid>
                    <Grid item xs={18} md={4}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '5px',
                            }}
                        >
                            <ReactHookFormIntlNumberInput
                                allowNegativeValue={false}
                                control={control}
                                disabled={isDisabled}
                                fullWidth
                                label={getLabelWithCurrency(
                                    currencyIso,
                                    t('Final price'),
                                )}
                                name={`${name}.${i}.price`}
                                min="0"
                                onBlur={() => handleChangeFinalCostValue(i)}
                                prepend="$"
                            />
                            {i > 0 && i === fields.length - 1 && (
                                <IconButton
                                    onClick={() => handleRemove(i)}
                                    sx={{ mb: 2 }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={18}>
                        <Divider />
                    </Grid>
                </Grid>
            ))}

            {fields && fields.length === 0 && (
                <Grid container mt={2}>
                    <Grid item xs>
                        <LabelError type="error">
                            {t('Enter at least one range')}
                        </LabelError>
                    </Grid>
                </Grid>
            )}

            {fields && fields.length >= 0 && (
                <Grid container mt={2}>
                    <Grid item xs={18} textAlign="right">
                        <Button
                            endIcon={<AddIcon />}
                            disabled={isDisabled}
                            onClick={() =>
                                handleNewItem(
                                    getValues(`${name}.${fields.length - 1}`),
                                )
                            }
                            sx={{ width: { lg: 'auto', xs: '100%' } }}
                            type="button"
                            variant="outlined"
                        >
                            {t('Add range')}
                        </Button>
                    </Grid>
                </Grid>
            )}
        </AccordionCard>
    );
};

CostArrayRanges.propTypes = {
    control: PropTypes.object,
    currencies: PropTypes.array,
    getValues: PropTypes.func,
    isDisabled: PropTypes.bool,
    name: PropTypes.string,
    setValue: PropTypes.func,
    title: PropTypes.string,
};

export default CostArrayRanges;
