import { createSlice } from '@reduxjs/toolkit';

import {
    CREATE_REPORT,
    CREATE_REPORT_FAILURE,
    CREATE_REPORT_SUCCESS,
    DELETE_DATA,
    DELETE_DATA_FAILURE,
    DELETE_DATA_SUCCESS,
    EDIT_CONSUMPTION_HISTORY,
    FETCH_AVAILABLE_BAG,
    FETCH_AVAILABLE_BAG_FAILURE,
    FETCH_AVAILABLE_BAG_SUCCESS,
    FETCH_CONSUMPTION_HISTORY,
    FETCH_CONSUMPTION_HISTORY_FAILURE,
    FETCH_CONSUMPTION_HISTORY_SUCCESS,
    FETCH_CUPS_INFO,
    FETCH_CUPS_INFO_FAILURE,
    FETCH_CUPS_INFO_SUCCESS,
    FETCH_INITIAL_CONSUMPTION_HISTORY,
    FETCH_LISA_FILES,
    FETCH_LISA_FILES_FAILURE,
    FETCH_LISA_FILES_SUCCESS,
    FETCH_MEASURED_ENERGY,
    FETCH_MEASURED_ENERGY_FAILURE,
    FETCH_MEASURED_ENERGY_SUCCESS,
    FETCH_TODAY_AVAILABLE_BAG,
    FETCH_TODAY_AVAILABLE_BAG_FAILURE,
    FETCH_TODAY_AVAILABLE_BAG_SUCCESS,
    INITIALIZE_FORM,
    INITIALIZE_REPORT_FORM,
    RECALCULATE_CONSUMPTION_HISTORY,
    RECALCULATE_CONSUMPTION_HISTORY_FAILURE,
    RECALCULATE_CONSUMPTION_HISTORY_SUCCESS,
    RESET_FORM,
    SAVE_DATA,
    SAVE_DATA_FAILURE,
    SAVE_DATA_SUCCESS,
    SET_CONFIG_CSV_IMPORT_MODAL,
    SET_MODAL_FORM_OPEN_STATUS,
    SET_REPORT_MODAL_OPEN_STATUS,
    UPLOAD_FILE,
    UPLOAD_FILE_FAILURE,
    UPLOAD_FILE_SUCCESS,
} from './actionTypes';
import { DEMAND_METHODS, NAME } from './constants';

const INITIAL_STATE = {
    availableBag: { data: null, errors: null, isLoading: false },
    createReport: { data: null, errors: null, isLoading: false },
    deleteData: { data: null, errors: null, isLoading: false },
    consumptionHistory: {
        data: { items: [], pagination: {}, years: [] },
        errors: null,
        isLoading: false,
    },
    configCsvImportModal: { isOpen: false },
    cupsInfo: { data: null, errors: null, isLoading: false },
    lisaFiles: { data: [], errors: null, isLoading: false },
    initialValues: {
        demand: {},
        demand_method: DEMAND_METHODS.MEASUREMENT_SOURCE,
        energy: {},
        fields_keys: { demand: [], energy: [] },
        file: null,
        final_date: '',
        hasTimestampGaps: false,
        id: null,
        initial_date: '',
        project_id: '',
        provider_data: null,
        total: {},
    },
    initialValuesReport: { historical_id: '', name: '', project: '' },
    isModalFormOpen: false,
    isReportModalOpen: false,
    measuredEnergy: { data: {}, errors: null, isLoading: false },
    recalculateConsumptionHistory: {
        data: null,
        errors: null,
        isLoading: false,
    },
    saveData: { data: null, errors: null, isLoading: false },
    todayAvailableBag: { data: null, errors: null, isLoading: false },
    uploadFile: { data: null, errors: null, isLoading: false },
};

const slice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [CREATE_REPORT]: (state) => {
            state.createReport.data = null;
            state.createReport.errors = null;
            state.createReport.isLoading = true;
        },
        [CREATE_REPORT_FAILURE]: (state, action) => {
            state.createReport.errors = action.payload;
            state.createReport.isLoading = false;
        },
        [CREATE_REPORT_SUCCESS]: (state, action) => {
            state.createReport.data = action.payload;
            state.createReport.isLoading = false;
        },
        [DELETE_DATA]: (state) => {
            state.deleteData.errors = null;
            state.deleteData.isLoading = true;
        },
        [DELETE_DATA_FAILURE]: (state, action) => {
            state.deleteData.errors = action.payload;
            state.deleteData.isLoading = false;
        },
        [DELETE_DATA_SUCCESS]: (state) => {
            state.deleteData.isLoading = false;
        },
        [EDIT_CONSUMPTION_HISTORY]: (state, action) => {
            const { id, values } = action.payload;
            if (!id || !values) return;

            const index = state.consumptionHistory.data.items.findIndex(
                (item) => item.id === id,
            );

            if (index >= 0) state.consumptionHistory.data.items[index] = values;
        },
        [FETCH_AVAILABLE_BAG]: (state) => {
            state.availableBag.errors = null;
            state.availableBag.isLoading = true;
        },
        [FETCH_AVAILABLE_BAG_FAILURE]: (state, action) => {
            state.availableBag.errors = action.payload;
            state.availableBag.isLoading = false;
        },
        [FETCH_AVAILABLE_BAG_SUCCESS]: (state, action) => {
            state.availableBag.data = action.payload;
            state.availableBag.isLoading = false;
        },
        [FETCH_CONSUMPTION_HISTORY]: (state) => {
            state.consumptionHistory.errors = null;
            state.consumptionHistory.isLoading = true;
        },
        [FETCH_CONSUMPTION_HISTORY_FAILURE]: (state, action) => {
            state.consumptionHistory.errors = action.payload;
            state.consumptionHistory.isLoading = false;
        },
        [FETCH_CONSUMPTION_HISTORY_SUCCESS]: (state, action) => {
            const { data, pagination, years } = action.payload || {};
            const { current_page, per_page, total_rows } = pagination || {};

            state.consumptionHistory.data.items.push(...(data || []));
            state.consumptionHistory.data.pagination = {
                hasMore: current_page * per_page < total_rows,
                page: current_page,
                pageSize: per_page,
                total: total_rows,
            };
            state.consumptionHistory.data.years = years;

            state.consumptionHistory.isLoading = false;
        },
        [FETCH_INITIAL_CONSUMPTION_HISTORY]: (state) => {
            state.consumptionHistory.data =
                INITIAL_STATE.consumptionHistory.data;
            state.consumptionHistory.errors = null;
            state.consumptionHistory.isLoading = true;
        },
        [FETCH_CUPS_INFO]: (state) => {
            state.cupsInfo.errors = null;
            state.cupsInfo.isLoading = true;
        },
        [FETCH_CUPS_INFO_FAILURE]: (state, action) => {
            state.cupsInfo.errors = action.payload;
            state.cupsInfo.isLoading = false;
        },
        [FETCH_CUPS_INFO_SUCCESS]: (state, action) => {
            state.cupsInfo.data = action.payload;
            state.cupsInfo.isLoading = false;
        },
        [FETCH_LISA_FILES]: (state) => {
            state.lisaFiles.errors = null;
            state.lisaFiles.isLoading = true;
        },
        [FETCH_LISA_FILES_FAILURE]: (state, action) => {
            state.lisaFiles.errors = action.payload;
            state.lisaFiles.isLoading = false;
        },
        [FETCH_LISA_FILES_SUCCESS]: (state, action) => {
            state.lisaFiles.data = action.payload;
            state.lisaFiles.isLoading = false;
        },
        [FETCH_MEASURED_ENERGY]: (state) => {
            state.measuredEnergy.errors = null;
            state.measuredEnergy.isLoading = true;
        },
        [FETCH_MEASURED_ENERGY_FAILURE]: (state, action) => {
            state.measuredEnergy.errors = action.payload;
            state.measuredEnergy.isLoading = false;
        },
        [FETCH_MEASURED_ENERGY_SUCCESS]: (state, action) => {
            state.measuredEnergy.data = action.payload;
            state.measuredEnergy.isLoading = false;
        },
        [FETCH_TODAY_AVAILABLE_BAG]: (state) => {
            state.todayAvailableBag.errors = null;
            state.todayAvailableBag.isLoading = true;
        },
        [FETCH_TODAY_AVAILABLE_BAG_FAILURE]: (state, action) => {
            state.todayAvailableBag.errors = action.payload;
            state.todayAvailableBag.isLoading = false;
        },
        [FETCH_TODAY_AVAILABLE_BAG_SUCCESS]: (state, action) => {
            state.todayAvailableBag.data = action.payload;
            state.todayAvailableBag.isLoading = false;
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [INITIALIZE_REPORT_FORM]: (state, action) => {
            state.initialValuesReport = {
                ...state.initialValuesReport,
                ...action.payload,
            };
        },
        [RECALCULATE_CONSUMPTION_HISTORY]: (state) => {
            state.recalculateConsumptionHistory.errors = null;
            state.recalculateConsumptionHistory.isLoading = true;
        },
        [RECALCULATE_CONSUMPTION_HISTORY_FAILURE]: (state, action) => {
            state.recalculateConsumptionHistory.errors = action.payload;
            state.recalculateConsumptionHistory.isLoading = false;
        },
        [RECALCULATE_CONSUMPTION_HISTORY_SUCCESS]: (state, action) => {
            state.recalculateConsumptionHistory.data = action.payload;
            state.recalculateConsumptionHistory.isLoading = false;
        },
        [RESET_FORM]: (state) => {
            state.availableBag = INITIAL_STATE.availableBag;
            state.initialValues = INITIAL_STATE.initialValues;
            state.saveData = INITIAL_STATE.saveData;
            state.uploadFile = INITIAL_STATE.uploadFile;
            state.measuredEnergy = INITIAL_STATE.measuredEnergy;
        },
        [SAVE_DATA]: (state) => {
            state.saveData.data = null;
            state.saveData.errors = null;
            state.saveData.isLoading = true;
        },
        [SAVE_DATA_FAILURE]: (state, action) => {
            state.saveData.errors = action.payload;
            state.saveData.isLoading = false;
        },
        [SAVE_DATA_SUCCESS]: (state, action) => {
            state.saveData.data = action.payload;
            state.saveData.isLoading = false;
        },
        [SET_CONFIG_CSV_IMPORT_MODAL]: (state, action) => {
            state.configCsvImportModal = action.payload;
        },
        [SET_MODAL_FORM_OPEN_STATUS]: (state, action) => {
            state.isModalFormOpen = action.payload;
        },
        [SET_REPORT_MODAL_OPEN_STATUS]: (state, action) => {
            state.isReportModalOpen = action.payload;
        },
        [UPLOAD_FILE]: (state) => {
            state.uploadFile.errors = null;
            state.uploadFile.isLoading = true;
        },
        [UPLOAD_FILE_FAILURE]: (state, payload) => {
            state.uploadFile.isLoading = false;
            state.uploadFile.errors = payload;
        },
        [UPLOAD_FILE_SUCCESS]: (state) => {
            state.uploadFile.isLoading = false;
        },
    },
});

export const actions = slice.actions;

export default slice.reducer;
