import React, { useEffect } from 'react';

import { get, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Card, Grid } from 'sunwise-ui';

import { COMMERCIAL_OFFER_STATUS } from 'common/constants/commercialOffer';
import SidebarIndicators from 'common/modules/proposalIndicators';

import * as energyBackupSetupSelectors from '../../energyBackupSetup/selectors';
import * as proposalGeneratorOneColumnSelectors from '../../proposalGeneratorOneColumn/selectors';
import * as proposalGeneratorQuoteActions from '../../proposalGeneratorQuote/actions';
import BreakdownTotals from '../../proposalGeneratorQuote/components/Totals';
import * as proposalGeneratorQuoteSelectors from '../../proposalGeneratorQuote/selectors';
import * as actions from '../actions';
import * as selectors from '../selectors';

import ConsumptionAnalysisVsGenerationCharts from './ConsumptionAnalysisVsGenerationCharts';
import Financing from './Financing';
import PVLayout from './PVLayout';
import StyledGrid from './StyledGrid';
import TotalsBarChart from './TotalsBarChart';

const DraftContainer = ({
    batteriesOfferValues,
    commercialOffer,
    compensationScheme,
    consumptionProjection,
    financialProductParameters,
    financierInstaller,
    initialFetchingDraft,
    irradiationValues,
    isFetchingDraft,
    maxDailyConsumption,
    nextRateData,
    offerCurrency,
    offerDetailsComplements,
    offerDetailsConsumption,
    offerDetailsLocation,
    offerDiscount,
    offerProductsData,
    productsTotals,
    proposalConfiguration,
    proposalId,
    solarSimulationData,
    totalAccessories,
    totalAdditionals,
    totalEnergyStorage,
    totalInverters,
    totalPanels,
    totalsSolarSimulation,
    totalStructure,
    totalWorkforce,
    updateProductsTotals,
}) => {
    useEffect(() => {
        if (proposalId) initialFetchingDraft(proposalId);
    }, [proposalId]);

    useEffect(() => {
        updateProductsTotals(offerDiscount);
    }, [offerDiscount, offerProductsData]);

    const currencyIso = get(offerCurrency, 'abbreviation', 'USD');
    const currencyLocale = get(offerCurrency, 'locale', 'en-Us');
    const currencySymbol = get(offerCurrency, 'symbol', '$');
    const financialPlan = get(
        financialProductParameters,
        'product_financial_plan',
        null,
    );
    const hasFinancing = get(commercialOffer, 'has_financing', false);
    const hasFinancingRequest = get(
        commercialOffer,
        'has_financing_request',
        false,
    );
    const offerStatus = get(commercialOffer, 'status', null);
    const isGeneratedProposal =
        offerStatus === COMMERCIAL_OFFER_STATUS.FINISHED.key;
    const {
        profit,
        profitPercentage,
        subtotal,
        subtotalWithDiscount,
        total,
        totalCost,
        totalTaxes,
    } = productsTotals;

    return (
        <Card.Body>
            <Grid container>
                <StyledGrid
                    item
                    xs={18}
                    md={14}
                    sx={{
                        height: 'calc(100vh - 220px)',
                    }}
                >
                    <Grid container>
                        <Grid item xs={18} md={10}>
                            <ConsumptionAnalysisVsGenerationCharts
                                consumptionProjection={consumptionProjection}
                                isFetching={isFetchingDraft}
                                proposalConfiguration={proposalConfiguration}
                                solarSimulationData={solarSimulationData}
                            />
                        </Grid>
                        <Grid item xs={18} md={8}>
                            <TotalsBarChart
                                totalAccessories={totalAccessories}
                                totalAdditionals={totalAdditionals}
                                totalEnergyStorage={totalEnergyStorage}
                                totalInverters={totalInverters}
                                totalPanels={totalPanels}
                                totalStructure={totalStructure}
                                totalWorkforce={totalWorkforce}
                            />
                            <BreakdownTotals
                                currencyIso={currencyIso}
                                currencyLocale={currencyLocale}
                                currencySymbol={currencySymbol}
                                isFetching={isFetchingDraft}
                                isLocked={true}
                                offerDiscount={offerDiscount}
                                profit={profit}
                                profitPercentage={profitPercentage}
                                proposalId={proposalId}
                                showTaxIcon={false}
                                subtotal={subtotal}
                                subtotalWithDiscount={subtotalWithDiscount}
                                total={total}
                                totalCost={totalCost}
                                totalTaxes={totalTaxes}
                            />
                        </Grid>
                    </Grid>
                    <PVLayout
                        isFetching={isFetchingDraft}
                        projectLocation={offerDetailsLocation}
                    />
                    <Grid container>
                        <Grid item xs={18}>
                            <Financing
                                currencyIso={currencyIso}
                                currencyLocale={currencyLocale}
                                financialProductParameters={
                                    financialProductParameters
                                }
                                financierInstaller={financierInstaller}
                                hasFinancing={hasFinancing}
                                hasFinancingRequest={hasFinancingRequest}
                                hasOwnFinancing={!isNull(financialPlan)}
                                isGeneratedProposal={isGeneratedProposal}
                                isLoading={isFetchingDraft}
                                proposalId={proposalId}
                            />
                        </Grid>
                    </Grid>
                </StyledGrid>
                <StyledGrid
                    item
                    xs={18}
                    md={4}
                    sx={{
                        height: 'calc(100vh - 220px)',
                    }}
                >
                    <SidebarIndicators.Container
                        batteriesOfferValues={batteriesOfferValues}
                        compensationScheme={compensationScheme}
                        currencyIso={currencyIso}
                        currencyLocale={currencyLocale}
                        irradiationValues={irradiationValues}
                        isLoading={isFetchingDraft}
                        maxDailyConsumption={maxDailyConsumption}
                        nextRateData={nextRateData}
                        offerDetailsComplements={offerDetailsComplements}
                        offerDetailsConsumption={offerDetailsConsumption}
                        proposalConfiguration={proposalConfiguration}
                        proposalId={proposalId}
                        totalsSolarSimulation={totalsSolarSimulation}
                    />
                </StyledGrid>
            </Grid>
        </Card.Body>
    );
};

DraftContainer.propTypes = {
    batteriesOfferValues: PropTypes.object,
    commercialOffer: PropTypes.object,
    compensationScheme: PropTypes.object,
    consumptionProjection: PropTypes.object,
    financialProductParameters: PropTypes.object,
    financierInstaller: PropTypes.object,
    initialFetchingDraft: PropTypes.func,
    irradiationValues: PropTypes.object,
    isFetchingDraft: PropTypes.bool,
    maxDailyConsumption: PropTypes.number,
    nextRateData: PropTypes.object,
    offerCurrency: PropTypes.object,
    offerDetailsComplements: PropTypes.object,
    offerDetailsConsumption: PropTypes.object,
    offerDetailsLocation: PropTypes.object,
    offerDiscount: PropTypes.object,
    offerProductsData: PropTypes.object,
    productsTotals: PropTypes.object,
    proposalConfiguration: PropTypes.object,
    proposalId: PropTypes.string,
    solarSimulationData: PropTypes.object,
    totalAccessories: PropTypes.number,
    totalAdditionals: PropTypes.number,
    totalEnergyStorage: PropTypes.number,
    totalInverters: PropTypes.number,
    totalPanels: PropTypes.number,
    totalsSolarSimulation: PropTypes.object,
    totalStructure: PropTypes.number,
    totalWorkforce: PropTypes.number,
    updateProductsTotals: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    batteriesOfferValues: energyBackupSetupSelectors.getOfferValuesData,
    compensationScheme:
        proposalGeneratorOneColumnSelectors.getCompensationScheme,
    consumptionProjection:
        proposalGeneratorOneColumnSelectors.getConsumptionProjectionData,
    commercialOffer:
        proposalGeneratorOneColumnSelectors.getOfferDetailsCommercialOffer,
    financialProductParameters:
        proposalGeneratorOneColumnSelectors.getFinancialProductParameters,
    financierInstaller:
        proposalGeneratorOneColumnSelectors.getFinancierInstaller,
    irradiationValues:
        proposalGeneratorOneColumnSelectors.getDataOfferIrradiationValues,
    isFetchingDraft: selectors.getIsFetchingDraft,
    maxDailyConsumption:
        proposalGeneratorOneColumnSelectors.getMaxDailyConsumption,
    nextRateData: proposalGeneratorOneColumnSelectors.getNextRateData,
    offerCurrency: proposalGeneratorOneColumnSelectors.getOfferCurrency,
    offerDetailsComplements:
        proposalGeneratorOneColumnSelectors.getOfferDetailsComplements,
    offerDetailsConsumption:
        proposalGeneratorOneColumnSelectors.getOfferDetailsConsumption,
    offerDetailsLocation:
        proposalGeneratorOneColumnSelectors.getOfferDetailsLocation,
    offerDiscount: proposalGeneratorOneColumnSelectors.getOfferDiscountData,
    offerProductsData: proposalGeneratorQuoteSelectors.getOfferProductsData,
    productsTotals: proposalGeneratorQuoteSelectors.getProductsTotals,
    proposalConfiguration:
        proposalGeneratorOneColumnSelectors.getProposalConfiguration,
    solarSimulationData:
        proposalGeneratorOneColumnSelectors.getSolarSimulationData,
    totalAccessories: proposalGeneratorQuoteSelectors.getFinalCostAccessories,
    totalAdditionals: proposalGeneratorQuoteSelectors.getFinalCostAdditionals,
    totalEnergyStorage:
        proposalGeneratorQuoteSelectors.getFinalCostEnergyStorage,
    totalInverters: proposalGeneratorQuoteSelectors.getFinalCostInverters,
    totalPanels: proposalGeneratorQuoteSelectors.getFinalCostPanels,
    totalsSolarSimulation:
        proposalGeneratorOneColumnSelectors.getTotalsSolarSimulation,
    totalStructure: proposalGeneratorQuoteSelectors.getFinalCostStructures,
    totalWorkforce: proposalGeneratorQuoteSelectors.getFinalCostWorkforce,
});

const mapDispatchToProps = (dispatch) => ({
    initialFetchingDraft: (proposalId) =>
        dispatch(actions.initialFetchingDraft(proposalId)),
    updateProductsTotals: (offerDiscount) =>
        dispatch(
            proposalGeneratorQuoteActions.updateProductsTotals(offerDiscount),
        ),
});

export default connect(mapStateToProps, mapDispatchToProps)(DraftContainer);
