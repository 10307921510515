import React from 'react';

import PropTypes from 'prop-types';

import ModifyCalculationField from '../../proposalGeneratorConfigurationModal/components/ModifyCalculationField';
import PPAFields from '../../proposalGeneratorConfigurationModal/components/PPAFields';
import RoiFields from '../../proposalGeneratorConfigurationModal/components/RoiFields';

import GuaranteedGenerationFields from './GuaranteedGenerationFields';
import OpexFields from './OpexFields';
import PowerFactorFields from './PowerFactorFields';

const TabSystem = ({
    control,
    currencyIso,
    currencySymbol,
    isDisabled,
    setValue,
}) => {
    return (
        <>
            <GuaranteedGenerationFields
                control={control}
                isDisabled={isDisabled}
            />

            <RoiFields
                canModifyRoiTaxIncentive={true}
                control={control}
                isLocked={isDisabled}
                setValue={setValue}
            />

            <PPAFields
                control={control}
                currencyIso={currencyIso}
                currencySymbol={currencySymbol}
                isLocked={isDisabled}
            />

            <ModifyCalculationField control={control} isLocked={isDisabled} />

            <PowerFactorFields control={control} isLocked={isDisabled} />

            <OpexFields
                control={control}
                currencyIso={currencyIso}
                currencySymbol={currencySymbol}
                isDisabled={isDisabled}
                setValue={setValue}
            />
        </>
    );
};

TabSystem.propTypes = {
    control: PropTypes.object,
    currencyIso: PropTypes.string,
    currencySymbol: PropTypes.string,
    isDisabled: PropTypes.bool,
    setValue: PropTypes.func,
};

export default TabSystem;
