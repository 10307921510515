import React, { useEffect } from 'react';

import { debounce, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';

import { DEBOUNCE_TIME_1500 } from 'common/constants';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import * as proposalGeneratorQuoteSelectors from '../proposalGeneratorQuote/selectors';

import * as actions from './actions';
import CardForm from './components/CardForm';
import { handleNormalizeFieldsBuild } from './helpers';
import * as selectors from './selectors';

const Container = ({
    canModify,
    currencyIso,
    currencyLocale,
    currencySymbol,
    initialValues,
    initialize,
    initializeProductsSettingsModal,
    isFetching,
    isLoading,
    isLocked = false,
    isUpdating,
    offerTaxes,
    proposalId,
    save,
    saveProductsSettings,
}) => {
    useEffect(() => {
        if (!isEmpty(offerTaxes)) {
            initialize(offerTaxes);
        }
    }, [offerTaxes]);

    const handleOnSave = React.useRef(
        debounce(save, DEBOUNCE_TIME_1500),
    ).current;

    const handleNormalizeFields = handleNormalizeFieldsBuild(
        handleOnSave,
        proposalId,
    );
    const handleSaveProductsSettings = (values) =>
        saveProductsSettings(values, proposalId);

    const isLoadingForm = isFetching || isLoading || isUpdating;

    const isLockedForm = isLoadingForm || isLocked;

    return (
        <CardForm
            canModify={canModify}
            currencyIso={currencyIso}
            currencyLocale={currencyLocale}
            currencySymbol={currencySymbol}
            handleNormalizeFields={handleNormalizeFields}
            handleSaveProductsSettings={handleSaveProductsSettings}
            initializeProductsSettingsModal={initializeProductsSettingsModal}
            initialValues={initialValues}
            isLoading={isLoadingForm}
            isLocked={isLockedForm}
        />
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
    isFetching: selectors.getIsFetchingOfferTaxes,
    isUpdating: selectors.getIsUpdating,
    offerTaxes: proposalGeneratorQuoteSelectors.getOfferTaxesData,
    totalCost: proposalGeneratorQuoteSelectors.getTotalCostTaxes,
});

const mapDispatchToProps = (dispatch) => ({
    initialize: (values) => dispatch(actions.initialValues(values)),
    initializeProductsSettingsModal: (id, values) =>
        dispatch(actions.initializeProductsSettingsModal(id, values)),
    save: (proposalId, valuesForm) =>
        dispatch(actions.save(proposalId, valuesForm)),
    saveProductsSettings: (values, proposalId) =>
        dispatch(actions.saveProductsSettings(values, proposalId)),
});

Container.propTypes = {
    canModify: PropTypes.bool,
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    currencySymbol: PropTypes.string,
    initialValues: PropTypes.object,
    initialize: PropTypes.func,
    initializeProductsSettingsModal: PropTypes.func,
    isFetching: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLocked: PropTypes.bool,
    isUpdating: PropTypes.bool,
    offerTaxes: PropTypes.array,
    proposalId: PropTypes.string,
    save: PropTypes.func,
    saveProductsSettings: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.PROPOSAL_TAXES_PERMISSION),
)(Container);
