import { useState, useEffect } from 'react';

export default () => {
    const [isPortrait, setIsPortrait] = useState(
        window.matchMedia('(orientation: portrait)').matches,
    );

    useEffect(() => {
        const mediaQueryList = window.matchMedia('(orientation: portrait)');

        const handleOrientationChange = (event) => {
            setIsPortrait(event.matches);
        };

        mediaQueryList.addEventListener('change', handleOrientationChange);

        return () => {
            mediaQueryList.removeEventListener(
                'change',
                handleOrientationChange,
            );
        };
    }, []);

    return { isPortrait };
};
