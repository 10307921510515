import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Box, Tab, Tabs, TabPanel } from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import * as actions from '../actions';
import {
    CONTACT_TRACKING_TYPE,
    DOCUMENTS_CONTACT_TYPE,
    DOCUMENTS_PROJECTS_TYPE,
    ORIGIN_CONTACT_TYPE,
    STATUS_CONTACT_TYPE,
    STATUS_PROJECT_TYPE,
} from '../constants';
import * as selectors from '../selectors';

import CatalogsCard from './CatalogsCard';
import SummaryTabPane from './SummaryTabPane';

const CardList = ({ canView, fetchCatalogs, resetItem, type }) => {
    const { t } = useTranslation();

    const [selectedTab, setSelectedTab] = useState('contact');
    const handleChange = (_, newValue) => {
        resetItem();
        setSelectedTab(newValue);
    };

    const getIsSelected = (selectedType) => type === selectedType;

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={selectedTab} onChange={handleChange}>
                    <Tab
                        key="tab-contact"
                        id="tab-contact"
                        label={t('Contact')}
                        value="contact"
                    />
                    <Tab
                        key="tab-tracking"
                        id="tab-tracking"
                        label={t('Follow-up')}
                        value="tracking"
                    />
                    <Tab
                        key="tab-project"
                        id="tab-project"
                        label={t('Project')}
                        value="project"
                    />
                    {canView && (
                        <Tab
                            key="tab-summary"
                            id="tab-summary"
                            label={t('Summary')}
                            value="summary"
                        />
                    )}
                </Tabs>
            </Box>

            <TabPanel
                key="tab-panel-contact"
                selectedTab={selectedTab}
                value="contact"
            >
                <CatalogsCard
                    onClick={() => fetchCatalogs(STATUS_CONTACT_TYPE)}
                    selected={getIsSelected(STATUS_CONTACT_TYPE)}
                    title={t('Contact status')}
                    sxCard={{ mb: 2 }}
                />

                <CatalogsCard
                    onClick={() => fetchCatalogs(DOCUMENTS_CONTACT_TYPE)}
                    selected={getIsSelected(DOCUMENTS_CONTACT_TYPE)}
                    title={t('Contact document', { count: 2 })}
                    sxCard={{ mb: 2 }}
                />

                <CatalogsCard
                    onClick={() => fetchCatalogs(ORIGIN_CONTACT_TYPE)}
                    selected={getIsSelected(ORIGIN_CONTACT_TYPE)}
                    title={t('Contact origin')}
                />
            </TabPanel>

            <TabPanel
                key="tab-panel-tracking"
                selectedTab={selectedTab}
                value="tracking"
            >
                <CatalogsCard
                    onClick={() => fetchCatalogs(CONTACT_TRACKING_TYPE)}
                    selected={getIsSelected(CONTACT_TRACKING_TYPE)}
                    title={t('Objective')}
                />
            </TabPanel>

            <TabPanel
                key="tab-panel-project"
                selectedTab={selectedTab}
                value="project"
            >
                <CatalogsCard
                    onClick={() => fetchCatalogs(DOCUMENTS_PROJECTS_TYPE)}
                    selected={getIsSelected(DOCUMENTS_PROJECTS_TYPE)}
                    title={t('Project document', { count: 2 })}
                    sxCard={{ mb: 2 }}
                />

                <CatalogsCard
                    onClick={() => fetchCatalogs(STATUS_PROJECT_TYPE)}
                    selected={getIsSelected(STATUS_PROJECT_TYPE)}
                    title={t('Project status')}
                />
            </TabPanel>

            <TabPanel
                key="tab-panel-summary"
                selectedTab={selectedTab}
                value="summary"
            >
                <SummaryTabPane
                    canView={canView}
                    getIsSelected={getIsSelected}
                    fetchCatalogs={fetchCatalogs}
                />
            </TabPanel>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    companiesCatalogs: selectors.getCompaniesCatalogs,
    type: selectors.getType,
});

const mapDispatchToProps = (dispatch) => ({
    fetchCatalogs: (type) => dispatch(actions.fetchCatalogs(type)),
});

CardList.propTypes = {
    canView: PropTypes.bool,
    fetchCatalogs: PropTypes.func,
    resetItem: PropTypes.func,
    type: PropTypes.number,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.PROPOSAL_SUMMARIES_PERMISSION),
)(CardList);
