import React from 'react';

import CircleIcon from '@mui/icons-material/Circle';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'sunwise-ui';

import { IconPanel } from 'common/components/icons';

import { countPanelPerSegmentsById } from '../helpers';

const PanelsCard = ({ offerPanels, segments }) => {
    const { t } = useTranslation();

    return (
        <Box display="flex" flexDirection="column" mb={2}>
            <Box
                className="__userguiding_panels_box"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    p: 1,
                }}
            >
                <IconPanel color="#3f4fae" />
                <Typography variant="body2" fontWeight="bold">
                    {t('Panel', { count: 2 })}
                </Typography>
            </Box>
            {offerPanels.map((item, index) => (
                <Box
                    key={`offer-panel-${index}`}
                    p={1}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        ml: '32px',
                    }}
                >
                    <Box
                        alignItems="center"
                        display="flex"
                        gap={1}
                        sx={{ ml: '-1rem' }}
                    >
                        <CircleIcon
                            sx={{ fontSize: '0.6rem', color: item.color }}
                        />
                        <Typography variant="caption" fontWeight="bold">
                            {item.name || 'N/A'}
                        </Typography>
                    </Box>
                    <Box alignItems="center" display="flex" gap={1}>
                        <Typography variant="caption">
                            <Typography
                                component="span"
                                fontWeight="bold"
                                variant="caption"
                            >
                                {t('Height')}:{' '}
                            </Typography>
                            {item?.height} mm
                        </Typography>
                        <Typography variant="caption">
                            <Typography
                                component="span"
                                fontWeight="bold"
                                variant="caption"
                            >
                                {t('Width')}:{' '}
                            </Typography>
                            {item?.width} mm
                        </Typography>
                    </Box>
                    <Typography variant="caption">
                        {t('Assigned', { count: 2 })}{' '}
                        {countPanelPerSegmentsById(item.id, segments)}/
                        {item.quantity}
                    </Typography>
                </Box>
            ))}
        </Box>
    );
};

PanelsCard.propTypes = {
    offerPanels: PropTypes.array,
    segments: PropTypes.array,
};

export default PanelsCard;
