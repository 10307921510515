import React, { useContext, useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import {
    editionLevels,
    WidgetSideMenu,
    withTemplateCore,
} from 'sunwise-template-core';
import { Box, Typography } from 'sunwise-ui';

import CollapsedBreadcrumbs from 'common/components/CollapsedBreadcrumbs';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { LoadingContext } from 'common/utils/contexts';
import { getCurrentLanguage } from 'common/utils/helpers/multiregion';
import { getToken } from 'common/utils/helpers/session';

import companyGeneral from '../companyGeneral';
import * as templateLibreryActions from '../templateLibrary/actions';
import * as selectorsThemeConfig from '../templateLibrary/components/TemplateColorPalette/selectors';

import * as actions from './actions';
import LeftSideMenu from './components/LeftSideMenu';
import PageContainer from './components/PageContainer';
import PageModal from './components/PageModal';
import { handleClonePage } from './helpers';
import * as selectors from './selectors';

const Wrapper = styled('div')`
    display: flex;
    flex: 1;
    height: calc(100vh - 52px);
    overflow: hidden;
`;

const TemplateEditionContainer = styled(Box)(
    ({ theme }) => `
    background:  ${theme.palette.mode === 'dark' ? null : 'rgb(248, 252, 255)'};
    display: block;
    flex: 1;
    position: relative;
`,
);

const TemplateWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    height: 100%;
    position: absolute;
    width: 100%;
`;

const TitleText = styled(Typography)`
    font-size: 24px;
    font-weight: 100;
    line-height: 26px;
    margin-bottom: 2px;
    min-height: 26px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
`;

const DataText = styled(Typography)`
    font-size: 12px;
`;

const InfoWrapper = styled(Box)(
    ({ theme }) => `
        background-color: ${theme.palette.mode === 'dark' ? '#1B1C1F' : '#fff'};
        padding: 8px 16px;
`,
);

const Container = ({
    addBlankPageInTemplate,
    addNewPages,
    companyCountry,
    customTemplate,
    deletePagesInTemplate,
    duplicatePagesInTemplate,
    fetchClonePages,
    getPermissionsByCode,
    handleClickBack,
    handleClickBulkItems,
    initialize,
    initTemplate,
    isEmptyPages,
    isInitialize,
    isInitializing,
    isUpdatingCustomTemplate,
    match,
    orderPagesInTemplate,
    pageSize,
    reset,
    selectedPageId,
    selectPage,
    setClonePages,
    templateCreatedAt,
    templateProposalNumber,
    templateTitle,
    templateType,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);
    const [newPageModalIsOpen, showNewPageModal] = useState(false);
    const [isArchived, setArchived] = useState(false);
    const templateId = match.params.uid;

    const { canModify: canModifyPanelsLayout } = getPermissionsByCode(
        PERMISSION_LIST.PANEL_LAYOUT_PERMISSION,
    );
    const { canModify: canModifyProjectLocation } = getPermissionsByCode(
        PERMISSION_LIST.PROJECT_LOCATION_PERMISSION,
    );

    useEffect(() => {
        initialize(templateId, initTemplate, templateType);
        return () => {
            reset();
        };
    }, [templateId]);

    useEffect(() => {
        setArchived(customTemplate?.status_flag === 'archived');
    }, [customTemplate]);

    useEffect(() => {
        if (isEmpty(loadingContext)) return null;
        if (isUpdatingCustomTemplate)
            loadingContext.openLoading(
                t('Saving change', { count: 2 }).concat('...'),
            );
        else loadingContext.closeLoading();
    }, [isUpdatingCustomTemplate]);

    if (isInitialize || isInitializing) return null;

    return (
        <Wrapper>
            {!isArchived && (
                <LeftSideMenu
                    canModify
                    deletePagesInTemplate={deletePagesInTemplate}
                    duplicatePagesInTemplate={duplicatePagesInTemplate}
                    orderPagesInTemplate={orderPagesInTemplate}
                    pageSize={pageSize}
                    selectPage={selectPage}
                    selectedPageId={selectedPageId}
                    showNewPageModal={() => showNewPageModal(true)}
                />
            )}

            <TemplateEditionContainer>
                <TemplateWrapper>
                    <InfoWrapper>
                        <TitleText title={templateTitle}>
                            {templateTitle}
                        </TitleText>

                        <DataText>
                            <strong>{t('Created at')}:</strong>{' '}
                            {templateCreatedAt}
                        </DataText>

                        <CollapsedBreadcrumbs
                            current={templateTitle}
                            locations={[
                                {
                                    action: () => handleClickBack(templateType),
                                    label: t('Template', { count: 2 }),
                                },
                            ]}
                        />
                    </InfoWrapper>

                    <PageContainer
                        handleClickBulkItems={handleClickBulkItems}
                        isArchived={isArchived}
                        isEmptyPages={isEmptyPages}
                        selectedPageId={selectedPageId}
                        setArchived={setArchived}
                        showNewPageModal={() => showNewPageModal(true)}
                        templateId={templateId}
                    />
                </TemplateWrapper>
            </TemplateEditionContainer>

            {!isArchived && (
                <WidgetSideMenu
                    canModifyPanelsSowing={canModifyPanelsLayout}
                    canModifyProjectLocation={canModifyProjectLocation}
                />
            )}

            <PageModal
                handleClickClose={() => showNewPageModal(false)}
                isOpen={newPageModalIsOpen}
                onAddPage={addBlankPageInTemplate}
                onClonePage={handleClonePage(
                    templateId,
                    setClonePages,
                    addNewPages,
                )}
                onEnter={() =>
                    fetchClonePages(companyCountry, customTemplate?.language)
                }
                proposalsNumber={templateProposalNumber}
                templateType={templateType}
            />
        </Wrapper>
    );
};

const mapStateToProps = createStructuredSelector({
    companyCountry: companyGeneral.selectors.getCompanyCountry,
    customTemplate: selectors.getDataFetchCustomTemplate,
    isInitialize: selectors.getIsInitialize,
    isUpdatingCustomTemplate: selectors.getIsUpdatingCustomTemplate,
    pageSize: selectors.getPageSize,
    themeData: selectorsThemeConfig.getConfigData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchClonePages: (countryId, language) =>
        dispatch(actions.fetchClonePages(countryId, language)),
    handleClickBack: (templateType) =>
        dispatch(actions.handleClickBack(templateType)),
    handleClickBulkItems: (ids, value, filterData, callback) =>
        dispatch(
            templateLibreryActions.prepareBulkItems(
                ids,
                value,
                filterData,
                callback,
            ),
        ),
    initialize: (id, initTemplate, templateType) =>
        dispatch(actions.initialize(id, initTemplate, templateType)),
    reset: () => dispatch(actions.reset()),
    setClonePages: (customTemplateId, cloneTemplateId, pages, addNewPages) =>
        dispatch(
            actions.setClonePages(
                customTemplateId,
                cloneTemplateId,
                pages,
                addNewPages,
            ),
        ),
    updatePage: (templateData) => dispatch(actions.updatePage(templateData)),
    updateTemplate: (templateId, templateData) =>
        dispatch(actions.updateTemplate(templateId, templateData)),
});

Container.propTypes = {
    addBlankPageInTemplate: PropTypes.func,
    addNewPages: PropTypes.func,
    companyCountry: PropTypes.string,
    customTemplate: PropTypes.object,
    deletePagesInTemplate: PropTypes.func,
    duplicatePagesInTemplate: PropTypes.func,
    fetchClonePages: PropTypes.func,
    getPermissionsByCode: PropTypes.func,
    handleClickBack: PropTypes.func,
    handleClickBulkItems: PropTypes.func,
    initialize: PropTypes.func,
    initTemplate: PropTypes.func,
    isEmptyPages: PropTypes.bool,
    isInitialize: PropTypes.bool,
    isInitializing: PropTypes.bool,
    isUpdatingCustomTemplate: PropTypes.bool,
    match: PropTypes.object,
    orderPagesInTemplate: PropTypes.func,
    pageSize: PropTypes.string,
    reset: PropTypes.func,
    selectedPageId: PropTypes.string,
    selectPage: PropTypes.func,
    setClonePages: PropTypes.func,
    templateCreatedAt: PropTypes.string,
    templateProposalNumber: PropTypes.number,
    templateTitle: PropTypes.string,
    templateType: PropTypes.number,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions([
        PERMISSION_LIST.PANEL_LAYOUT_PERMISSION,
        PERMISSION_LIST.PROJECT_LOCATION_PERMISSION,
    ]),
    withRouter,
    withTemplateCore((props) => ({
        application: 'sunwise',
        baseUrl: import.meta.env.VITE_API_URL,
        editionMode: editionLevels.FULL_EDITION_MODE,
        froalaLicenseKey: import.meta.env.VITE_FROALA_LICENSE,
        googleApiKey: import.meta.env.VITE_GOOGLE_MAPS_KEY,
        language: getCurrentLanguage(),
        onChangeInPage: props.updatePage,
        token: getToken(),
        themeData: props.themeData,
        updateTemplatePromise: props.updateTemplate,
    })),
)(Container);
