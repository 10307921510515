import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid } from 'sunwise-ui';

import CategoryPreviewItem from 'common/components/CategoryPreviewItem';
import IconPreviewItem from 'common/components/IconPreviewItem';

import PdfDocument from 'resources/pdfDocument.svg';

import { BLANK_TEMPLATE } from '../../constants';
import {
    getCarouselTemplates,
    getCategoryTemplatesByLanguage,
} from '../../helpers';
import * as selectors from '../../selectors';

import DefaultTemplatePreviewItem from './DefaultTemplatePreviewItem';

const DefaultTemplatesTab = ({
    carouselTemplatesData,
    categorySelected,
    control,
    handleSelectTemplate,
    isForSummaryTemplate,
    languagesDictionary,
    selectCategory,
    selectedTemplate,
    templateCategories,
    templateType,
}) => {
    const { t } = useTranslation();
    const language = useWatch({ control, name: 'language' });

    const templates = getCarouselTemplates(
        carouselTemplatesData,
        null,
        templateType,
    );

    if (isNull(categorySelected)) {
        return (
            <Grid container>
                <Grid item md={6} lg={3} xs={18}>
                    <IconPreviewItem
                        icon={<AddIcon fontSize="large" />}
                        isSelected={selectedTemplate?.id === BLANK_TEMPLATE}
                        onClick={() =>
                            handleSelectTemplate({
                                id: BLANK_TEMPLATE,
                                locked: 0,
                            })
                        }
                        text={t('Blank template')}
                    />
                </Grid>

                {!isForSummaryTemplate &&
                    templateCategories.map((templateCategory) => {
                        const templateCategoryId = templateCategory.id;
                        const templateCount = getCategoryTemplatesByLanguage({
                            category: templateCategoryId,
                            language,
                            templates,
                        }).length;
                        return templateCount > 0 ? (
                            <Grid
                                item
                                key={templateCategoryId}
                                lg={3}
                                md={6}
                                xs={18}
                            >
                                <CategoryPreviewItem
                                    id={templateCategoryId}
                                    name={templateCategory.name}
                                    onClick={() =>
                                        selectCategory(templateCategoryId)
                                    }
                                    templatesCount={templateCount}
                                    urlIcon={`${import.meta.env.VITE_S3_MEDIA_PATH}${templateCategory.file_url}`}
                                />
                            </Grid>
                        ) : null;
                    })}
            </Grid>
        );
    }

    return (
        <Grid container>
            <Grid item md={6} lg={3} xs={18}>
                <IconPreviewItem
                    icon={<ArrowBackIosNewIcon fontSize="large" />}
                    onClick={() => {
                        selectCategory(null);
                        handleSelectTemplate({});
                    }}
                    text={t('Back')}
                />
            </Grid>
            {getCategoryTemplatesByLanguage({
                category: categorySelected,
                language,
                templates,
            }).map((item) => {
                return (
                    <Grid item xs={18} md={6} lg={3} key={item.id}>
                        <DefaultTemplatePreviewItem
                            isLocked={item.locked === 1}
                            isSelected={selectedTemplate?.id === item.id}
                            language={language}
                            languagesDictionary={languagesDictionary}
                            onClick={() =>
                                handleSelectTemplate({
                                    id: item.id,
                                    locked: item.locked,
                                })
                            }
                            previewImage={
                                item?.image_thumbnail
                                    ? item.image_thumbnail
                                    : PdfDocument
                            }
                            text={item.title}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
};

const mapStateToProps = createStructuredSelector({
    carouselTemplatesData: selectors.getCarouselTemplatesData,
    languagesDictionary: selectors.getLanguagesDictionary,
    templateCategories: selectors.getTemplateCategoriesData,
});

DefaultTemplatesTab.propTypes = {
    carouselTemplatesData: PropTypes.object,
    categorySelected: PropTypes.string,
    control: PropTypes.object,
    handleSelectTemplate: PropTypes.func,
    isForSummaryTemplate: PropTypes.bool,
    languagesDictionary: PropTypes.object,
    selectCategory: PropTypes.func,
    selectedTemplate: PropTypes.object,
    templateCategories: PropTypes.array,
    templateType: PropTypes.number,
};

export default connect(mapStateToProps, null)(DefaultTemplatesTab);
