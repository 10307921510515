import React, { useEffect } from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Mention, MentionsInput } from 'react-mentions';
import { Box, Button, FormControl, FormLabel, Grid } from 'sunwise-ui';

import {
    ReactHookFormCheck,
    ReactHookFormSelect,
    RHFDateTimePicker,
} from 'common/components/form/bootstrap';
import ShowErrors from 'common/components/ShowErrors';
import yupResolver from 'common/utils/yupResolver';

import { getUsersOptions, getTrackingTypesOptions } from '../helpers';
import validate from '../validate';

import mentionsInputStyle from './mentionsInputStyle';
import mentionStyle from './mentionStyle';

const Wrapper = styled(Box)(
    ({ theme }) => `
    background: ${
        theme.palette.mode === 'dark'
            ? 'rgb(40, 43, 48)'
            : 'rgba(238, 244, 250, 0.35)'
    };
    border-radius: 8px;
    padding: 16px;
`,
);

const Form = ({
    errors,
    fetchTrackingsPurposes,
    handleClickCancel,
    initialValues,
    isSaving,
    onSubmit,
    trackingsPurposesToSelect,
    users,
}) => {
    const { t } = useTranslation();

    const { control, handleSubmit, reset, setValue, watch } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    const remember = watch('remember');

    useEffect(() => {
        fetchTrackingsPurposes();
    }, []);

    return (
        <Wrapper>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ReactHookFormSelect
                    control={control}
                    label={t('Tracking type')}
                    name="tracking_type"
                    options={getTrackingTypesOptions()}
                />

                <ReactHookFormSelect
                    control={control}
                    label={t('Objective')}
                    name="tracking_purpose"
                    options={trackingsPurposesToSelect}
                />

                <FormControl fullWidth sx={{ height: '100px' }}>
                    <FormLabel sx={{ fontSize: '13px', fontWeight: 'bold' }}>
                        {t('Note')}
                    </FormLabel>
                    <MentionsInput
                        onChange={(e) =>
                            setValue('description', e.target.value)
                        }
                        placeholder={t('Add note')}
                        style={{
                            ...mentionsInputStyle,
                            control: {
                                backgroundColor: '#fff',
                                borderRadius: '8px',
                                padding: '8.5px 14px',
                                height: '92px',
                            },
                        }}
                        value={watch('description')}
                    >
                        <Mention
                            data={getUsersOptions(users)}
                            style={mentionStyle}
                            trigger="@"
                        />
                    </MentionsInput>
                </FormControl>

                <ReactHookFormCheck
                    control={control}
                    label={t('Remember task')}
                    name="remember"
                />

                {remember && (
                    <Grid container>
                        <Grid item xs={18}>
                            <RHFDateTimePicker
                                control={control}
                                disabled={!remember}
                                fullWidth
                                disablePast
                                label={t('Date')}
                                name="notification_at"
                            />
                        </Grid>
                    </Grid>
                )}

                <Grid container>
                    <Grid item xs={18}>
                        <ShowErrors errors={errors} />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid
                        display="flex"
                        flexDirection={{ xs: 'column', md: 'row' }}
                        item
                        justifyContent={{ md: 'right', xs: 'center' }}
                        xs
                    >
                        <Button
                            color="secondary"
                            onClick={handleClickCancel}
                            sx={{
                                mr: { xs: 0, md: 2 },
                                order: { xs: 2, md: 1 },
                                width: { xs: '100%', md: 'auto' },
                            }}
                            variant="text"
                        >
                            {t('Cancel')}
                        </Button>
                        <Button
                            color="secondary"
                            disabled={isSaving}
                            sx={{
                                mb: { xs: 2, md: 0 },
                                order: { xs: 1, md: 2 },
                                width: { xs: '100%', md: 'auto' },
                            }}
                            type="submit"
                            variant="outlined"
                        >
                            {t('Save')}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Wrapper>
    );
};

Form.propTypes = {
    errors: PropTypes.array,
    fetchTrackingsPurposes: PropTypes.func,
    handleClickCancel: PropTypes.func,
    initialValues: PropTypes.object,
    isSaving: PropTypes.bool,
    onSubmit: PropTypes.func,
    trackingsPurposesToSelect: PropTypes.array,
    users: PropTypes.array,
};

export default Form;
