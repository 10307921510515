import { saveThemeConfig } from 'common/api/v1/templates';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import { SAVE, SAVE_FAILURE, SAVE_SUCCESS } from '../actionTypes';
import { actions } from '../reducer';

export default (values) => (dispatch) => {
    dispatch(actions[SAVE]());

    saveThemeConfig({ configs: values }).then(
        (response) => {
            dispatch(actions[SAVE_SUCCESS](response.data));
            showToast();
        },
        (error) => {
            dispatch(actions[SAVE_FAILURE](error?.response?.data));
            showReponseErrorsAsAlert(dispatch, error?.response);
        },
    );
};
