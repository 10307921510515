import { updateOferCosting } from 'common/api/v2/offerCosting';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import eventsAfterUpdate from '../../proposalGeneratorOneColumn/actions/eventsAfterUpdate';
import {
    SAVE_COSTING,
    SAVE_COSTING_SUCCESS,
    SAVE_COSTING_FAILURE,
} from '../actionTypes';
import { proposalGeneratorQuoteActions } from '../reducer';

export default (proposalId, values, callback = null) =>
    (dispatch) => {
        const newValues = {
            kwh_cost: values.kwh_cost,
            kwh_margin: values.kwh_margin,
            kwh_price: values.kwh_price,
            kwh_total_amount: values.kwh_total_amount,
            watt_cost: values.watt_cost,
            watt_margin: values.watt_margin,
            watt_price: values.watt_price,
            watt_total_amount: values.watt_total_amount,
            costing_type: values.costing_type,
        };

        dispatch(proposalGeneratorQuoteActions[SAVE_COSTING]());

        updateOferCosting(proposalId, newValues)
            .then((response) => {
                dispatch(
                    proposalGeneratorQuoteActions[SAVE_COSTING_SUCCESS](
                        response.data.data,
                    ),
                );
                dispatch(eventsAfterUpdate(response.data.data, proposalId));
                if (callback) callback();
            })
            .catch((error) => {
                dispatch(
                    proposalGeneratorQuoteActions[SAVE_COSTING_FAILURE](
                        error?.response?.data?.errors,
                    ),
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
