import React from 'react';

import get from 'lodash/get';
import { Typography } from 'sunwise-ui';

const SalesmanFormatter = (params) => {
    const { row } = params;

    const label = row?.created_by
        ? `${get(row, 'created_by.first_name', '') || ''} ${
              get(row, 'created_by.last_name', '') || ''
          } ${get(row, 'created_by.second_surname', '') || ''}`
        : row?.agent_name;

    return <Typography variant="caption">{label}</Typography>;
};

SalesmanFormatter.propTypes = {};

export default SalesmanFormatter;
