import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, UiDialog as Dialog } from 'sunwise-ui';

const PanelConfigModal = ({ handleCloseModal, isOpenModal }) => {
    const { t } = useTranslation();

    return (
        <Dialog
            onClose={() => handleCloseModal()}
            onEnter={() => {}}
            open={isOpenModal}
            title="Configurar paneles"
            size="md"
        >
            <Box>{t('Add')}</Box>
        </Dialog>
    );
};

PanelConfigModal.propTypes = {
    handleCloseModal: PropTypes.func,
    isOpenModal: PropTypes.bool,
};

export default PanelConfigModal;
