import React, { useEffect, useState } from 'react';

import { debounce, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';

import AccordionProduct from 'common/components/accordions/AccordionProduct';
import { IconAccessory } from 'common/components/icons';
import { DEBOUNCE_TIME_1500 } from 'common/constants';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { numberFormat } from 'common/utils/helpers';

import {
    handleClickDelete,
    handleNormalizeDiscountField,
    handleNormalizeField,
    handleNormalizeFieldsBuild,
    handleNormalizeMarginField,
    handleNormalizeModel,
    handleNormalizeModelFieldBuild,
    handleNormalizePercentageDiscountField,
    handleRemoveBuild,
} from '../proposalGeneratorQuote/helpers';
import * as proposalGeneratorQuoteSelectors from '../proposalGeneratorQuote/selectors';

import * as actions from './actions';
import CardForm from './components/CardForm';
import * as selectors from './selectors';

const Container = ({
    accessories,
    createProduct,
    currencyIso,
    currencyLocale,
    currencySymbol,
    customerMode,
    deleteProduct,
    getPermissionsByCode,
    initialize,
    isFetching,
    isLoading,
    isLocked = false,
    isProductCosting,
    isUpdating,
    offerAccessories,
    proposalId,
    save,
    selectionModel,
    setSelectionModel,
    totalCost,
}) => {
    const { t } = useTranslation();
    const [isChecked, setIsChecked] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);

    const unCheckBox = () => {
        setSelectionModel((prevModel) =>
            prevModel.filter((item) => item !== 'accessories'),
        );
    };

    useEffect(() => {
        if (!isEmpty(offerAccessories)) {
            initialize(offerAccessories);
            setSelectedItems(
                offerAccessories.map((item) => ({
                    ...item.product_branch_office,
                    quantity: item.quantity,
                })),
            );
        } else {
            setSelectedItems([]);
            setIsChecked(false);
            unCheckBox();
        }
    }, [offerAccessories]);

    useEffect(() => {
        if (isChecked) setSelectionModel([...selectionModel, 'accessories']);
        else unCheckBox();
    }, [selectedItems, isChecked]);

    useEffect(() => {
        if (isEmpty(selectionModel)) setIsChecked(false);
    }, [selectionModel]);

    const { canModify: canModifyProduct } = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_ACCESORIES_PERMISSION,
    );

    const { canModify: canModifyTotal } = getPermissionsByCode(
        PERMISSION_LIST.SEE_TOTAL_PRODUCTS,
    );

    const handleOnSave = React.useRef(
        debounce(save, DEBOUNCE_TIME_1500),
    ).current;

    const handleNormalizeFields = handleNormalizeFieldsBuild(
        createProduct,
        handleOnSave,
        proposalId,
    );

    const handleNormalizeModelField = handleNormalizeModelFieldBuild(
        createProduct,
        handleOnSave,
        proposalId,
    );

    const handleRemove = handleRemoveBuild(deleteProduct, proposalId);

    const isLoadingForm = isFetching || isLoading || isUpdating;

    const isLockedForm = isLoadingForm || isLocked;

    const totalItems = selectedItems.reduce(
        (total, item) => total + item.quantity,
        0,
    );

    return (
        <AccordionProduct
            caption={t('Total price')}
            cardId="proposal_generator_accessory_card"
            checkboxControl
            defaultBehavior={false}
            disabledCheckbox={selectedItems?.length === 0}
            isChecked={isChecked}
            isLoading={isLoadingForm}
            isProductCosting={isProductCosting}
            setIsChecked={setIsChecked}
            svgIcon={<IconAccessory />}
            title={t('Accessory', { count: 2 })}
            total={
                canModifyTotal
                    ? numberFormat(totalCost, {
                          currency: currencyIso,
                          locale: currencyLocale,
                          style: 'currency',
                      })
                    : null
            }
            totalItems={numberFormat(totalItems, {
                decimals: 0,
                locale: currencyLocale,
                style: 'decimal',
            })}
        >
            <CardForm
                canModifyProduct={canModifyProduct}
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                currencySymbol={currencySymbol}
                customerMode={customerMode}
                handleClickDelete={handleClickDelete}
                handleNormalizeDiscountField={handleNormalizeDiscountField}
                handleNormalizeField={handleNormalizeField}
                handleNormalizeFields={handleNormalizeFields}
                handleNormalizeMarginField={handleNormalizeMarginField}
                handleNormalizeModel={handleNormalizeModel}
                handleNormalizeModelField={handleNormalizeModelField}
                handleNormalizePercentageDiscountField={
                    handleNormalizePercentageDiscountField
                }
                handleRemove={handleRemove}
                isLoading={isLoadingForm}
                isLocked={isLockedForm}
                isProductCosting={isProductCosting}
                itemsData={accessories}
                offerItems={selectedItems}
            />
        </AccordionProduct>
    );
};

const mapStateToProps = createStructuredSelector({
    accessories: proposalGeneratorQuoteSelectors.getProposalAccessoryCatalogs,
    isFetching: selectors.getIsFetchingOfferAccessories,
    isUpdating: selectors.getIsUpdating,
    offerAccessories: proposalGeneratorQuoteSelectors.getOfferAccessoriesData,
    totalCost: proposalGeneratorQuoteSelectors.getFinalCostAccessories,
});

const mapDispatchToProps = (dispatch) => ({
    createProduct: (proposalId, values) =>
        dispatch(actions.create(proposalId, values)),
    deleteProduct: (id, proposalId, successCallback) =>
        dispatch(actions.delete(id, proposalId, successCallback)),
    initialize: (values) => dispatch(actions.initialValues(values)),
    save: (proposalId, valuesForm) =>
        dispatch(actions.save(proposalId, valuesForm)),
});

Container.propTypes = {
    accessories: PropTypes.array,
    createProduct: PropTypes.func,
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    currencySymbol: PropTypes.string,
    customerMode: PropTypes.bool,
    deleteProduct: PropTypes.func,
    getPermissionsByCode: PropTypes.func,
    initialize: PropTypes.func,
    isFetching: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLocked: PropTypes.bool,
    isProductCosting: PropTypes.bool,
    isUpdating: PropTypes.bool,
    offerAccessories: PropTypes.array,
    proposalId: PropTypes.string,
    save: PropTypes.func,
    selectionModel: PropTypes.array,
    setSelectionModel: PropTypes.func,
    totalCost: PropTypes.number,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions([
        PERMISSION_LIST.PROPOSAL_ACCESORIES_PERMISSION,
        PERMISSION_LIST.SEE_TOTAL_PRODUCTS,
    ]),
)(Container);
