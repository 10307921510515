import { styled } from '@mui/material/styles';
import { Box } from 'sunwise-ui';

import { useOrientation } from 'common/hooks';
import { scroll } from 'common/utils/mixins';

export default styled(Box)(({ theme }) => {
    const { isPortrait } = useOrientation();
    const height = 'calc(100vh - 56px)';
    const overflowY = 'scroll';
    return {
        height,
        marginTop: 1,
        overflowY,
        [theme.breakpoints.down('md')]: {
            height: isPortrait ? height : '100%',
            overflowY: isPortrait ? overflowY : 'initial',
        },
        ...scroll.object,
    };
});
