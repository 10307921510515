import React from 'react';

import BusinessIcon from '@mui/icons-material/Business';
import HomeIcon from '@mui/icons-material/Home';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'sunwise-ui';

import {
    ReactHookFormCheckboxButtonGroup,
    ReactHookFormInput,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';
import TitleWithDetail from 'common/components/TitleWithDetail';
import MainBranchOfficeSelectorRHF from 'common/modules/multiBranches/components/MainBranchOfficeSelectorRHF';

import { getTemplatesToSelect } from '../helpers';

import SimulationFields from './SimulationFields';

const StyledLabel = styled(Typography)`
    align-items: center;
    color: #848bab;
    cursor: pointer;
    display: inline-flex;
    gap: 8px;
    padding: 9px 0;
`;

const TabGeneral = ({
    branchOffice,
    control,
    id,
    isDisabled,
    isNewRecord,
    planData,
    templates,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <ReactHookFormInput
                control={control}
                disabled={isDisabled}
                name="name"
                label={t('Name')}
            />

            <MainBranchOfficeSelectorRHF
                control={control}
                disabled={id !== null || isDisabled}
                hideCompany
                label={t('Branch office')}
                name="branch_office"
            />

            <ReactHookFormSelect
                control={control}
                disabled={isDisabled || !branchOffice}
                label={t('Template')}
                name="external_custom_template_id"
                options={getTemplatesToSelect(templates, isNewRecord, planData)}
            />

            <Box display="flex" flexDirection="column">
                <TitleWithDetail variant="body2">
                    {t('Choose the type of installation')}
                </TitleWithDetail>

                <ReactHookFormCheckboxButtonGroup
                    control={control}
                    disabled={isDisabled}
                    items={[
                        {
                            label: (
                                <StyledLabel component="span">
                                    <HomeIcon />
                                    {t('Residential')}
                                </StyledLabel>
                            ),
                            value: 0,
                        },
                        {
                            label: (
                                <StyledLabel component="span">
                                    <StorefrontIcon />
                                    {t('Commercial')}
                                </StyledLabel>
                            ),
                            value: 1,
                        },
                        {
                            label: (
                                <StyledLabel component="span">
                                    <BusinessIcon />
                                    {t('Industrial')}
                                </StyledLabel>
                            ),
                            value: 2,
                        },
                    ]}
                    name="installation_type"
                    row
                />
            </Box>

            <SimulationFields
                canModifyStockExchange={true}
                control={control}
                isDisabled={isDisabled}
            />

            <ReactHookFormSelect
                control={control}
                disabled={isDisabled}
                fullWidth
                label={t('Costing type')}
                name="costing_type"
                options={[
                    { label: t('Per product'), value: 'by_product' },
                    { label: t('Per Watt and kWh'), value: 'by_watt' },
                ]}
            />
        </>
    );
};
TabGeneral.propTypes = {
    branchOffice: PropTypes.string,
    id: PropTypes.string,
    isDisabled: PropTypes.bool,
    isNewRecord: PropTypes.bool,
    planData: PropTypes.object,
    templates: PropTypes.array,
    control: PropTypes.object,
};

export default TabGeneral;
