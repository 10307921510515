import React, { useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, IconButton } from 'sunwise-ui';

import {
    ReactHookFormColorPicker,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';

import * as companyGeneralSelectors from '../../../../companyGeneral/selectors';
import * as actions from '../actions';
import { DEFAULT_VALUES, FONT_SIZES } from '../constants';
import * as selectors from '../selectors';

const ModalForm = ({
    fetchConfig,
    fetchFonts,
    fonts,
    defaultValues,
    isOpen,
    save,
    setIsOpen,
}) => {
    const { t } = useTranslation();

    const { control, handleSubmit, reset } = useForm({
        defaultValues,
    });

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues]);

    const handleOnClickSubmit = (values) => save(values);
    const onClose = () => setIsOpen(false);
    const onEnter = () => {
        fetchConfig();
        fetchFonts();
    };
    const onExited = () => restoreToDefault();
    const restoreToDefault = () => {
        reset(DEFAULT_VALUES);
    };

    return (
        <Dialog
            TransitionProps={{ onEnter, onExited }}
            disableEscapeKeyDown={false}
            fullWidth={true}
            onClose={onClose}
            open={isOpen === null ? false : isOpen}
            sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
        >
            <DialogTitle sx={{ m: 0, p: 2 }}>
                {t('Color palette')}

                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        color: (theme) => theme.palette.grey[500],
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent dividers>
                <form onSubmit={handleSubmit(handleOnClickSubmit)}>
                    <ReactHookFormSelect
                        control={control}
                        label={t('Font family')}
                        name="font.family"
                        options={fonts}
                    />

                    <ReactHookFormSelect
                        control={control}
                        label={t('Font size')}
                        name="font.size"
                        options={FONT_SIZES}
                    />

                    <ReactHookFormColorPicker
                        control={control}
                        fullWidth
                        initialHexColor="#fff"
                        label={t('Primary color')}
                        name="colors.primary"
                    />

                    <ReactHookFormColorPicker
                        control={control}
                        fullWidth
                        initialHexColor="#fff"
                        label={t('Secondary color')}
                        name="colors.secondary"
                    />

                    <ReactHookFormColorPicker
                        control={control}
                        fullWidth
                        initialHexColor="#fff"
                        label={t('Tertiary color')}
                        name="colors.tertiary"
                    />

                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 1,
                            justifyContent: 'flex-end',
                            mt: 2,
                        }}
                    >
                        <Button
                            color="error"
                            onClick={restoreToDefault}
                            variant="text"
                        >
                            {t('Restore')}
                        </Button>

                        <Button
                            color="secondary"
                            onClick={onClose}
                            sx={{ ml: 'auto' }}
                            variant="outlined"
                        >
                            {t('Cancel')}
                        </Button>

                        <Button type="submit">{t('Save')}</Button>
                    </Box>
                </form>
            </DialogContent>
        </Dialog>
    );
};

ModalForm.propTypes = {
    defaultValues: PropTypes.object,
    fetchConfig: PropTypes.func,
    fetchFonts: PropTypes.func,
    fonts: PropTypes.array,
    isOpen: PropTypes.bool,
    save: PropTypes.func,
    setIsOpen: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    companyImage: companyGeneralSelectors.getCompanyImage,
    defaultValues: selectors.getInitialValues,
    fonts: selectors.getFontData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchConfig: () => dispatch(actions.fetchConfig()),
    fetchFonts: () => dispatch(actions.fetchFonts()),
    save: (values) => dispatch(actions.save(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalForm);
