import React, { useEffect, useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, ListItemText, Menu, MenuItem, Tooltip } from 'sunwise-ui';

const BulkEditionMenu = ({ buttonSize, callback, items, selectionModel }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                callback();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [callback]);

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: { xs: 'start', md: 'center' },
                gap: 1,
            }}
        >
            <>
                <Button
                    color="secondary"
                    endIcon={<KeyboardArrowDownIcon />}
                    onClick={handleClick}
                    variant="outlined"
                    size={buttonSize}
                >
                    {`${t('Selected')} (${selectionModel.length})`}
                </Button>

                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    {items.map(({ handleClick, label, visible }, index) =>
                        visible ? (
                            <MenuItem
                                key={`bulk-option-${index}`}
                                onClick={() => {
                                    handleClick();
                                    handleClose();
                                }}
                            >
                                <ListItemText primary={label} />
                            </MenuItem>
                        ) : null,
                    )}
                </Menu>
            </>
            <Tooltip title={t('Press Escape key to cancel')}>
                <Button color="secondary" onClick={callback} variant="outlined">
                    {t('Cancel')}
                </Button>
            </Tooltip>
        </Box>
    );
};

BulkEditionMenu.defaultProps = {
    buttonSize: 'medium',
    handleClickUpdateMargin: () => {},
    show: true,
};

BulkEditionMenu.propTypes = {
    buttonSize: PropTypes.string,
    callback: PropTypes.func,
    items: PropTypes.array,
    selectionModel: PropTypes.array,
};

export default BulkEditionMenu;
