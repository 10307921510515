import React, { useEffect } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, IconButton } from 'sunwise-ui';

import { ReactHookFormSelect } from 'common/components/form/bootstrap';
import { DEBOUNCE_TIME_500 } from 'common/constants';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../../actions';
import { REPORT_TYPES } from '../../constants';
import * as selectors from '../../selectors';
import validatePeriods from '../../validatePeriods';

import GroupsArray from './GroupsArray';
import ModalPeriodsSetup from './ModalPeriodsSetup';

const PeriodSettings = ({
    canModify,
    initialValues,
    isFetching,
    isGenerated,
    isSaving,
    periods,
    proposalPeriods,
    reportId,
    save,
    setIsOpenPeriodsSettingsModal,
}) => {
    const { t } = useTranslation();
    const { control, getValues, handleSubmit, reset, setValue, watch } =
        useForm({
            defaultValues: initialValues,
            resolver: yupResolver(validatePeriods),
        });

    const type = watch('type');

    useEffect(() => {
        reset(initialValues);
    }, [initialValues]);

    const isSinglePeriod = type === REPORT_TYPES.SINGLE_PERIOD;
    const handleOnClose = () => setIsOpenPeriodsSettingsModal(false);
    const onSubmit = handleSubmit((data) =>
        save(reportId, data, handleOnClose),
    );
    const handleOnSave = debounce(onSubmit, DEBOUNCE_TIME_500);

    const handleOnChangeType = (e) => {
        const type = e?.target?.value;
        const values = getValues();
        let groups = [];
        let periods = [];

        if (type === REPORT_TYPES.SINGLE_PERIOD) {
            // only remain one group with one pair of periods
            groups = values.groups.slice(0, 1);
            periods = groups[0].periods.slice(0, 1);
        }
        if (type === REPORT_TYPES.MULTIPERIOD) {
            // only remain one group with all periods
            groups = values.groups.slice(0, 1);
            periods = groups[0].periods;
        }

        setValue('groups', groups);
        setValue('groups.0.periods', periods);
        handleOnSave();
    };

    const disabled = !canModify || isFetching || isSaving || isGenerated;

    return (
        <form>
            <Box
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                gap={5}
            >
                <ReactHookFormSelect
                    control={control}
                    disabled={disabled}
                    label={t('Type')}
                    name="type"
                    onChange={handleOnChangeType}
                    options={[
                        {
                            label: t('Single period'),
                            value: REPORT_TYPES.SINGLE_PERIOD,
                        },
                        {
                            label: t('Multiple periods'),
                            value: REPORT_TYPES.MULTIPERIOD,
                        },
                        // {
                        //    label: t('Grouped periods'),
                        //    value: REPORT_TYPES.GROUPED_PERIODS,
                        // },
                    ]}
                    variant="standard"
                />

                {!isSinglePeriod && (
                    <IconButton
                        onClick={() => setIsOpenPeriodsSettingsModal(true)}
                    >
                        <EditIcon />
                    </IconButton>
                )}
            </Box>
            {isSinglePeriod && (
                <GroupsArray
                    control={control}
                    disabled={disabled}
                    isSinglePeriod={isSinglePeriod}
                    name="groups"
                    onChangePeriod={handleOnSave}
                    periods={periods}
                    proposalPeriods={proposalPeriods}
                    setValue={setValue}
                />
            )}

            <ModalPeriodsSetup
                control={control}
                disabled={disabled}
                handleOnClose={handleOnClose}
                handleOnSave={onSubmit}
                isSinglePeriod={isSinglePeriod}
                periods={periods}
                proposalPeriods={proposalPeriods}
                setValue={setValue}
            />
        </form>
    );
};
const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
    periods: selectors.getHistoricalInfoForSelect,
    proposalPeriods: selectors.getProposalDataForSelect,
});

const mapDispatchToProps = (dispatch) => ({
    save: (reportId, data, callback) =>
        dispatch(actions.save(reportId, data, callback)),
    setIsOpenPeriodsSettingsModal: (isOpen) =>
        dispatch(actions.setIsOpenPeriodsSettingsModal(isOpen)),
});

PeriodSettings.propTypes = {
    canModify: PropTypes.bool,
    initialValues: PropTypes.object,
    isFetching: PropTypes.bool,
    isGenerated: PropTypes.bool,
    isSaving: PropTypes.bool,
    periods: PropTypes.array,
    proposalPeriods: PropTypes.array,
    reportId: PropTypes.string,
    save: PropTypes.func,
    setIsOpenPeriodsSettingsModal: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(PeriodSettings);
