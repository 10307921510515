import { getItems } from 'common/api/v1/fonts';

import {
    FETCH_FONTS,
    FETCH_FONTS_FAILURE,
    FETCH_FONTS_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default () => (dispatch) => {
    dispatch(actions[FETCH_FONTS]());

    getItems().then(
        (response) => {
            dispatch(actions[FETCH_FONTS_SUCCESS](response.data.data));
        },
        (error) => {
            dispatch(actions[FETCH_FONTS_FAILURE](error?.response?.data));
        },
    );
};
