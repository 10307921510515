import React from 'react';

import { styled } from '@mui/material/styles';

import { getFileNameByPath } from 'common/utils/helpers';

const DatasheetUrl = styled('a')`
    color: #848bab;
    cursor: pointer;
    display: flex;
    line-height: 16px;
    overflow-wrap: anywhere;
    text-decoration: none !important;
`;

const ProductDatasheetUrlFormatter = (datasheet) => {
    const file =
        datasheet && datasheet.length > 0 ? getFileNameByPath(datasheet) : '--';

    return (
        <DatasheetUrl href={datasheet} target="_blank" title={file}>
            {file}
        </DatasheetUrl>
    );
};

export default ProductDatasheetUrlFormatter;
