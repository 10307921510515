import { numberFormat } from 'common/utils/helpers';

const COLORS = ['#0073d0', '#03e396'];

export const calculateTotalCols = (breakpoint) => {
    if (['xs', 'sm', 'md'].includes(breakpoint)) return 1;
    return 6;
};

export const calculateTotalRows = ({ layout = [], setTotalRows }) => {
    if (layout.length > 0) {
        const maxYItem = layout.reduce(
            (maxItem, item) => (item.y > maxItem.y ? item : maxItem),
            layout[0],
        );
        const totalHeight = Math.round((maxYItem.y + maxYItem.h) / 2) + 1;
        setTotalRows(totalHeight);
    }
};

export const getChartSettings = ({
    currencyIso,
    currencyLocale,
    currencySymbol,
    labels,
    series,
} = {}) => {
    const data = {
        datasets: series.map(({ data, name }, index) => ({
            backgroundColor: COLORS[index],
            data,
            label: name,
            yAxisID: `y${index}`,
        })),
        labels,
    };

    const options = {
        scales: {
            x: { grid: { display: false } },
            y0: {
                border: { color: COLORS[0] },
                ticks: {
                    callback: (value) =>
                        numberFormat(value, {
                            currency: currencyIso,
                            locale: currencyLocale,
                            style: 'currency',
                            symbol: currencySymbol,
                        }),
                },
            },
            y1: {
                border: { color: COLORS[1] },
                grid: { drawOnChartArea: false },
                position: 'right',
                ticks: {
                    callback: (value) =>
                        numberFormat(value, {
                            decimals: 0,
                            locale: currencyLocale,
                            style: 'decimal',
                            unit: 'kW',
                        }),
                },
            },
        },
    };

    return { data, options };
};

export const getProjectsChartSettings = ({
    currencyLocale,
    labels,
    series,
} = {}) => {
    const data = {
        datasets: [series].map(({ data, name }) => ({
            backgroundColor: COLORS[0],
            data,
            label: name,
        })),
        labels,
    };

    const options = {
        scales: {
            x: { grid: { display: false } },
            y: {
                border: { color: COLORS[0] },
                ticks: {
                    callback: (value) =>
                        numberFormat(value, {
                            decimals: 0,
                            locale: currencyLocale,
                            style: 'decimal',
                        }),
                },
            },
        },
    };

    return { data, options };
};

export const handleDeleteItemBuild = (
    currentBreakpoint,
    itemId,
    layouts,
    setLayouts,
) => {
    const newLayout = layouts[currentBreakpoint].filter(
        (item) => item.i !== itemId,
    );
    setLayouts((prevLayouts) => ({
        ...prevLayouts,
        [currentBreakpoint]: newLayout,
    }));
};

export const handleOnDragStopBuild = (
    currentBreakpoint,
    layout,
    setLayouts,
) => {
    const newLayout = layout.map((item) => {
        if (item.h % 2 !== 0) return { ...item, h: item.h + 1 };
        return item;
    });
    setLayouts((prevLayouts) => ({
        ...prevLayouts,
        [currentBreakpoint]: newLayout,
    }));
};

export const handleResizeStopBuild = (
    currentBreakpoint,
    layout,
    newItem,
    setLayouts,
) => {
    let newH = newItem.h;
    if (newH % 2 !== 0) newH = newH + 1;
    const newLayout = layout.map((item) =>
        item.i === newItem.i ? { ...item, h: newH } : item,
    );
    setLayouts((prevLayouts) => ({
        ...prevLayouts,
        [currentBreakpoint]: newLayout,
    }));
};
