import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, TabPanel } from 'sunwise-ui';

import * as actions from '../../actions';
import {
    TEMPLATE_MODAL_MAIN_DATA,
    TEMPLATE_MODAL_SELECT_BASE,
} from '../../constants';
import * as selectors from '../../selectors';

import MainDataStep from './MainDataStep';
import SelectBaseStep from './SelectBaseStep';
import WizardBreadcrumbs from './WizardBreadcrumbs';

const TemplateWizard = ({
    branchSelected,
    control,
    isForSummaryTemplate,
    languagesDictionary,
    setValue,
    setWizardStep,
    templateMode,
    templateType,
    wizardStep,
}) => (
    <>
        <WizardBreadcrumbs
            setWizardStep={setWizardStep}
            templateMode={templateMode}
            wizardStep={wizardStep}
        />

        <TabPanel selectedTab={wizardStep} value={TEMPLATE_MODAL_MAIN_DATA}>
            <Box sx={{ mb: '-16px!important' }}>
                <MainDataStep
                    branchSelected={branchSelected}
                    control={control}
                    isForSummaryTemplate={isForSummaryTemplate}
                    templateMode={templateMode}
                />
            </Box>
        </TabPanel>

        <TabPanel selectedTab={wizardStep} value={TEMPLATE_MODAL_SELECT_BASE}>
            <Box sx={{ mb: '-16px!important' }}>
                <SelectBaseStep
                    control={control}
                    isForSummaryTemplate={isForSummaryTemplate}
                    languagesDictionary={languagesDictionary}
                    setValue={setValue}
                    templateType={templateType}
                />
            </Box>
        </TabPanel>
    </>
);

const mapStateToProps = createStructuredSelector({
    templateMode: selectors.getTemplateMode,
    wizardStep: selectors.getWizardStep,
});

const mapDispatchToProps = (dispatch) => ({
    setWizardStep: (step) => dispatch(actions.setWizardStep(step)),
});

TemplateWizard.propTypes = {
    branchSelected: PropTypes.string,
    control: PropTypes.object,
    isForSummaryTemplate: PropTypes.bool,
    languagesDictionary: PropTypes.object,
    setValue: PropTypes.func,
    setWizardStep: PropTypes.func,
    templateMode: PropTypes.string,
    templateType: PropTypes.number,
    wizardStep: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateWizard);
