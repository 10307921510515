import React, { useRef, useState } from 'react';

import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { types } from 'sunwise-template-core';
import { Box, FormControl, InputLabel, MenuItem, Select } from 'sunwise-ui';

import { InputSearch } from 'common/components';
import { DEBOUNCE_TIME_500 } from 'common/constants';

const Filter = ({ setFilterData }) => {
    const { t } = useTranslation();
    const [filter, setFilter] = useState({ searchText: '', type: 'all' });
    const debounceFilter = useRef(
        debounce(
            (values) => setFilterData((prev) => ({ ...prev, ...values })),
            DEBOUNCE_TIME_500,
        ),
    ).current;

    const handleOnChangeFilter = (name, value) => {
        setFilter((prev) => ({ ...prev, [name]: value }));
        debounceFilter({
            [name]: name === 'type' && value === 'all' ? null : value,
        });
    };

    return (
        <Box sx={{ alignItems: 'end', display: 'flex', gap: 2 }}>
            <InputSearch
                onChange={(e) =>
                    handleOnChangeFilter('searchText', e.target.value)
                }
                placeholder={t('Search')}
                value={filter?.searchText}
            />

            <FormControl fullWidth size="small">
                <InputLabel>{t('Filter by')}</InputLabel>
                <Select
                    label={t('Filter by')}
                    onChange={(e) =>
                        handleOnChangeFilter('type', e.target.value)
                    }
                    value={filter?.type}
                    variant="standard"
                >
                    <MenuItem value="all">
                        {t('All text', { count: 2 })}
                    </MenuItem>
                    <MenuItem value={types.ONE_PROPOSAL_TYPE}>
                        {t('Proposal')}
                    </MenuItem>
                    <MenuItem value={types.MULTIPROPOSAL_TYPE}>
                        {t('Summary')}
                    </MenuItem>
                    <MenuItem value={types.SMART_DOCUMENTS_TYPE}>
                        {t('Smart document')}
                    </MenuItem>
                    <MenuItem value={types.REPORT_TYPE}>{t('Report')}</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
};

Filter.propTypes = {
    setFilterData: PropTypes.func,
};

export default Filter;
