import React, { useEffect } from 'react';

import { Button, UiDialog as Dialog, Grid } from '@sunwisesoftware/sunwise-ui';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { ReactHookFormInput } from 'common/components/form/bootstrap';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import * as selectors from '../selectors';
import validate from '../validateReport';

const ModalForm = ({
    handleOnClose,
    initialValues,
    isModalFormOpen,
    isSaving,
    redirect,
    save,
}) => {
    const { t } = useTranslation();

    const { control, handleSubmit, reset } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    const handleOnClickSubmit = (values) =>
        save(values, (data) => {
            handleOnClose();
            if (data?.id) redirect(`/report/${data.id}`);
        });

    return (
        <Dialog
            onClose={handleOnClose}
            open={isModalFormOpen}
            size="sm"
            title={t('Report')}
        >
            <form onSubmit={handleSubmit(handleOnClickSubmit)}>
                <ReactHookFormInput
                    control={control}
                    disabled={isSaving}
                    label={t('Report name')}
                    name="name"
                    variant="standard"
                />

                <Grid container mt={2}>
                    <Grid
                        item
                        sx={{
                            display: 'flex',
                            flexDirection: { md: 'row', xs: 'column' },
                            justifyContent: { md: 'right', xs: 'center' },
                        }}
                        xs
                    >
                        <Button
                            color="secondary"
                            disabled={isSaving}
                            onClick={handleOnClose}
                            sx={{
                                mr: { md: 1, xs: 0 },
                                order: { md: 1, xs: 1 },
                                width: { md: 'auto', xs: '100%' },
                            }}
                            variant="text"
                        >
                            {t('Cancel')}
                        </Button>

                        <Button
                            disabled={isSaving}
                            sx={{
                                mb: { md: 0, xs: 1 },
                                order: { md: 1, xs: 1 },
                                width: { md: 'auto', xs: '100%' },
                            }}
                            type="submit"
                            variant="outlined"
                        >
                            {t('Continue')}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getReportInitialValues,
    isModalFormOpen: selectors.getIsReportModalOpen,
    isSaving: selectors.getIsCreatingReport,
});

const mapDispatchToProps = (dispatch) => ({
    redirect: (url) => dispatch(push(url)),
    save: (values, callback) =>
        dispatch(actions.createReport(values, callback)),
});

ModalForm.propTypes = {
    handleOnClose: PropTypes.func,
    initialValues: PropTypes.object,
    isModalFormOpen: PropTypes.bool,
    isSaving: PropTypes.bool,
    redirect: PropTypes.func,
    save: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalForm);
