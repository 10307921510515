import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, Typography } from 'sunwise-ui';

import { parseJSON } from 'common/utils/helpers';

import WarningComponent from '../../proposalGeneratorEnergyBackup/components/WarningComponent';

const Warnings = ({
    handleOnChangeValue,
    initialValues,
    isDisabled,
    saveContext,
    warnings,
}) => {
    const { t } = useTranslation();
    const [hideWarnings, setHideWarnings] = useState(false);
    let hasBatteryWarning = false;

    const handleUpdate = ({ product_branch_office, quantity }) => {
        handleOnChangeValue({ product_branch_office, quantity }, saveContext);
        setHideWarnings(true);
    };

    const renderWarnings = (warnings) => {
        if (!warnings || warnings.length === 0 || hideWarnings) return;

        const content = warnings.map((warning, i) => {
            const jsonWarning = parseJSON(warning);
            if (jsonWarning?.type === 'battery') hasBatteryWarning = true;
            return (
                <Typography variant="body2" key={`warning-${i}`}>
                    {jsonWarning?.msg?.replace(/&#8217;/g, "'")}
                </Typography>
            );
        });

        return (
            <WarningComponent
                body={content}
                title={t('Alert')}
                variant="warning"
            />
        );
    };

    const renderButton = (currentProducts, handleUpdate) => {
        if (
            hasBatteryWarning &&
            currentProducts?.battery &&
            (currentProducts.battery[0].quantity !==
                parseInt(initialValues?.quantity) ||
                currentProducts.battery[0].product_branch_office.id !==
                    initialValues?.product_branch_office)
        ) {
            return (
                <Box mt={2}>
                    <Button
                        disabled={isDisabled}
                        fontSize="14px"
                        fontWeight="bold"
                        key={`energy-backup-update-batteries-button`}
                        onClick={() =>
                            handleUpdate({
                                product_branch_office:
                                    currentProducts.battery[0]
                                        .product_branch_office.id,
                                quantity: currentProducts.battery[0].quantity,
                            })
                        }
                        size="sm"
                        sx={{
                            mb: '0 !important',
                            width: { xs: '100%', md: 'auto' },
                        }}
                        type="button"
                        variant="confirm"
                    >
                        {t('Update information')}
                    </Button>
                </Box>
            );
        }
        return null;
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Grid container>
                <Grid item xs={18}>
                    {renderWarnings(warnings)}
                    {renderButton(saveContext?.batteryOffer, handleUpdate)}
                </Grid>
            </Grid>
        </Box>
    );
};

Warnings.propTypes = {
    handleOnChangeValue: PropTypes.func,
    initialValues: PropTypes.object,
    isDisabled: PropTypes.bool,
    saveContext: PropTypes.object,
    warnings: PropTypes.array,
};

export default Warnings;
