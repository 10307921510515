export const NAME = 'trackings';

export const FILTER_TYPE_CALL = 0;
export const FILTER_TYPE_MAIL = 1;
export const FILTER_TYPE_VISIT = 2;
export const FILTER_TYPE_MESSAGE = 3;
export const FILTER_TYPE_OTHER = 4;

export const OBJECTIVE_RECEIPT = 0;
export const OBJECTIVE_TRACKING = 1;
export const OBJECTIVE_INFORMATION = 2;
export const OBJECTIVE_SCHEDULE_APPOINTMENT = 3;
export const OBJECTIVE_OTHER = 4;

export const HOUR_9_AM = 9;
export const HOUR_10_AM = 10;
export const HOUR_11_AM = 11;
export const HOUR_12_PM = 12;
export const HOUR_1_PM = 13;
export const HOUR_2_PM = 14;
export const HOUR_3_PM = 15;
export const HOUR_4_PM = 16;
export const HOUR_5_PM = 17;
export const HOUR_6_PM = 18;
export const HOUR_7_PM = 19;

export const ORDER_BY_PAST = -1;
export const ORDER_ALL = 0;
export const ORDER_BY_NEXT = 1;

export const CONTACT_TRACKING_TYPE = 0;

export const MAXIMUM_CHARACTERS_FOR_TRACKING = 250;
