import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';
import { createSelector } from 'reselect';

import orm from 'common/orm';

import { NAME } from './constants';

export const getModel = (state) => {
    return state[NAME];
};

export const getEntitiesSession = createSelector(getModel, (state) =>
    orm.session(state.entities),
);

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues,
);

export const getContacts = createSelector(
    getModel,
    (model) => model.contactsTrackings,
);

export const getAgents = createSelector(getModel, (model) => model.agents);

export const getAgentsData = createSelector(getAgents, (model) => model.data);

export const getFetchCommercialOffers = createSelector(
    getModel,
    (model) => model.commercialOffers,
);

export const getIsFetchingCommercialOffers = createSelector(
    getFetchCommercialOffers,
    (fetchCommercialOffers) => fetchCommercialOffers.isLoading,
);

export const getDataFetchCommercialOffers = createSelector(
    getFetchCommercialOffers,
    (fetchCommercialOffers) => {
        if (isEmpty(fetchCommercialOffers.data)) {
            return [];
        }
        return fetchCommercialOffers.data.filter(
            ({ is_archived }) => !is_archived,
        );
    },
);

export const getErrorsFetchCommercialOffers = createSelector(
    getFetchCommercialOffers,
    (fetchCommercialOffers) => fetchCommercialOffers.errors,
);

export const getDashboardConfig = createSelector(
    getModel,
    (model) => model.dashboardConfig,
);

export const getIsFetchingDashboardConfig = createSelector(
    getDashboardConfig,
    (model) => model.isFetching,
);

export const getDashboardConfigData = createSelector(
    getDashboardConfig,
    (model) => model.data,
);

export const getDashboardConfigErrors = createSelector(
    getDashboardConfig,
    (model) => model.errors,
);

export const getContactsTrackingDataLoading = createSelector(
    getModel,
    (model) => model.contactsTrackings.isLoading,
);

export const getContactsTrackingData = createSelector(
    getContacts,
    (contacts) => contacts.data,
);

export const getProject = createSelector(getModel, (model) => model.project);

export const getProjectsSalesStage = createSelector(
    getModel,
    (model) => model.projectsSalesStage,
);

export const getIsFetchingProjectsSalesStage = createSelector(
    getProjectsSalesStage,
    (model) => model.isLoading,
);

export const getProjectsSalesStageData = createSelector(
    getProjectsSalesStage,
    (model) => {
        let projectsKw = [i18next.t('Total power (kW)')];
        let projectsNumber = [i18next.t('Number of projects')];
        let projectsTotal = [i18next.t('Total value')];

        const columns = model.data.map((data) => {
            projectsNumber.push(data.projects_number);
            projectsTotal.push(data.projects_total);
            projectsKw.push(data.projects_kw);

            return {
                color: data.color,
                title: data.status,
            };
        });

        const rows = { projectsNumber, projectsTotal, projectsKw };

        return { columns, rows };
    },
);

export const getTotalProjectsIssued = createSelector(
    getProjectsSalesStage,
    (model) =>
        model.data.reduce(
            (valorAnterior, valorActual) =>
                valorAnterior + valorActual.projects_number,
            0,
        ),
);

export const getTotalMoneyProjects = createSelector(
    getProjectsSalesStage,
    (model) =>
        model.data.reduce(
            (valorAnterior, valorActual) =>
                valorAnterior + valorActual.projects_total,
            0,
        ),
);

export const getTotalKwProjects = createSelector(
    getProjectsSalesStage,
    (model) =>
        model.data.reduce(
            (valorAnterior, valorActual) =>
                valorAnterior + valorActual.projects_kw,
            0,
        ),
);

export const getProjectsCounters = createSelector(
    getModel,
    (model) => model.projectsCounters,
);

export const getProjectsCountersData = createSelector(
    getProjectsCounters,
    (model) => model.data,
);

export const getIsFetchingProjectsCounters = createSelector(
    getProjectsCounters,
    (model) => model.isLoading,
);

export const getProjectsCountersErrors = createSelector(
    getProjectsCounters,
    (model) => model.error,
);

export const getProjectsSalesStageIsLoading = createSelector(
    getProjectsSalesStage,
    (model) => model.isLoading,
);

export const getProjectsSalesStageErrors = createSelector(
    getProjectsSalesStage,
    (model) => model.error,
);

export const getProposalsTotals = createSelector(
    getModel,
    (model) => model.proposalsTotals,
);

export const getProposalsTotalsData = createSelector(
    getProposalsTotals,
    (model) => model.data,
);

export const getProposalsTotalsIsLoading = createSelector(
    getProposalsTotals,
    (model) => model.isLoading,
);

export const getProposalsTotalsErrors = createSelector(
    getProposalsTotals,
    (model) => model.error,
);

export const getRates = createSelector(getModel, (model) => model.rates);

export const getIsLoadingRates = createSelector(
    getRates,
    (rates) => rates.isLoading,
);

export const getRatesData = createSelector(getEntitiesSession, ({ Rate }) => {
    return Rate.all().toRefArray();
});

export const getRatesForSelect = createSelector(getRatesData, (rates) => {
    const items = rates.map((rate) => {
        return {
            label: rate.name,
            value: rate.id,
        };
    });

    items.unshift({
        label: i18next.t('All text', { context: 'female', count: 2 }),
        value: null,
    });
    return items;
});

export const getGroupedRatesForSelect = createSelector(
    getRatesData,
    (rates) => {
        return [
            {
                label: i18next.t('All text', { context: 'female', count: 2 }),
                value: '',
            },
            ...Object.values(
                rates.reduce((acc, rate) => {
                    const brandName = rate.isCertified
                        ? i18next.t('Certificated')
                        : i18next.t('Custom', { context: 'female', count: 2 });
                    let option = {};
                    if (rate.isCertified) {
                        option = {
                            label: rate.name,
                            value: rate.id,
                        };
                    } else {
                        option = {
                            label: rate.name,
                            value: rate.id,
                        };
                    }
                    if (!acc[brandName]) {
                        return {
                            ...acc,
                            [brandName]: {
                                label: brandName,
                                options: [option],
                            },
                        };
                    }
                    return {
                        ...acc,
                        [brandName]: {
                            ...acc[brandName],
                            options: [...acc[brandName].options, option],
                        },
                    };
                }, {}),
            ),
        ];
    },
);

export const getRatesForSelectFromFunnel = createSelector(
    getRatesData,
    (rates) => {
        return [
            {
                label: i18next.t('All text', { context: 'female', count: 2 }),
                value: '',
            },
            { label: i18next.t('No consumption'), value: 'unassigned' },
            ...Object.values(
                rates.reduce((acc, rate) => {
                    const brandName = rate.isCertified
                        ? i18next.t('Certificated')
                        : i18next.t('Custom', { context: 'female', count: 2 });
                    let option = {};
                    if (rate.isCertified) {
                        option = {
                            label: rate.name,
                            value: rate.id,
                        };
                    } else {
                        option = {
                            label: rate.name,
                            value: rate.id,
                        };
                    }
                    if (!acc[brandName]) {
                        return {
                            ...acc,
                            [brandName]: {
                                label: brandName,
                                options: [option],
                            },
                        };
                    }
                    return {
                        ...acc,
                        [brandName]: {
                            ...acc[brandName],
                            options: [...acc[brandName].options, option],
                        },
                    };
                }, {}),
            ),
        ];
    },
);

export const getSaveDashboardConfig = createSelector(
    getModel,
    (model) => model.saveDashboardConfig,
);

export const getIsSavingDashboardConfig = createSelector(
    getSaveDashboardConfig,
    (model) => model.isSaving,
);

export const getSaveDashboardConfigErrors = createSelector(
    getSaveDashboardConfig,
    (model) => model.errors,
);
