import React from 'react';

import PropTypes from 'prop-types';
import { Box, Card } from 'sunwise-ui';

import DashboardCardHeader from 'common/components/cards/DashboardCardHeader';
import PlaceholderText from 'common/components/placeholder/PlaceholderText';
import { scroll } from 'common/utils/mixins';

import LabelAppend from './LabelAppend';
import LabelTotal from './LabelTotal';

const CardKeyValue = ({
    append,
    id,
    menuOptions,
    prepend,
    isLoading,
    title,
    value,
}) => {
    return (
        <Card id={id} sx={{ mb: 0, minHeight: '100%', width: '100%' }}>
            <Card.Header
                sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <DashboardCardHeader title={title} height="auto" />
                {menuOptions}
            </Card.Header>

            <Card.Body
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'calc(100% - 73px)',
                    overflowY: 'auto',
                    ...scroll.object,
                }}
            >
                <PlaceholderText ready={isLoading}>
                    <Box sx={{ textAlign: 'center' }}>
                        {prepend !== null && (
                            <LabelAppend>{prepend}</LabelAppend>
                        )}
                        {value !== null && <LabelTotal>{value}</LabelTotal>}
                        {append !== null && <LabelAppend>{append}</LabelAppend>}
                    </Box>
                </PlaceholderText>
            </Card.Body>
        </Card>
    );
};

CardKeyValue.propTypes = {
    append: PropTypes.string,
    id: PropTypes.string,
    menuOptions: PropTypes.object,
    prepend: PropTypes.string,
    isLoading: PropTypes.bool,
    title: PropTypes.string,
    value: PropTypes.string,
};

export default CardKeyValue;
