import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_OFFER_PRODUCTS,
    FETCH_OFFER_PRODUCTS_FAILURE,
    FETCH_OFFER_PRODUCTS_SUCCESS,
    FETCH_PROPOSAL_CATALOGS,
    FETCH_PROPOSAL_CATALOGS_FAILURE,
    FETCH_PROPOSAL_CATALOGS_SUCCESS,
    FETCH_TEMPLATES,
    FETCH_TEMPLATES_FAILURE,
    FETCH_TEMPLATES_SUCCESS,
    INITIALIZE_COSTING_FORM,
    INITIALIZE_DISCOUNT_FORM,
    INITIALIZE_UPDATE_PRODUCTS_FORM,
    RESET_DATA,
    RESET_COSTING_FORM,
    RESET_UPDATE_PRODUCTS_FORM,
    SAVE_BULK_UPDATE_PRODUCTS,
    SAVE_BULK_UPDATE_PRODUCTS_FAILURE,
    SAVE_BULK_UPDATE_PRODUCTS_SUCCESS,
    SAVE_COSTING,
    SAVE_COSTING_FAILURE,
    SAVE_COSTING_SUCCESS,
    SAVE_DISCOUNT,
    SAVE_DISCOUNT_FAILURE,
    SAVE_DISCOUNT_SUCCESS,
    SET_BULK_ACTION,
    SET_IS_OPEN_MODAL,
    SET_PRODUCTS_TOTALS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    bulkAction: null,
    bulkUpdateProducts: {
        errors: [],
        isSaving: false,
    },
    initialProductsUpdateValues: {
        discount: 0,
        margin: 0,
        selectionModel: [],
    },
    initialCostingValues: {
        final_storage_kwh: 0,
        kwh_cost: 0,
        kwh_margin: 0,
        kwh_price: 0,
        kwh_total_amount: 0,
        price_per_watt_local: 0,
        system_size: 0,
        type_change: 0,
        watt_cost: 0,
        watt_margin: 0,
        watt_price: 0,
        watt_total_amount: 0,
    },
    initialDiscountValues: {
        discount_amount: 0,
        discount_percentage: 0,
        price_per_watt: 0,
        subtotal_amount: 0,
        type_discount: 0,
        type_subtotal: 0,
    },
    isOpenModal: false,
    offerProducts: {
        data: {},
        errors: [],
        isFetching: true,
    },
    offerProposalCatalogs: {
        data: {},
        errors: [],
        isFetching: true,
    },
    productsTotals: {
        profit: 0,
        profitPercentage: 0,
        subtotal: 0,
        subtotalWithDiscount: 0,
        total: 0,
        totalCost: 0,
        totalTaxes: 0,
    },
    templates: {
        data: [],
        errors: [],
        isFetching: true,
    },
    saveCosting: {
        errors: [],
        isSaving: false,
    },
    saveDiscount: {
        errors: [],
        isSaving: false,
    },
};

const proposalGeneratorQuoteSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_OFFER_PRODUCTS]: (state) => {
            state.offerProducts = {
                ...state.offerProducts,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_OFFER_PRODUCTS_FAILURE]: (state, action) => {
            state.offerProducts = {
                ...state.offerProducts,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_OFFER_PRODUCTS_SUCCESS]: (state, action) => {
            state.offerProducts = {
                ...state.offerProducts,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PROPOSAL_CATALOGS]: (state) => {
            state.offerProposalCatalogs = {
                ...state.offerProposalCatalogs,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_PROPOSAL_CATALOGS_FAILURE]: (state, action) => {
            state.offerProposalCatalogs = {
                ...state.offerProposalCatalogs,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PROPOSAL_CATALOGS_SUCCESS]: (state, action) => {
            state.offerProposalCatalogs = {
                ...state.offerProposalCatalogs,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_TEMPLATES]: (state) => {
            state.templates = {
                ...state.templates,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_TEMPLATES_FAILURE]: (state, action) => {
            state.templates = {
                ...state.templates,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_TEMPLATES_SUCCESS]: (state, action) => {
            state.offerProposalCatalogs = {
                ...state.offerProposalCatalogs,
                data: {
                    ...state.offerProposalCatalogs.data,
                    custom_templates: action.payload,
                },
            };
            state.templates = {
                ...state.templates,
                data: action.payload,
                errors: [],
                isFetching: false,
            };
        },
        [INITIALIZE_COSTING_FORM]: (state, action) => {
            state.initialCostingValues = {
                ...state.initialCostingValues,
                ...action.payload,
            };
        },
        [INITIALIZE_DISCOUNT_FORM]: (state, action) => {
            state.initialDiscountValues = {
                ...state.initialDiscountValues,
                ...action.payload,
            };
        },
        [INITIALIZE_UPDATE_PRODUCTS_FORM]: (state, action) => {
            state.initialProductsUpdateValues = {
                ...state.initialProductsUpdateValues,
                ...action.payload,
            };
        },
        [RESET_DATA]: () => INITIAL_STATE,
        [RESET_COSTING_FORM]: (state) => {
            state.initialCostingValues = INITIAL_STATE.initialCostingValues;
        },
        [RESET_UPDATE_PRODUCTS_FORM]: (state) => {
            state.initialProductsUpdateValues =
                INITIAL_STATE.initialProductsUpdateValues;
        },
        [SAVE_BULK_UPDATE_PRODUCTS]: (state) => {
            state.bulkUpdateProducts.isSaving = true;
        },
        [SAVE_BULK_UPDATE_PRODUCTS_FAILURE]: (state, action) => {
            state.bulkUpdateProducts = {
                ...state.bulkUpdateProducts,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_BULK_UPDATE_PRODUCTS_SUCCESS]: (state) => {
            state.bulkUpdateProducts.isSaving = false;
        },
        [SAVE_COSTING]: (state) => {
            state.saveCosting.isSaving = true;
        },
        [SAVE_COSTING_FAILURE]: (state, action) => {
            state.saveCosting = {
                ...state.saveCosting,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_COSTING_SUCCESS]: (state) => {
            state.saveCosting.isSaving = false;
        },
        [SAVE_DISCOUNT]: (state) => {
            state.saveDiscount.isSaving = true;
        },
        [SAVE_DISCOUNT_FAILURE]: (state, action) => {
            state.saveDiscount = {
                ...state.saveDiscount,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_DISCOUNT_SUCCESS]: (state) => {
            state.saveDiscount.isSaving = false;
        },
        [SET_BULK_ACTION]: (state, action) => {
            state.bulkAction = action.payload;
        },
        [SET_IS_OPEN_MODAL]: (state, action) => {
            state.isOpenModal = action.payload;
        },
        [SET_PRODUCTS_TOTALS]: (state, action) => {
            state.productsTotals = action.payload;
        },
    },
});

export const proposalGeneratorQuoteActions =
    proposalGeneratorQuoteSlice.actions;

export default proposalGeneratorQuoteSlice.reducer;
