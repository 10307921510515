import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid } from 'sunwise-ui';

import KeyIndicator from 'common/components/KeyIndicator';
import { numberFormat } from 'common/utils/helpers';

import { getPercentageVariation } from '../helpers';
import * as selectors from '../selectors';

const Indicators = ({ countryCurrencyLocale, indicatorsData }) => {
    const { t } = useTranslation();

    const numberFormatOptions = {
        decimals: 0,
        locale: countryCurrencyLocale,
        style: 'decimal',
    };
    const {
        active_projects,
        alert_projects,
        current_month_total_generated,
        current_month_total_projects,
        global_total_generated,
        global_total_projects,
        inactive_projects,
        last_month_total_generated,
        last_month_total_projects,
        unmonitored_project,
    } = indicatorsData || {};

    const generationVariation = getPercentageVariation(
        current_month_total_generated,
        last_month_total_generated,
    );
    const projectsVariation = getPercentageVariation(
        current_month_total_projects,
        last_month_total_projects,
    );

    const indicatorsValues = [
        {
            key: 'historical_generation',
            name: t('Historical generation'),
            unit: 'kWh',
            value: global_total_generated,
        },
        {
            key: 'total_projects',
            name: t('Total projects'),
            value: global_total_projects,
        },
        {
            infoTitle: t('Compared against the previous month'),
            key: 'monthly_generation',
            name: t('Monthly generation'),
            unit: 'kWh',
            value: current_month_total_generated,
            variation: generationVariation,
        },
        {
            infoTitle: t('Compared against the previous month'),
            key: 'monthly_projects',
            name: t('Monthly projects'),
            value: current_month_total_projects,
            variation: projectsVariation,
        },
        {
            key: 'online_projects',
            name: t('Online project', { count: 2 }),
            value: active_projects,
        },
        {
            key: 'alert_projects',
            name: t('Unstable projects'),
            value: alert_projects,
        },
        {
            key: 'offline_projects',
            name: t('Offline project', { count: 2 }),
            value: inactive_projects,
        },
        {
            key: 'unmonitored_project',
            name: t('Unmonitored project', { count: 2 }),
            value: unmonitored_project,
        },
    ];

    return (
        <Grid container>
            {indicatorsValues?.map((indicator) => {
                const {
                    infoTitle,
                    isString,
                    key,
                    name,
                    unit,
                    value,
                    variation,
                } = indicator;

                const _value = isString
                    ? value
                    : numberFormat(value || 0, numberFormatOptions);

                const variationValue = variation
                    ? numberFormat(variation, numberFormatOptions)
                    : null;

                return (
                    <Grid item key={key} xs={18} sm={9} md={6} lg={4.5}>
                        <KeyIndicator
                            infoTitle={infoTitle}
                            title={name}
                            unit={unit}
                            value={_value}
                            variation={variation}
                            variationValue={variationValue}
                            widthIcon="20px"
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
};

const mapStateToProps = createStructuredSelector({
    indicatorsData: selectors.getIndicatorsData,
});

Indicators.propTypes = {
    countryCurrencyLocale: PropTypes.string,
    indicatorsData: PropTypes.object,
};

export default connect(mapStateToProps)(Indicators);
