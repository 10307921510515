import React from 'react';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { types } from 'sunwise-template-core';
import { Box, Typography } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';
import { formatDate } from 'common/utils/dates';
import { fullNameBuild } from 'common/utils/helpers';

import BranchOfficeFormatter from '../BranchOfficeFormatter';

const CustomTemplateColumns = ({
    branchOfficesDictionary,
    companyUsersData,
    isForSummaryTemplates,
    languagesDictionary,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();
    const getType = (type) => {
        const typesMap = {
            [types.ONE_PROPOSAL_TYPE]: t('Proposal'),
            [types.MULTIPROPOSAL_TYPE]: t('Summary'),
            [types.SMART_DOCUMENTS_TYPE]: t('Smart document'),
            [types.REPORT_TYPE]: t('Report'),
        };
        return typesMap?.[type] || t('Not available');
    };

    const nameColumn = [
        {
            align: 'left',
            field: 'title',
            flex: 1,
            headerName: t('Title'),
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Box display="flex" flexDirection="column">
                        <Typography variant="caption">{row.title}</Typography>
                        <Typography variant="caption">
                            {row.pages} {t('Sheet', { count: row.pages })}
                        </Typography>
                    </Box>
                );
            },
        },
    ];

    let columns = [
        ...nameColumn,
        {
            align: 'left',
            field: 'branch_office',
            flex: 1,
            headerName: t('Branch office'),
            renderCell: (params) => {
                const { row } = params;
                return (
                    <BranchOfficeFormatter
                        branchOfficesDictionary={branchOfficesDictionary}
                        row={row}
                    />
                );
            },
        },
        {
            align: 'left',
            field: 'proposals_number',
            flex: 1,
            headerName: t('Proposal', { count: 2 }),
            hide: !isForSummaryTemplates,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Box alignItems="center" display="flex" gap={1}>
                        <FileCopyIcon fontSize="small" />
                        <Typography variant="body2">
                            {row?.proposals_number}{' '}
                            {t('Proposal', {
                                count: row?.proposals_number,
                            })}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            align: 'left',
            field: 'language',
            flex: 1,
            headerName: t('Language'),
            renderCell: (params) => {
                const { row } = params;
                if (languagesDictionary[row?.language])
                    return languagesDictionary[row?.language]?.language;
                return null;
            },
        },
        {
            align: 'left',
            field: 'type',
            flex: 1,
            headerName: t('Type'),
            renderCell: (params) => {
                const { row } = params;
                return getType(row?.type);
            },
        },
        {
            align: 'left',
            field: 'created_by',
            flex: 1,
            headerName: t('Created by'),
            renderCell: (params) => {
                const { row } = params;
                const user = companyUsersData.find(
                    (user) => user.id === row.created_by,
                );
                return user
                    ? fullNameBuild({
                          firstName: user.first_name,
                          lastName: user.last_name,
                      })
                    : t('Not available');
            },
        },
        {
            align: 'left',
            field: 'created_at',
            flex: 1,
            headerName: t('Creation date'),
            renderCell: (params) => {
                const { row } = params;
                const date = new Date(row?.created_at);
                return formatDate(date, 'yyyy-MM-dd');
            },
        },
    ];

    if (['xs', 'sm'].includes(breakpoint)) {
        columns = nameColumn;
    }

    return columns;
};

CustomTemplateColumns.propTypes = {
    branchOfficesDictionary: PropTypes.array,
    companyUsersData: PropTypes.array,
    isForSummaryTemplates: PropTypes.bool,
    languagesDictionary: PropTypes.object,
};

export default CustomTemplateColumns;
