export const NAME = 'after-sales-monitoring';

export const CHART_COLORS = {
    accumulated_savings: ['#BFBBBD', '#3CAF5F'],
    generation_accuracy: ['#FFC107', '#FF590D'],
    generation_ratio: ['#FF590D'],
    measurement_source: ['#FF590D', '#20C4D9', '#8BC34A', '#BFBBBD', '#E91E63'],
    roi_progress: ['#3CAF5F'],
};

export const VIEW_OPTIONS = {
    ANNUAL: 0,
    MONTHLY: 1,
    DAILY_HOUR: 2,
    DAILY_5_MINUTES: 3,
};

export const DATE_OPTIONS = {
    [VIEW_OPTIONS.ANNUAL]: {
        format: 'yyyy',
        inputFormat: 'yyyy',
        views: ['year'],
    },
    [VIEW_OPTIONS.MONTHLY]: {
        format: 'MMM yyyy',
        inputFormat: 'MMM yyyy',
        views: ['year', 'month'],
    },
    [VIEW_OPTIONS.DAILY_HOUR]: {
        views: ['year', 'month', 'day'],
    },
    [VIEW_OPTIONS.DAILY_5_MINUTES]: {
        views: ['year', 'month', 'day'],
    },
};

export const STEPS_IN_HOUR = {
    [VIEW_OPTIONS.DAILY_5_MINUTES]: 12,
};

export const SYNC_PROGRESS_STATUS = {
    FAILED: 'failed',
    SUCCESS: 'success',
};

export const SYNC_TYPES = {
    INITIAL: 'fetch_project_initial_data',
    DAILY: 'fetch_project_data',
};

export const SYNC_TYPES_LABELS = {
    [SYNC_TYPES.INITIAL]: 'Historical',
    [SYNC_TYPES.DAILY]: 'Day',
};

export const SYNC_EVENT_TYPES = {
    ERROR: 'error',
    PROGRESS: 'progress',
    SUCCESS: 'success',
};
