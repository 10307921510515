import React, { useRef, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { styled } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { Responsive as ResponsiveReactGridLayout } from 'react-grid-layout';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import {
    Box,
    Button,
    Container as MaterialContainer,
    Grid,
    Skeleton,
} from 'sunwise-ui';

import { GridLayout, HeaderContainer, TitleIcon } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { useBreakpoint } from 'common/hooks';
import {
    getCurrencyIso,
    getCurrencyLocale,
    getCurrencySymbol,
} from 'common/utils/helpers';

import * as companyGeneralSelectors from '../companyGeneral/selectors';

import * as actions from './actions';
import PanelConfigModal from './components/PanelConfigModal';
import PanelContent from './components/PanelContent';
import {
    calculateTotalCols,
    calculateTotalRows,
    handleDeleteItemBuild,
    handleOnDragStopBuild,
    handleResizeStopBuild,
} from './helpers';
import * as selectors from './selectors';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const StyledItem = styled(Box)`
    border: 2px dashed
        ${({ theme }) =>
            theme.palette.mode === 'dark' ? '#757575' : '#CFD8DC'};
    border-radius: 8px;
    display: flex;
    justify-content: center;
    height: 168px;
    width: 100%;
`;

const Container = ({
    agents,
    canView,
    dashboardConfigData,
    defaultCurrency,
    drawerSize,
    fetchAgents,
    fetchDashboardConfig,
    fetchRates,
    groupedRates,
    isFetchingDashboardConfig,
    isFetchingProjectsSalesStage,
    isSavingDashboardConfig,
    saveDashboardConfig,
    totalKwProjects,
    totalMoneyProjects,
    totalProjectsIssued,
}) => {
    const boxRef = useRef(null);
    const gridRef = useRef(null);
    const [boxWidth, setBoxWidth] = useState(window.innerWidth);
    const [currentBreakpoint, setCurrentBreakpoint] = useState('lg');
    const [isEditMode, setIsEditMode] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [layouts, setLayouts] = useState({});
    const [selectedLayout, setSelectedLayout] = useState([]);
    const [totalRows, setTotalRows] = useState(1);
    const breakpoint = useBreakpoint();

    const contentConfig = {
        1: { key: 'total-proposals-issued' },
        2: { key: 'total-value-of-the-proposals-issued' },
        3: { key: 'total-power-of-the-proposals-issued' },
        4: { key: 'proposals-issued' },
        5: { key: 'follow-ups' },
        6: { key: 'last-offers' },
        7: { key: 'project-status' },
    };

    useEffect(() => {
        fetchAgents();
        fetchDashboardConfig();
        fetchRates();
    }, []);

    useEffect(() => {
        if (boxRef.current) {
            setBoxWidth(boxRef.current.offsetWidth);
        }

        const handleResize = () => {
            if (boxRef.current) {
                setBoxWidth(boxRef.current.offsetWidth);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [boxRef, drawerSize, isEditMode]);

    useEffect(() => {
        if (breakpoint === 'xl') setCurrentBreakpoint('lg');
        else setCurrentBreakpoint(breakpoint);
    }, [breakpoint]);

    useEffect(() => {
        if (!isEmpty(dashboardConfigData)) {
            setLayouts(dashboardConfigData);
            setSelectedLayout(dashboardConfigData[currentBreakpoint]);
            calculateTotalRows({
                layout: dashboardConfigData[currentBreakpoint],
                setTotalRows,
            });
        }
    }, [dashboardConfigData]);

    useEffect(() => {
        if (isEmpty(layouts)) return;
        setSelectedLayout(layouts[currentBreakpoint]);
        calculateTotalRows({
            layout: layouts[currentBreakpoint],
            setTotalRows,
        });
    }, [layouts, currentBreakpoint]);

    const { t } = useTranslation();

    const currencyIso = getCurrencyIso(defaultCurrency);
    const currencyLocale = getCurrencyLocale(defaultCurrency);
    const currencySymbol = getCurrencySymbol(defaultCurrency);

    const handleAddItem = () => {
        const newLayouts = { ...layouts };
        const currentLayout = newLayouts[currentBreakpoint] || [];
        const newItemId = String(
            currentLayout.length > 0
                ? Math.max(
                      ...currentLayout.map((item) => parseInt(item.i, 10)),
                  ) + 1
                : 1,
        );

        Object.keys(newLayouts).forEach((breakpoint) => {
            const lastItem = newLayouts[breakpoint].reduce(
                (prev, current) =>
                    prev.y + prev.h > current.y + current.h ? prev : current,
                { y: 0, h: 0 },
            );
            const newItem = {
                i: newItemId,
                x: 0,
                y: lastItem.y + lastItem.h,
                w: breakpoint === 'lg' ? 6 : 1,
                h: breakpoint === 'lg' ? 4 : 2,
            };
            newLayouts[breakpoint] = [...newLayouts[breakpoint], newItem];
        });
        setLayouts(newLayouts);
        calculateTotalRows({
            layout: newLayouts[currentBreakpoint],
            setTotalRows,
        });
    };

    const handleDeleteItem = (itemId) =>
        handleDeleteItemBuild(currentBreakpoint, itemId, layouts, setLayouts);

    const handleOnDragStop = (layout) =>
        handleOnDragStopBuild(currentBreakpoint, layout, setLayouts);

    const handleResizeStop = (layout, _, newItem) =>
        handleResizeStopBuild(currentBreakpoint, layout, newItem, setLayouts);

    const renderContent = () => {
        if (isFetchingDashboardConfig)
            return (
                <Grid container mt={1}>
                    <Grid item xs={18} md={6}>
                        <Skeleton height={200} variant="rounded" />
                    </Grid>
                    <Grid item xs={18} md={6}>
                        <Skeleton height={200} variant="rounded" />
                    </Grid>
                    <Grid item xs={18} md={6}>
                        <Skeleton height={200} variant="rounded" />
                    </Grid>
                </Grid>
            );
        return (
            <>
                <GridLayout
                    cols={calculateTotalCols(currentBreakpoint)}
                    rows={totalRows}
                    gridRef={gridRef}
                    sxPaper={{
                        ...(!isEditMode && {
                            border: 0,
                            background: 'transparent',
                        }),
                    }}
                />
                <ResponsiveReactGridLayout
                    breakpoints={{
                        lg: 1200,
                        md: 900,
                        sm: 600,
                        xs: 480,
                        xxs: 0,
                    }}
                    cols={{ lg: 6, md: 1, sm: 1, xs: 1, xxs: 1 }}
                    className="layout"
                    draggableHandle=".drag-handle"
                    isDraggable={isEditMode}
                    isResizable={isEditMode}
                    layouts={layouts}
                    margin={[16, 16]}
                    onDragStop={handleOnDragStop}
                    onResizeStop={handleResizeStop}
                    rowHeight={30}
                    style={{ width: '100%', height: '100%' }}
                    width={
                        gridRef.current?.offsetWidth + 16 === boxWidth
                            ? boxWidth
                            : boxWidth - 15
                    }
                >
                    {selectedLayout?.map((layout) => (
                        <StyledItem
                            className="grid-item"
                            key={layout.i}
                            sx={{ ...(!isEditMode && { border: 0 }) }}
                        >
                            {isEditMode && <span className="drag-handle" />}
                            <PanelContent
                                agents={agents}
                                currencyIso={currencyIso}
                                currencyLocale={currencyLocale}
                                currencySymbol={currencySymbol}
                                defaultCurrency={defaultCurrency}
                                groupedRates={groupedRates}
                                handleDeleteItem={handleDeleteItem}
                                isEditMode={isEditMode}
                                isFetchingProjectsSalesStage={
                                    isFetchingProjectsSalesStage
                                }
                                panelKey={contentConfig[layout.i]?.key}
                                totalKwProjects={totalKwProjects}
                                totalMoneyProjects={totalMoneyProjects}
                                totalProjectsIssued={totalProjectsIssued}
                            />
                        </StyledItem>
                    ))}
                </ResponsiveReactGridLayout>
            </>
        );
    };

    if (!canView) return null;

    return (
        <>
            <MaterialContainer maxWidth={false}>
                <HeaderContainer mbDivider="0px" sxHeader={{ pb: 0 }}>
                    <Grid item lg={13} xs={10}>
                        <TitleIcon title={t('Dashboard')} />
                    </Grid>
                    <Grid
                        item
                        lg={5}
                        xs={8}
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            gap: 1,
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            color="secondary"
                            onClick={() => setIsEditMode(true)}
                            size="small"
                            startIcon={<EditIcon fontSize="small" />}
                            variant="text"
                            visible={!isEditMode}
                        >
                            {t('Edit')}
                        </Button>
                        <Button
                            color="secondary"
                            disabled={isSavingDashboardConfig}
                            onClick={() => handleAddItem()}
                            size="small"
                            startIcon={<AddIcon fontSize="small" />}
                            variant="text"
                            visible={false}
                        >
                            {t('Add panel')}
                        </Button>
                        <Button
                            color="secondary"
                            disabled={isSavingDashboardConfig}
                            onClick={() =>
                                saveDashboardConfig(
                                    () => setIsEditMode(false),
                                    layouts,
                                )
                            }
                            size="small"
                            startIcon={<SaveIcon fontSize="small" />}
                            variant="text"
                            visible={isEditMode}
                        >
                            {isSavingDashboardConfig
                                ? t('Saving').concat('...')
                                : t('Save')}
                        </Button>
                        <Button
                            color="secondary"
                            disabled={isSavingDashboardConfig}
                            onClick={() => {
                                setLayouts(dashboardConfigData);
                                setIsEditMode(false);
                            }}
                            size="small"
                            variant="text"
                            visible={isEditMode}
                        >
                            {t('Cancel')}
                        </Button>
                    </Grid>
                </HeaderContainer>
            </MaterialContainer>

            <Box
                ref={boxRef}
                sx={{
                    height: '100%',
                    overflow: 'hidden',
                    padding: '0 16px',
                    paddingBottom: '16px',
                    position: 'relative',
                    width: '100%',
                }}
            >
                {renderContent()}
            </Box>
            <PanelConfigModal
                handleCloseModal={() => setIsOpenModal(false)}
                isOpenModal={isOpenModal}
            />
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    agents: selectors.getAgentsData,
    dashboardConfigData: selectors.getDashboardConfigData,
    defaultCurrency: companyGeneralSelectors.getCurrentCompanyCurrency,
    groupedRates: selectors.getGroupedRatesForSelect,
    isFetchingDashboardConfig: selectors.getIsFetchingDashboardConfig,
    isFetchingProjectsSalesStage: selectors.getIsFetchingProjectsSalesStage,
    isSavingDashboardConfig: selectors.getIsSavingDashboardConfig,
    totalKwProjects: selectors.getTotalKwProjects,
    totalMoneyProjects: selectors.getTotalMoneyProjects,
    totalProjectsIssued: selectors.getTotalProjectsIssued,
});

const mapDispatchToProps = (dispatch) => ({
    fetchAgents: () => dispatch(actions.fetchAgents()),
    fetchDashboardConfig: () => dispatch(actions.fetchDashboardConfig()),
    fetchRates: () => dispatch(actions.fetchRates()),
    saveDashboardConfig: (callback, data) =>
        dispatch(actions.saveDashboardConfig(callback, data)),
});

Container.propTypes = {
    agents: PropTypes.array,
    canView: PropTypes.bool,
    dashboardConfigData: PropTypes.object,
    drawerSize: PropTypes.string,
    defaultCurrency: PropTypes.object,
    fetchAgents: PropTypes.func,
    fetchDashboardConfig: PropTypes.func,
    fetchRates: PropTypes.func,
    groupedRates: PropTypes.array,
    isFetchingDashboardConfig: PropTypes.bool,
    isFetchingProjectsSalesStage: PropTypes.bool,
    isSavingDashboardConfig: PropTypes.bool,
    saveDashboardConfig: PropTypes.func,
    totalKwProjects: PropTypes.number,
    totalProjectsIssued: PropTypes.number,
    totalMoneyProjects: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
};
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.DASHBOARD_PERMISSION),
)(Container);
