import React, { useEffect } from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box } from 'sunwise-ui';

import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import costingValidate from '../costingValidate';
import * as selectors from '../selectors';

import KwhFields from './KwhFields';
import WattFields from './WattFields';

const CostingForm = ({
    customerMode,
    currencyIso,
    disabled,
    initialValues,
    isSaving,
    offerCosting,
    proposalId,
    save,
}) => {
    const { control, handleSubmit, reset, setValue, watch } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(costingValidate),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    const handleOnSave = (values) => save(proposalId, values);

    const handleOnChange = handleSubmit(handleOnSave);
    const constingRanges = offerCosting?.costing_ranges;

    return (
        <Box>
            <WattFields
                constingRanges={get(constingRanges, 'cost_ranges_watt', [])}
                control={control}
                currencyIso={currencyIso}
                customerMode={customerMode}
                disabled={disabled}
                handleOnChange={handleOnChange}
                isSaving={isSaving}
                setValue={setValue}
                totalCost={offerCosting?.watt_total_amount}
                watch={watch}
            />
            <KwhFields
                constingRanges={get(constingRanges, 'cost_ranges_kwh', [])}
                control={control}
                currencyIso={currencyIso}
                customerMode={customerMode}
                disabled={disabled}
                handleOnChange={handleOnChange}
                isSaving={isSaving}
                setValue={setValue}
                totalCost={offerCosting?.kwh_total_amount}
                watch={watch}
            />
        </Box>
    );
};

CostingForm.propTypes = {
    currencyIso: PropTypes.string,
    customerMode: PropTypes.bool,
    disabled: PropTypes.bool,
    initialValues: PropTypes.object,
    isSaving: PropTypes.bool,
    offerCosting: PropTypes.object,
    proposalId: PropTypes.string,
    save: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialCostingValues,
    isSaving: selectors.getIsSavingCosting,
});

const mapDispatchToProps = (dispatch) => ({
    save: (proposalId, values) =>
        dispatch(actions.saveCosting(proposalId, values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CostingForm);
