import React, { useState } from 'react';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Mention, MentionsInput } from 'react-mentions';
import { Box, Button } from 'sunwise-ui';

import { truncateString } from 'common/utils/helpers';

import { mentionsMessageStyle, mentionStyle } from '../../trackings/components';
import { MAXIMUM_CHARACTERS_FOR_TRACKING } from '../../trackings/constants';
import { getUsersOptions } from '../../trackings/helpers';

const DescriptionFormatter = ({ description, isCompleted, users }) => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const [maxCharacters, setMaxCharacters] = useState(
        MAXIMUM_CHARACTERS_FOR_TRACKING,
    );
    return (
        <Box display="flex" flexDirection="column">
            <Box display="flex">
                <MentionsInput
                    style={{
                        ...mentionsMessageStyle,
                        input: {
                            ...mentionsMessageStyle.input,
                            color: isDarkMode ? '#fff' : '#000',
                            textDecoration: isCompleted
                                ? 'line-through'
                                : 'none',
                        },
                    }}
                    value={truncateString(description, maxCharacters)}
                >
                    <Mention
                        data={getUsersOptions(users)}
                        style={
                            isDarkMode
                                ? {
                                      backgroundColor:
                                          'rgba(255, 255, 255, 0.09)',
                                  }
                                : mentionStyle
                        }
                    />
                </MentionsInput>
            </Box>
            {description?.length > MAXIMUM_CHARACTERS_FOR_TRACKING && (
                <Box textAlign="center">
                    <Button
                        color="secondary"
                        variant="text"
                        onClick={() =>
                            maxCharacters > MAXIMUM_CHARACTERS_FOR_TRACKING
                                ? setMaxCharacters(
                                      MAXIMUM_CHARACTERS_FOR_TRACKING,
                                  )
                                : setMaxCharacters(description.length)
                        }
                        sx={{ p: 0 }}
                    >
                        {maxCharacters > MAXIMUM_CHARACTERS_FOR_TRACKING ? (
                            <ExpandLess />
                        ) : (
                            <ExpandMore />
                        )}
                    </Button>
                </Box>
            )}
        </Box>
    );
};

DescriptionFormatter.propTypes = {
    description: PropTypes.string,
    isCompleted: PropTypes.bool,
    users: PropTypes.array,
};

export default DescriptionFormatter;
