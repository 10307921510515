import React from 'react';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import BoltIcon from '@mui/icons-material/Bolt';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import SpeedIcon from '@mui/icons-material/Speed';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
    IconExportedGeneration,
    IconGridConsumption,
    IconPanel,
    IconPanelSystem,
    IconReturnInvestment,
    IconSaveEnergy,
    IconSaveMoney,
    IconSelfConsumption,
    IconStoragePrice,
    IconZeroExport,
} from 'common/components/icons';
import KeyIndicator from 'common/components/KeyIndicator';
import { numberFormat } from 'common/utils/helpers';

import { getDailyAverage, getIndicatorName } from '../helpers';

const Indicator = ({
    annualSaving,
    annualSavingPercentage,
    batteriesOfferValues,
    compensationScheme,
    currencyIso,
    currencyLocale,
    energySaving,
    getCurrencyDataByValue,
    getIndicatorDecimalValue,
    grossStoragePrice,
    indicator,
    internalRateOfReturn,
    irradiationValues,
    isLoading,
    localCurrency,
    maxDailyConsumption,
    netPresentValue,
    netStoragePrice,
    nextRateData,
    originalConsumption,
    priceByWatt,
    priceWattLocal,
    proposalConfiguration,
    returnInvestment,
    supplierAnnualSaving,
    systemGeneration,
    systemSize,
    totalsSolarSimulation,
    totalWithoutTaxes,
    totalWithTaxes,
    zeroExport,
}) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const { days_diff, storage_offer } = batteriesOfferValues || {};
    const { total_backup_days, daily_cap_batteries, daily_cap_percentage } =
        storage_offer?.storage_plan || {};
    const title = getIndicatorName(indicator?.name);

    switch (indicator?.name) {
        case 'actual_exported_energy': {
            const totalExportedEnergy = totalsSolarSimulation?.exportedEnergy;
            return (
                <KeyIndicator
                    isLoading={isLoading}
                    svgIcon={<IconExportedGeneration />}
                    title={title}
                    unit={totalExportedEnergy ? 'kWh' : ''}
                    value={getIndicatorDecimalValue(totalExportedEnergy)}
                />
            );
        }
        case 'actual_exported_energy_percentage': {
            const totalExportedEnergy = totalsSolarSimulation?.exportedEnergy;
            return (
                <KeyIndicator
                    isLoading={isLoading}
                    svgIcon={<IconExportedGeneration />}
                    title={title}
                    unit={totalExportedEnergy ? '%' : ''}
                    value={getIndicatorDecimalValue(
                        (100 * totalExportedEnergy) / systemGeneration,
                    )}
                />
            );
        }
        case 'actual_grid_consumption': {
            const totalGridConsumption =
                totalsSolarSimulation?.consumptionFromGrid;
            return (
                <KeyIndicator
                    isLoading={isLoading}
                    svgIcon={
                        <IconGridConsumption
                            color={theme.palette.primary.contrastText}
                        />
                    }
                    title={title}
                    unit={totalGridConsumption ? 'kWh' : ''}
                    value={getIndicatorDecimalValue(totalGridConsumption)}
                />
            );
        }
        case 'actual_grid_consumption_percentage': {
            const totalGridConsumption =
                totalsSolarSimulation?.consumptionFromGrid;
            return (
                <KeyIndicator
                    isLoading={isLoading}
                    svgIcon={
                        <IconGridConsumption
                            color={theme.palette.primary.contrastText}
                        />
                    }
                    title={title}
                    unit={totalGridConsumption ? '%' : ''}
                    value={getIndicatorDecimalValue(
                        (100 * totalGridConsumption) / originalConsumption,
                    )}
                />
            );
        }
        case 'actual_self_consumption': {
            const totalSelfConsumption = totalsSolarSimulation?.selfConsumption;
            return (
                <KeyIndicator
                    isLoading={isLoading}
                    svgIcon={<IconSelfConsumption />}
                    title={title}
                    unit={totalSelfConsumption ? 'kWh' : ''}
                    value={getIndicatorDecimalValue(totalSelfConsumption)}
                />
            );
        }
        case 'actual_self_consumption_percentage': {
            const totalSelfConsumption = totalsSolarSimulation?.selfConsumption;
            return (
                <KeyIndicator
                    isLoading={isLoading}
                    svgIcon={<IconSelfConsumption />}
                    title={title}
                    unit={totalSelfConsumption ? '%' : ''}
                    value={getIndicatorDecimalValue(
                        (100 * totalSelfConsumption) / originalConsumption,
                    )}
                />
            );
        }
        case 'annual_saving':
            return (
                <KeyIndicator
                    isLoading={isLoading}
                    svgIcon={<IconSaveMoney />}
                    title={title}
                    value={numberFormat(annualSaving, {
                        currency: currencyIso,
                        decimals: 2,
                        locale: currencyLocale,
                        style: 'currency',
                    })}
                />
            );

        case 'annual_saving_percentage': {
            const value = annualSavingPercentage?.[indicator?.year || 0] || 0;

            return (
                <KeyIndicator
                    isLoading={isLoading}
                    svgIcon={<IconSaveMoney />}
                    title={title}
                    unit={value ? '%' : ''}
                    value={getIndicatorDecimalValue(value)}
                />
            );
        }
        case 'average_daily_consumption':
            return (
                <KeyIndicator
                    isLoading={isLoading}
                    svgIcon={<SpeedIcon />}
                    title={title}
                    unit={originalConsumption ? 'kWh' : ''}
                    value={getIndicatorDecimalValue(
                        getDailyAverage({
                            days: days_diff,
                            value: originalConsumption,
                        }),
                    )}
                />
            );
        case 'daily_average_generation':
            return (
                <KeyIndicator
                    isLoading={isLoading}
                    svgIcon={<IconPanel />}
                    title={title}
                    unit={systemGeneration ? 'kWh' : ''}
                    value={getIndicatorDecimalValue(
                        getDailyAverage({
                            days: days_diff,
                            value: systemGeneration,
                        }),
                    )}
                />
            );
        case 'daily_storage_percentage':
            return (
                <KeyIndicator
                    isLoading={isLoading}
                    svgIcon={<BoltIcon />}
                    title={title}
                    unit={daily_cap_percentage ? '%' : ''}
                    value={getIndicatorDecimalValue(100 * daily_cap_percentage)}
                />
            );
        case 'energy':
            return (
                <KeyIndicator
                    isLoading={isLoading}
                    svgIcon={<IconSaveEnergy />}
                    title={title}
                    unit={energySaving ? '%' : ''}
                    value={getIndicatorDecimalValue(energySaving)}
                />
            );
        case 'energy_compensation_scheme':
            return (
                <KeyIndicator
                    isLoading={isLoading}
                    svgIcon={<AttachMoneyIcon />}
                    title={title}
                    value={t(compensationScheme?.label)}
                />
            );
        case 'final_rate':
            return (
                <KeyIndicator
                    isLoading={isLoading}
                    svgIcon={<SettingsInputComponentIcon />}
                    title={title}
                    value={nextRateData?.name}
                />
            );
        case 'full_backup_duration':
            return (
                <KeyIndicator
                    isLoading={isLoading}
                    svgIcon={<WatchLaterIcon />}
                    title={title}
                    unit={
                        total_backup_days
                            ? t('Day', {
                                  count: total_backup_days || 2,
                              }).toLowerCase()
                            : ''
                    }
                    value={getIndicatorDecimalValue(total_backup_days)}
                />
            );
        case 'gross_storage_generation':
            return (
                <KeyIndicator
                    isLoading={isLoading}
                    svgIcon={<BatteryChargingFullIcon />}
                    title={title}
                    unit={daily_cap_batteries ? 'kWh' : ''}
                    value={getIndicatorDecimalValue(daily_cap_batteries)}
                />
            );
        case 'initial_rate':
            return (
                <KeyIndicator
                    isLoading={isLoading}
                    svgIcon={<SettingsInputComponentIcon />}
                    title={title}
                    value={proposalConfiguration?.previous_rate_object?.name}
                />
            );
        case 'internal_rate_return':
            return (
                <KeyIndicator
                    isLoading={isLoading}
                    svgIcon={<IconReturnInvestment />}
                    title={title}
                    unit="%"
                    value={numberFormat(internalRateOfReturn, {
                        decimals: 4,
                        locale: currencyLocale,
                    })}
                />
            );
        case 'kWh/kW':
            return (
                <KeyIndicator
                    isLoading={isLoading}
                    svgIcon={<SolarPowerIcon />}
                    title={title}
                    value={getIndicatorDecimalValue(
                        irradiationValues?.kWhkWValue,
                        0,
                    )}
                />
            );
        case 'max_daily_consumption':
            return (
                <KeyIndicator
                    isLoading={isLoading}
                    svgIcon={<SpeedIcon />}
                    title={title}
                    unit={maxDailyConsumption ? 'kWh' : ''}
                    value={getIndicatorDecimalValue(maxDailyConsumption)}
                />
            );
        case 'net_present_value': {
            const label = t('To {{years}} years', { years: 50 }).toLowerCase();
            return (
                <KeyIndicator
                    isLoading={isLoading}
                    svgIcon={<AttachMoneyIcon />}
                    title={`${title} (${label})`}
                    value={numberFormat(netPresentValue, {
                        currency: currencyIso,
                        decimals: 4,
                        locale: currencyLocale,
                        style: 'currency',
                    })}
                />
            );
        }
        case 'original_consumption':
            return (
                <KeyIndicator
                    isLoading={isLoading}
                    svgIcon={<SpeedIcon />}
                    title={title}
                    unit={originalConsumption ? 'kWh' : ''}
                    value={getIndicatorDecimalValue(originalConsumption)}
                />
            );
        case 'price_watt':
            return (
                <KeyIndicator
                    isLoading={isLoading}
                    svgIcon={<AttachMoneyIcon />}
                    title={title}
                    unit="USD/W"
                    value={numberFormat(priceByWatt, {
                        currency: 'USD',
                        decimals: 4,
                        locale: currencyLocale,
                        style: 'currency',
                    })}
                />
            );
        case 'price_watt_local':
            return (
                <KeyIndicator
                    isLoading={isLoading}
                    svgIcon={<AttachMoneyIcon />}
                    title={title}
                    unit={`${localCurrency}/W`}
                    value={numberFormat(priceWattLocal, {
                        currency: currencyIso,
                        decimals: 4,
                        locale: currencyLocale,
                        style: 'currency',
                    })}
                />
            );
        case 'return_of_investment':
            return (
                <KeyIndicator
                    isLoading={isLoading}
                    svgIcon={<IconReturnInvestment />}
                    title={title}
                    unit={
                        returnInvestment
                            ? t('Year', { count: 2 }).toLowerCase()
                            : ''
                    }
                    value={getIndicatorDecimalValue(returnInvestment)}
                />
            );
        case 'self_consumption_utilization_percentage': {
            const totalSelfConsumption = totalsSolarSimulation?.selfConsumption;
            return (
                <KeyIndicator
                    isLoading={isLoading}
                    svgIcon={<IconSelfConsumption />}
                    title={title}
                    unit={totalSelfConsumption ? '%' : ''}
                    value={getIndicatorDecimalValue(
                        (100 * totalSelfConsumption) / systemGeneration,
                    )}
                />
            );
        }
        case 'storage_price_gross_per_kwh': {
            const { indicatorCurrency, indicatorCurrencyValue } =
                getCurrencyDataByValue(grossStoragePrice, indicator?.currency);

            return (
                <KeyIndicator
                    isLoading={isLoading}
                    svgIcon={<IconStoragePrice />}
                    title={title}
                    unit={`${indicatorCurrency}/kWh`}
                    value={numberFormat(indicatorCurrencyValue, {
                        currency: indicatorCurrency,
                        decimals: 4,
                        locale: currencyLocale,
                        style: 'currency',
                    })}
                />
            );
        }
        case 'storage_price_per_kwh': {
            const { indicatorCurrency, indicatorCurrencyValue } =
                getCurrencyDataByValue(netStoragePrice, indicator?.currency);

            return (
                <KeyIndicator
                    isLoading={isLoading}
                    svgIcon={<IconStoragePrice />}
                    title={title}
                    unit={`${indicatorCurrency}/kWh`}
                    value={numberFormat(indicatorCurrencyValue, {
                        currency: indicatorCurrency,
                        decimals: 4,
                        locale: currencyLocale,
                        style: 'currency',
                    })}
                />
            );
        }
        case 'sun_hrs':
            return (
                <KeyIndicator
                    isLoading={isLoading}
                    svgIcon={<SolarPowerIcon />}
                    title={title}
                    value={getIndicatorDecimalValue(
                        irradiationValues?.sunHoursValue,
                    )}
                />
            );
        case 'supplier_annual_saving': {
            const value = supplierAnnualSaving?.[indicator?.year || 0] || 0;
            return (
                <KeyIndicator
                    isLoading={isLoading}
                    svgIcon={<IconSaveMoney />}
                    title={title}
                    value={numberFormat(value, {
                        currency: currencyIso,
                        decimals: 2,
                        locale: currencyLocale,
                        style: 'currency',
                    })}
                />
            );
        }

        case 'system_generation':
            return (
                <KeyIndicator
                    isLoading={isLoading}
                    svgIcon={<IconPanel />}
                    title={title}
                    unit={systemGeneration ? 'kWh' : ''}
                    value={getIndicatorDecimalValue(systemGeneration)}
                />
            );
        case 'system_size':
            return (
                <KeyIndicator
                    isLoading={isLoading}
                    svgIcon={<IconPanelSystem />}
                    title={title}
                    unit={systemSize ? 'kW' : ''}
                    value={getIndicatorDecimalValue(systemSize)}
                />
            );
        case 'total_price':
            return (
                <KeyIndicator
                    isLoading={isLoading}
                    svgIcon={<AttachMoneyIcon />}
                    title={title}
                    value={numberFormat(totalWithoutTaxes, {
                        currency: currencyIso,
                        decimals: 4,
                        locale: currencyLocale,
                        style: 'currency',
                    })}
                />
            );
        case 'total_price_iva':
            return (
                <KeyIndicator
                    isLoading={isLoading}
                    svgIcon={<AttachMoneyIcon />}
                    title={title}
                    value={numberFormat(totalWithTaxes, {
                        currency: currencyIso,
                        decimals: 4,
                        locale: currencyLocale,
                        style: 'currency',
                    })}
                />
            );
        case 'zero_export_utilization_percentage': {
            const totalSelfConsumption = totalsSolarSimulation?.selfConsumption;
            const totalSolarPotential = totalsSolarSimulation?.solarPotential;
            return (
                <KeyIndicator
                    isLoading={isLoading}
                    svgIcon={
                        <IconZeroExport
                            color={theme.palette.primary.contrastText}
                        />
                    }
                    title={title}
                    unit={zeroExport && totalSelfConsumption ? '%' : ''}
                    value={getIndicatorDecimalValue(
                        zeroExport
                            ? (100 * systemGeneration) / totalSolarPotential
                            : 0,
                    )}
                />
            );
        }
        default:
            return null;
    }
};

Indicator.defaultProps = {
    annualSaving: 0,
    energySaving: 0,
    grossStoragePrice: 0,
    netStoragePrice: 0,
    originalConsumption: 0,
    priceByWatt: 0,
    returnInvestment: 0,
    systemGeneration: 0,
    systemSize: 0,
    totalWithTaxes: 0,
    totalWithoutTaxes: 0,
};

Indicator.propTypes = {
    annualSaving: PropTypes.number,
    annualSavingPercentage: PropTypes.object,
    batteriesOfferValues: PropTypes.object,
    compensationScheme: PropTypes.object,
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    energySaving: PropTypes.number,
    getCurrencyDataByValue: PropTypes.func,
    getIndicatorDecimalValue: PropTypes.func,
    grossStoragePrice: PropTypes.number,
    indicator: PropTypes.object,
    internalRateOfReturn: PropTypes.number,
    irradiationValues: PropTypes.object,
    isLoading: PropTypes.bool,
    localCurrency: PropTypes.string,
    maxDailyConsumption: PropTypes.number,
    netPresentValue: PropTypes.number,
    netStoragePrice: PropTypes.number,
    nextRateData: PropTypes.object,
    originalConsumption: PropTypes.number,
    priceByWatt: PropTypes.number,
    priceWattLocal: PropTypes.number,
    proposalConfiguration: PropTypes.object,
    returnInvestment: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    supplierAnnualSaving: PropTypes.object,
    systemGeneration: PropTypes.number,
    systemSize: PropTypes.number,
    totalWithTaxes: PropTypes.number,
    totalWithoutTaxes: PropTypes.number,
    totalsSolarSimulation: PropTypes.object,
    zeroExport: PropTypes.bool,
};

export default Indicator;
