import React, { useEffect } from 'react';

import toNumber from 'lodash/toNumber';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Grid, ToggleButton, ToggleButtonGroup } from 'sunwise-ui';

import {
    ReactHookFormIntlNumberInput,
    ReactHookFormSelect,
    ReactHookFormSwitch,
} from 'common/components/form/bootstrap';
import TitleWithDetail from 'common/components/TitleWithDetail';

import OpexRangesFields from './OpexRangesFields';

const OpexFields = ({
    canModify,
    control,
    currencyIso,
    currencyLocale,
    currencySymbol,
    isLocked,
    setValue,
    watch,
}) => {
    const { t } = useTranslation();
    const [
        kwOpexTotalAmount,
        kwhOpexTotalAmount,
        opexActive,
        opexCostingRanges,
        opexCostingType,
        opexPeriodicity,
    ] = useWatch({
        control,
        name: [
            'kw_opex_total_amount',
            'kwh_opex_total_amount',
            'opex_active',
            'opex_costing_ranges',
            'opex_costing_type',
            'opex_periodicity',
        ],
    });

    useEffect(() => {
        const newOpex =
            parseFloat(kwOpexTotalAmount) + parseFloat(kwhOpexTotalAmount);
        setValue('opex', toNumber(newOpex).toFixed(2));
    }, [kwOpexTotalAmount, kwhOpexTotalAmount]);

    return (
        <>
            <Box sx={{ mb: 2 }}>
                <TitleWithDetail variant="body2">OPEX</TitleWithDetail>
            </Box>

            <Grid container>
                <Grid item xs={18} md={6} lg={4}>
                    <ReactHookFormSwitch
                        control={control}
                        disabled={isLocked}
                        fullWidth
                        label={t('Active')}
                        labelPlacement="start"
                        name="opex_active"
                    />
                </Grid>
                <Grid item xs={18} md={5}>
                    <ReactHookFormSelect
                        control={control}
                        disabled={isLocked || !opexActive}
                        fullWidth
                        label={t('Costing type')}
                        name="opex_costing_type"
                        onChange={({ target }) => {
                            if (target.value === 'by_kw_and_kwh')
                                setValue('opex', 0);
                        }}
                        options={[
                            {
                                label: t('Fixed amount'),
                                value: 'by_fixed_amount',
                            },
                            {
                                label: t('Per kW and kWh'),
                                value: 'by_kw_and_kwh',
                            },
                        ]}
                    />
                </Grid>
            </Grid>

            <Grid container mt={1}>
                <Grid item xs={18} md={6}>
                    <ReactHookFormIntlNumberInput
                        allowNegativeValue={false}
                        append={currencyIso}
                        control={control}
                        disabled={
                            isLocked ||
                            !opexActive ||
                            opexCostingType === 'by_kw_and_kwh'
                        }
                        fullWidth
                        label={t('Total')}
                        min="0"
                        name="opex"
                        prepend={currencySymbol}
                    />
                </Grid>
                <Grid item xs={18} md={6}>
                    <ReactHookFormIntlNumberInput
                        allowNegativeValue={false}
                        append="%"
                        control={control}
                        disabled={isLocked || !opexActive}
                        fullWidth
                        label={t('Annual percentage increase')}
                        min={0}
                        name="opex_percentage_increase"
                        step="any"
                    />
                </Grid>
                <Grid item xs={18} md={6}>
                    <ToggleButtonGroup
                        color="primary"
                        disabled={isLocked || !opexActive}
                        exclusive
                        fullWidth
                        onChange={(e) =>
                            setValue('opex_periodicity', e.target.value)
                        }
                        value={parseInt(opexPeriodicity)}
                        size="small"
                        sx={{ mb: 2 }}
                    >
                        <ToggleButton value={0}>{t('Monthly')}</ToggleButton>
                        <ToggleButton value={1}>{t('Yearly')}</ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>

            <OpexRangesFields
                canModify={canModify}
                control={control}
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                currencySymbol={currencySymbol}
                isLocked={isLocked}
                opexActive={opexActive}
                opexCostingRanges={opexCostingRanges}
                opexCostingType={opexCostingType}
                setValue={setValue}
                watch={watch}
            />
        </>
    );
};

OpexFields.propTypes = {
    canModify: PropTypes.bool,
    control: PropTypes.object,
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    currencySymbol: PropTypes.string,
    isLocked: PropTypes.bool,
    setValue: PropTypes.func,
    watch: PropTypes.func,
};

export default OpexFields;
