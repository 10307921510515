import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';
import { types } from 'sunwise-template-core';

import { parseDate } from 'common/utils/dates';

import ProposalsNumberFormatter from '../templateLibrary/components/ProposalsNumberFormatter';
import { TEMPLATES_URL } from '../templateLibrary/constants';

export const getCustomBaseTemplatesGridColumns = (isForSummaryTemplates) => {
    const columns = [
        {
            field: 'title',
            flex: 1,
            headerName: i18next.t('Title'),
        },
        {
            field: 'created_at',
            flex: 1,
            headerName: i18next.t('Creation date'),
            sortComparator: (v1, v2) =>
                parseDate(v1, 'dd/MM/yyyy HH:mm:ss') -
                parseDate(v2, 'dd/MM/yyyy HH:mm:ss'),
            type: 'date',
        },
    ];

    if (isForSummaryTemplates) {
        const proposalsNumberTitle = `# ${i18next.t('Proposal', { count: 2 })}`;
        const [column1, column2, ...rest] = columns;
        return [
            column1,
            column2,
            {
                field: 'proposals_number',
                flex: 1,
                formatter: (cell) => ProposalsNumberFormatter(cell),
                headerName: proposalsNumberTitle,
            },
            ...rest,
        ];
    }
    return columns;
};

export const handleClonePage =
    (customTemplateId, setClonePages, addNewPages) =>
    (cloneTemplateId, pages) => {
        setClonePages(customTemplateId, cloneTemplateId, pages, addNewPages);
    };

export const handleOnCustomBaseTableChange =
    ({ orderBy, setOrderBy, setSortBy, sortBy }) =>
    (type, { sortField, sortOrder }) => {
        if (
            type === 'sort' &&
            (sortField !== orderBy || sortOrder !== sortBy)
        ) {
            setOrderBy(sortField);
            setSortBy(sortOrder);
        }
    };

export const getTemplateIdUrl = (templateType) => {
    switch (templateType) {
        case types.ONE_PROPOSAL_TYPE:
            return TEMPLATES_URL.PROPOSAL;
        case types.MULTIPROPOSAL_TYPE:
            return TEMPLATES_URL.SUMMARY;
        case types.SMART_DOCUMENTS_TYPE:
            return TEMPLATES_URL.SMART_DOCUMENT;
        case types.REPORT_TYPE:
            return TEMPLATES_URL.REPORT;
        default:
            return '';
    }
};

export const getClonePagesTemplatesByCategoryAndLanguage = ({
    category,
    clonePages,
    language,
    proposalsNumber,
    templateBaseType,
}) => {
    if (isEmpty(clonePages)) return [];
    return clonePages.filter(
        (item) =>
            item.category_id === category &&
            item.supported_types.includes(templateBaseType) &&
            item.proposals_number === proposalsNumber &&
            item.total_pages >= 1 &&
            item?.languages.map((lang) => lang).includes(language),
    );
};
