import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Grid } from 'sunwise-ui';

import {
    BulkEditionMenu,
    InputSearch,
    StatusFilterSelect,
} from 'common/components';

import ManageCsvDropdown from '../ManageCsvDropdown';

const BulkOperationsCard = ({
    canDelete,
    canModify,
    downloadFile,
    filterData,
    handleClickBulkItems,
    handleClickUpdateMargin,
    handleOnChangeSearch,
    handleOnSelectStatus,
    prepareUploadFile,
    selectionModel,
    setSelectionModel,
}) => {
    const { t } = useTranslation();

    const isArchivedList = filterData?.status === 'archived';
    const callback = () => setSelectionModel([]);

    return (
        <Card>
            <Card.Body>
                <Grid container alignItems="end" justifyContent="flex-end">
                    <Grid
                        item
                        xs={16}
                        md={13}
                        lg={isEmpty(selectionModel) ? 14 : 10}
                        sx={{ order: 1 }}
                    >
                        <InputSearch
                            placeholder={t('Filter by name, brand or model')}
                            onChange={handleOnChangeSearch}
                            value={filterData?.searchText}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={18}
                        md={4}
                        lg={3}
                        sx={{ order: { xs: 3, md: 2 } }}
                    >
                        <StatusFilterSelect
                            filterBy={filterData?.status}
                            handleSelected={handleOnSelectStatus}
                            variant="standard"
                        />
                    </Grid>
                    {!isEmpty(selectionModel) && (
                        <Grid
                            item
                            xs={18}
                            md={6}
                            lg={4}
                            sx={{ order: { xs: 5, lg: 4 } }}
                        >
                            <BulkEditionMenu
                                callback={callback}
                                items={[
                                    {
                                        label: t('Change margin'),
                                        handleClick: () =>
                                            handleClickUpdateMargin(
                                                selectionModel,
                                                callback,
                                            ),
                                        visible: canModify,
                                    },
                                    {
                                        label: t('Archive'),
                                        handleClick: () =>
                                            handleClickBulkItems(
                                                selectionModel,
                                                'archived',
                                                filterData,
                                                callback,
                                            ),
                                        visible: canDelete && !isArchivedList,
                                    },
                                    {
                                        label: t('Unarchive'),
                                        handleClick: () =>
                                            handleClickBulkItems(
                                                selectionModel,
                                                'active',
                                                filterData,
                                                callback,
                                            ),
                                        visible: canDelete && isArchivedList,
                                    },
                                    {
                                        label: t('Delete'),
                                        handleClick: () =>
                                            handleClickBulkItems(
                                                selectionModel,
                                                'deleted',
                                                filterData,
                                                callback,
                                            ),
                                        visible: canDelete && isArchivedList,
                                    },
                                ]}
                                selectionModel={selectionModel}
                            />
                        </Grid>
                    )}
                    <Grid
                        item
                        xs={2}
                        md={1}
                        sx={{ order: { xs: 2, lg: 5 }, textAlign: 'right' }}
                    >
                        <ManageCsvDropdown
                            canModify={canModify}
                            handleDownload={downloadFile}
                            handleUpload={prepareUploadFile}
                        />
                    </Grid>
                </Grid>
            </Card.Body>
        </Card>
    );
};

BulkOperationsCard.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    downloadFile: PropTypes.func,
    filterData: PropTypes.object,
    handleClickBulkItems: PropTypes.func,
    handleClickUpdateMargin: PropTypes.func,
    handleOnChangeSearch: PropTypes.func,
    handleOnSelectStatus: PropTypes.func,
    prepareUploadFile: PropTypes.func,
    selectionModel: PropTypes.array,
    setSelectionModel: PropTypes.func,
};

export default BulkOperationsCard;
