import React from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, BottomActions, Button } from 'sunwise-ui';

import ReactHookFormIntlNumberInput from 'common/components/form/bootstrap/ReactHookFormIntlNumberInput';
import ShowErrors from 'common/components/ShowErrors';
// import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import * as selectors from '../selectors';
// import validateMargin from '../validateMargin';

const UpdateProductsForm = ({
    bulkAction,
    canModify = true,
    errors,
    initialValues,
    isSaving,
    proposalId,
    save,
}) => {
    const { t } = useTranslation();
    const { control, handleSubmit } = useForm({
        defaultValues: initialValues,
        // resolver: yupResolver(validateMargin),
    });

    const renderField = () => {
        switch (bulkAction) {
            case 'discount':
                return (
                    <ReactHookFormIntlNumberInput
                        append="%"
                        control={control}
                        disabled={!canModify}
                        fullWidth
                        label={t('Discount')}
                        max={99}
                        min={0}
                        name="discount"
                    />
                );
            case 'cost':
                return (
                    <ReactHookFormIntlNumberInput
                        append="%"
                        control={control}
                        disabled={!canModify}
                        fullWidth
                        label={t('Cost')}
                        max={99}
                        min={0}
                        name="cost"
                    />
                );
            default:
                return (
                    <ReactHookFormIntlNumberInput
                        append="%"
                        control={control}
                        disabled={!canModify}
                        fullWidth
                        label={t('Margin')}
                        max={99}
                        min={0}
                        name="margin"
                    />
                );
        }
    };

    const handleClickSave = (values) => save(proposalId, values);

    return (
        <form>
            {renderField()}

            <ShowErrors errors={errors} />

            <BottomActions>
                <Box sx={{ pt: 2 }}>
                    <Button
                        disabled={isSaving}
                        onClick={handleSubmit(handleClickSave)}
                        visible={canModify}
                    >
                        {t('Save').toUpperCase()}
                    </Button>
                </Box>
            </BottomActions>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    bulkAction: selectors.getBulkAction,
    errors: selectors.getBulkUpdateProductsErrors,
    initialValues: selectors.getInitialProductsUpdateValues,
    isSaving: selectors.getIsUpdatingBulkProducts,
});

const mapDispatchToProps = (dispatch) => ({
    save: (proposalId, values) =>
        dispatch(actions.bulkUpdateProducts(proposalId, values)),
});

UpdateProductsForm.propTypes = {
    bulkAction: PropTypes.string,
    canModify: PropTypes.bool,
    errors: PropTypes.array,
    initialValues: PropTypes.object,
    isSaving: PropTypes.bool,
    proposalId: PropTypes.string,
    save: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProductsForm);
