import { saveDashboardConfig } from 'common/api/v1/configSettings';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_DASHBOARD_CONFIG,
    SAVE_DASHBOARD_CONFIG_FAILURE,
    SAVE_DASHBOARD_CONFIG_SUCCESS,
} from '../actionTypes';
import { dashboardActions } from '../reducer';

import fetchDashboardConfig from './fetchDashboardConfig';

export default (callback, data) => (dispatch) => {
    dispatch(dashboardActions[SAVE_DASHBOARD_CONFIG]());

    saveDashboardConfig({ config_settings: { dashboard: data } })
        .then(() => {
            dispatch(dashboardActions[SAVE_DASHBOARD_CONFIG_SUCCESS]());
            dispatch(fetchDashboardConfig());
            if (callback) callback();
            showToast();
        })
        .catch((error) => {
            dispatch(
                dashboardActions[SAVE_DASHBOARD_CONFIG_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            if (callback) callback();
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
