import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { ToggleButtonGroup, ToggleButton } from 'sunwise-ui';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
    margin-left: 8px;
    & .MuiToggleButton-primary {
        color: #545759;
        padding: 6px 12px;
        &.Mui-selected {
            background-color: #dcdbdc;
        }
    }
`;

const SubtotalTypeGroup = ({
    currencyLocale = 'USD',
    currencySymbol = '$',
    disabled,
    handleOnChange,
    selected,
}) => {
    return (
        <StyledToggleButtonGroup
            color="primary"
            disabled={disabled}
            exclusive
            onChange={(e) => handleOnChange(parseInt(e.target.value))}
            value={selected}
            size="small"
        >
            <ToggleButton value={0}>{currencySymbol}</ToggleButton>
            <ToggleButton value={1}>{currencyLocale}/W</ToggleButton>
        </StyledToggleButtonGroup>
    );
};

SubtotalTypeGroup.propTypes = {
    currencyLocale: PropTypes.string,
    currencySymbol: PropTypes.string,
    disabled: PropTypes.bool,
    handleOnChange: PropTypes.func,
    selected: PropTypes.number,
};

export default SubtotalTypeGroup;
