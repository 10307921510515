import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, Typography } from 'sunwise-ui';

import { MONTHLY_FREQUENCY } from 'common/constants';
import { getTranslatedAddonLabel } from 'common/utils/helpers';

const PricingDetails = ({
    amount,
    isEnterprise,
    onClick,
    pricing,
    selectedTheme,
}) => {
    const { t } = useTranslation();
    const priceFrom = parseFloat(get(pricing, 'price_from', 0));
    const currency = get(pricing, 'currency_code.value', 'USD') || 'USD';
    const planType = get(pricing, 'plan_type.key', 0);
    const variant = planType === 0 ? 'primary' : 'secondary';

    return (
        <Card>
            <Card.Header
                sx={{
                    display: 'flex',
                    backgroundColor: `${variant}.main`,
                    color: variant === 'secondary' ? '#fff' : 'inherit',
                }}
            >
                <Typography
                    variant="h4"
                    color={
                        variant === 'secondary' &&
                        selectedTheme === 'dark' &&
                        '#000'
                    }
                    sx={{ fontSize: { xs: '1.25rem', lg: '1.5rem' } }}
                >
                    {pricing.plan_name}
                </Typography>
            </Card.Header>
            <Card.Body>
                <Box sx={{ height: 160 }}>
                    {isEnterprise ? (
                        <Typography
                            variant="h4"
                            sx={{ fontSize: { xs: '1.25rem', lg: '1.4rem' } }}
                        >
                            {amount}
                        </Typography>
                    ) : (
                        <>
                            <Typography variant="body1">{t('From')}</Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontSize: { xs: '1.25rem', lg: '1.4rem' },
                                }}
                            >
                                $
                                {`${priceFrom.toFixed(2)} ${currency}/${
                                    pricing.frequency === MONTHLY_FREQUENCY
                                        ? t('Month')
                                        : t('Year')
                                }`}
                            </Typography>
                        </>
                    )}

                    <Typography variant="body2">
                        {pricing.available_users === 0
                            ? t('Unlimited users')
                            : `${t('Up to')} ${pricing.available_users} ${t(
                                  'User',
                                  { count: 2 },
                              ).toLocaleLowerCase()}`}
                    </Typography>
                    <Typography variant="body2">
                        {`${t('Includes')} ${pricing.available_projects} ${t(
                            'Project',
                            { count: 2 },
                        ).toLocaleLowerCase()}/${
                            pricing.frequency === MONTHLY_FREQUENCY
                                ? t('Month')
                                : t('Year')
                        }`}
                    </Typography>
                    {pricing.addons.map((addon) => (
                        <Typography variant="body2" key={addon}>
                            {getTranslatedAddonLabel(addon)}
                        </Typography>
                    ))}
                </Box>
                <Box sx={{ minHeight: 40 }} mt={2}>
                    <Button
                        fullWidth
                        onClick={() => onClick(pricing.chargebee_price_id)}
                    >
                        {t('Select')}
                    </Button>
                </Box>
            </Card.Body>
        </Card>
    );
};

PricingDetails.propTypes = {
    amount: PropTypes.number,
    isEnterprise: PropTypes.bool,
    onClick: PropTypes.func,
    pricing: PropTypes.object,
    selectedTheme: PropTypes.string,
};

export default PricingDetails;
