import { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

import { DEBOUNCE_TIME_500 } from 'common/constants';
import { useDebounce } from 'common/hooks';

const Debouncer = ({ control, setFormValues }) => {
    const formValues = useWatch({ control });
    const debouncedFormValues = useDebounce(formValues, DEBOUNCE_TIME_500);

    useEffect(() => setFormValues(debouncedFormValues), [debouncedFormValues]);

    return null;
};

Debouncer.propTypes = {
    control: PropTypes.object,
    setFormValues: PropTypes.func,
};

export default Debouncer;
