import { get, isEmpty } from 'lodash';

import { getDashboardConfig } from 'common/api/v1/configSettings';

import {
    FETCH_DASHBOARD_CONFIG,
    FETCH_DASHBOARD_CONFIG_FAILURE,
    FETCH_DASHBOARD_CONFIG_SUCCESS,
} from '../actionTypes';
import layoutsJson from '../layouts.json';
import { dashboardActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(dashboardActions[FETCH_DASHBOARD_CONFIG]());

    getDashboardConfig()
        .then((response) => {
            let data = layoutsJson;
            const configSettings = get(response, 'data.config_settings', {});
            const dashboardConfig = get(configSettings, 'dashboard', {});
            if (!isEmpty(dashboardConfig)) data = dashboardConfig;
            dispatch(dashboardActions[FETCH_DASHBOARD_CONFIG_SUCCESS](data));
        })
        .catch((error) =>
            dispatch(
                dashboardActions[FETCH_DASHBOARD_CONFIG_FAILURE](
                    error?.response?.data?.errors,
                ),
            ),
        );
};
