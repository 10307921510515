import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';
import { numberFormat } from 'common/utils/helpers';

import CardCommercialOffers from './CardCommercialOffers';
import CardContactsTrackingsData from './CardContactsTrackingsData';
import CardKeyValue from './CardKeyValue';
import CardProjectSalesStages from './CardProjectSalesStages';
import CardTotalProposalIssued from './CardTotalProposalIssued';

const PanelContent = ({
    agents,
    currencyIso,
    currencyLocale,
    currencySymbol,
    defaultCurrency,
    groupedRates,
    handleDeleteItem,
    isFetchingProjectsSalesStage,
    panelKey,
    totalKwProjects,
    totalMoneyProjects,
    totalProjectsIssued,
}) => {
    const { t } = useTranslation();

    switch (panelKey) {
        case 'total-proposals-issued':
            return (
                <CardKeyValue
                    className="grid-item"
                    id="card-total-proposals-issued"
                    isLoading={isFetchingProjectsSalesStage}
                    menuOptions={
                        <DropdownCardMenu
                            items={[
                                {
                                    label: t('Delete'),
                                    handleClick: () =>
                                        handleDeleteItem(panelKey),
                                },
                            ]}
                            sxIconButton={{ ml: 'auto', zIndex: 3 }}
                            visible={false}
                        />
                    }
                    title={t('Total proposals issued')}
                    value={numberFormat(totalProjectsIssued, {
                        decimals: 0,
                        locale: currencyLocale,
                        style: 'decimal',
                    })}
                />
            );
        case 'total-value-of-the-proposals-issued':
            return (
                <CardKeyValue
                    id="card-total-value-of-the-proposals-issued"
                    isLoading={isFetchingProjectsSalesStage}
                    menuOptions={
                        <DropdownCardMenu
                            items={[
                                {
                                    label: t('Delete'),
                                    handleClick: () =>
                                        handleDeleteItem(panelKey),
                                },
                            ]}
                            sxIconButton={{ ml: 'auto', zIndex: 3 }}
                            visible={false}
                        />
                    }
                    title={t('Total value of the proposals issued')}
                    value={numberFormat(totalMoneyProjects, {
                        currency: currencyIso,
                        locale: currencyLocale,
                        style: 'currency',
                        symbol: currencySymbol,
                    })}
                />
            );
        case 'total-power-of-the-proposals-issued':
            return (
                <CardKeyValue
                    id="card-total-power-of-the-proposals-issued"
                    isLoading={isFetchingProjectsSalesStage}
                    menuOptions={
                        <DropdownCardMenu
                            items={[
                                {
                                    label: t('Delete'),
                                    handleClick: () =>
                                        handleDeleteItem(panelKey),
                                },
                            ]}
                            sxIconButton={{ ml: 'auto', zIndex: 3 }}
                            visible={false}
                        />
                    }
                    title={t('Total power of the proposals issued')}
                    value={numberFormat(totalKwProjects, {
                        decimals: 0,
                        locale: currencyLocale,
                        style: 'decimal',
                        unit: 'kWp',
                    })}
                />
            );
        case 'proposals-issued':
            return (
                <CardTotalProposalIssued
                    agents={agents}
                    currencyIso={currencyIso}
                    currencyLocale={currencyLocale}
                    currencySymbol={currencySymbol}
                    defaultCurrency={defaultCurrency}
                    isFetchingProjectsSalesStage={isFetchingProjectsSalesStage}
                    menuOptions={
                        <DropdownCardMenu
                            items={[
                                {
                                    label: t('Delete'),
                                    handleClick: () =>
                                        handleDeleteItem(panelKey),
                                },
                            ]}
                            sxIconButton={{
                                mb: 0,
                                mt: 2,
                                zIndex: 3,
                            }}
                            visible={false}
                        />
                    }
                />
            );
        case 'follow-ups':
            return (
                <CardContactsTrackingsData
                    agents={agents}
                    menuOptions={
                        <DropdownCardMenu
                            items={[
                                {
                                    label: t('Delete'),
                                    handleClick: () =>
                                        handleDeleteItem(panelKey),
                                },
                            ]}
                            sxIconButton={{ ml: 'auto', zIndex: 3 }}
                            visible={false}
                        />
                    }
                    visible={true}
                />
            );
        case 'last-offers':
            return (
                <CardCommercialOffers
                    agents={agents}
                    groupedRates={groupedRates}
                    menuOptions={
                        <DropdownCardMenu
                            items={[
                                {
                                    label: t('Delete'),
                                    handleClick: () =>
                                        handleDeleteItem(panelKey),
                                },
                            ]}
                            sxIconButton={{
                                ml: 'auto',
                                mt: 2,
                                zIndex: 3,
                            }}
                            visible={false}
                        />
                    }
                    visible={true}
                />
            );
        case 'project-status':
            return (
                <CardProjectSalesStages
                    currencyIso={currencyIso}
                    currencyLocale={currencyLocale}
                    menuOptions={
                        <DropdownCardMenu
                            items={[
                                {
                                    label: t('Delete'),
                                    handleClick: () =>
                                        handleDeleteItem(panelKey),
                                },
                            ]}
                            sxIconButton={{ ml: 'auto', zIndex: 3 }}
                            visible={false}
                        />
                    }
                />
            );
        default:
            return null;
    }
};

PanelContent.propTypes = {
    agents: PropTypes.array,
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    currencySymbol: PropTypes.string,
    defaultCurrency: PropTypes.object,
    groupedRates: PropTypes.array,
    handleDeleteItem: PropTypes.func,
    isFetchingProjectsSalesStage: PropTypes.bool,
    panelKey: PropTypes.string,
    totalKwProjects: PropTypes.number,
    totalMoneyProjects: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    totalProjectsIssued: PropTypes.number,
};

export default PanelContent;
