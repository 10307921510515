import React from 'react';

import { useTheme } from '@mui/material/styles';
import { isEmpty, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Typography } from 'sunwise-ui';

import {
    getCurrencyIso,
    getCurrencyLocale,
    getCurrencySymbol,
    numberFormat,
} from 'common/utils/helpers';

const DollarPriceItem = ({ handleDrawerToggle, oppositeCurrency }) => {
    if (isEmpty(oppositeCurrency) || isNull(oppositeCurrency)) return null;
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const currencyIso = getCurrencyIso(oppositeCurrency);
    const currencyLocale = getCurrencyLocale(oppositeCurrency);
    const currencySymbol = getCurrencySymbol(oppositeCurrency);
    const color = isDarkMode ? { xs: '#fff' } : { xs: '#fff', md: 'initial' };
    return (
        <Button
            color="secondary"
            component={RouterLink}
            size="small"
            to={`/currencies/${currencyIso}`}
            variant="text"
            onClick={() => handleDrawerToggle && handleDrawerToggle()}
        >
            <Typography
                sx={{
                    color,
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                }}
                variant="caption"
            >
                $1 USD ={' '}
                {numberFormat(oppositeCurrency.dollar_price, {
                    currency: currencyIso,
                    locale: currencyLocale,
                    style: 'currency',
                    symbol: currencySymbol,
                })}
            </Typography>
        </Button>
    );
};

DollarPriceItem.propTypes = {
    handleDrawerToggle: PropTypes.func,
    oppositeCurrency: PropTypes.object,
};

export default DollarPriceItem;
