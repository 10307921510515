import React, { useState } from 'react';

import PaletteIcon from '@mui/icons-material/Palette';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { IconButton, Tooltip } from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import ModalForm from './conmponents/ModalForm';

const TemplateColorPalette = ({ getPermissionsByCode }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const handleOnClick = () => setIsOpen(true);

    const proposalTamplatesPermission = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_TEMPLATES_PERMISSION,
    );

    if (!proposalTamplatesPermission.canModify) return null;

    return (
        <>
            <Tooltip title={t('Template color palette')}>
                <IconButton onClick={handleOnClick} variant="outlined">
                    <PaletteIcon />
                </IconButton>
            </Tooltip>

            <ModalForm isOpen={isOpen} setIsOpen={setIsOpen} />
        </>
    );
};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = () => ({});

TemplateColorPalette.propTypes = {
    getPermissionsByCode: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions([PERMISSION_LIST.PROPOSAL_TEMPLATES_PERMISSION]),
)(TemplateColorPalette);
