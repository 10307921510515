import { SET_PRODUCTS_TOTALS } from '../actionTypes';
import {
    getProfilAmount,
    getProfitPercentage,
    getSubtotal2,
    getTotal,
} from '../helpers';
import { proposalGeneratorQuoteActions } from '../reducer';
import * as selectors from '../selectors';

export default (offerDiscount) => (dispatch, getState) => {
    const state = getState();
    const { discount_amount = 0 } = offerDiscount;
    const totalTaxes = selectors.getTotalCostTaxes(state);
    const totalCost = selectors.getTotalCostProducts(state);
    const subtotal = selectors.getProductsSubtotal(state);
    const subtotal2 = getSubtotal2(discount_amount, subtotal);
    const profit = getProfilAmount(subtotal2, totalCost);
    const profitPercentage = getProfitPercentage(subtotal2, profit);
    const total = getTotal(subtotal2, totalTaxes);

    const newValues = {
        profit,
        profitPercentage,
        subtotal,
        subtotalWithDiscount: subtotal2,
        total,
        totalCost,
        totalTaxes,
    };
    dispatch(proposalGeneratorQuoteActions[SET_PRODUCTS_TOTALS](newValues));
};
