import React, { useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import {
    AccordionDetails,
    Box,
    Checkbox,
    Chip,
    Divider,
    IconButton,
    Skeleton,
    Typography,
} from 'sunwise-ui';

import StyledAccordion from './StyledAccordion';
import StyledAccordionSummary from './StyledAccordionSummary';

const LoadingText = ({ children, isLoading }) => {
    if (isLoading) {
        return (
            <Skeleton
                variant="text"
                sx={{
                    fontSize: '1rem',
                    width: 200,
                }}
            />
        );
    }
    return <>{children}</>;
};

LoadingText.propTypes = {
    children: PropTypes.object,
    isLoading: PropTypes.bool,
};

const AccordionProduct = ({
    caption,
    cardId,
    checkboxControl,
    children,
    classNameCard,
    classNameExpandIcon,
    defaultBehavior,
    defaultExpanded,
    disabledCheckbox,
    isChecked,
    isHidden,
    isLoading,
    isProductCosting,
    setIsChecked = () => {},
    svgIcon,
    title,
    total,
    totalItems,
}) => {
    const [isExpanded, setIsExpanded] = useState(defaultExpanded);
    const [hovered, setHovered] = useState(false);

    const handleChange = (event, isExpanded) => {
        if (defaultBehavior) setIsExpanded(isExpanded);
        else event.stopPropagation();
    };

    const handleExpandClick = (event) => {
        if (defaultBehavior) return;
        event.stopPropagation();
        setIsExpanded((prev) => !prev);
    };

    const handleChangeCheckbox = (event) => {
        setIsChecked(event.target.checked);
    };

    const renderCheckbox = () => {
        if (checkboxControl)
            return (
                <Checkbox
                    checked={isChecked}
                    color="primary"
                    disabled={disabledCheckbox}
                    inputProps={{ 'aria-label': 'controlled' }}
                    onChange={handleChangeCheckbox}
                    sx={{ padding: 0 }}
                />
            );
        return null;
    };

    const renderIcon = () => {
        if (hovered || isChecked) return renderCheckbox();
        return svgIcon;
    };

    if (isHidden) return null;

    return (
        <Box
            alignItems="start"
            className={classNameCard}
            display="flex"
            id={cardId}
            md={2}
            sx={{ width: '100%' }}
        >
            <StyledAccordion
                defaultExpanded={defaultExpanded}
                expanded={isExpanded}
                onChange={handleChange}
            >
                <StyledAccordionSummary
                    expandIcon={
                        defaultBehavior ? (
                            <ExpandMoreIcon className={classNameExpandIcon} />
                        ) : (
                            <IconButton onClick={handleExpandClick}>
                                <ExpandMoreIcon />
                            </IconButton>
                        )
                    }
                    aria-controls={`${cardId}-content`}
                    id={`${cardId}-header`}
                    height="50px"
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            gap={2}
                            sx={{ width: '100%' }}
                        >
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                onMouseEnter={() => setHovered(true)}
                                onMouseLeave={() => setHovered(false)}
                                sx={{
                                    height: '24px',
                                    width: '25px',
                                }}
                            >
                                {renderIcon()}
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                onClick={handleExpandClick}
                                sx={{ width: '100%' }}
                            >
                                <Typography fontWeight="bold" variant="body2">
                                    {title && <span>{title}</span>}
                                </Typography>
                                {!isExpanded && isProductCosting && (
                                    <LoadingText isLoading={isLoading}>
                                        <Typography
                                            sx={{
                                                display: {
                                                    xs: 'block',
                                                    lg: 'none',
                                                },
                                            }}
                                            variant="body2"
                                        >
                                            {total}
                                        </Typography>
                                    </LoadingText>
                                )}
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2,
                                ml: 'auto',
                                mr: 1,
                            }}
                        >
                            {!isExpanded && isProductCosting && (
                                <LoadingText isLoading={isLoading}>
                                    <>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                display: {
                                                    xs: 'none',
                                                    lg: 'block',
                                                },
                                                pl: '5px',
                                                borderLeft: '1px solid #b6bbc1',
                                                width: '100px',
                                            }}
                                        >
                                            {caption}
                                        </Typography>
                                        <Typography
                                            fontWeight="bold"
                                            variant="body2"
                                            sx={{
                                                display: {
                                                    xs: 'none',
                                                    lg: 'block',
                                                },
                                            }}
                                        >
                                            {total}
                                        </Typography>
                                    </>
                                </LoadingText>
                            )}
                            {!isProductCosting && totalItems && (
                                <Chip
                                    label={totalItems}
                                    size="small"
                                    sx={{
                                        fontWeight: 700,
                                        minWidth: '50px',
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                </StyledAccordionSummary>
                <Divider />
                <AccordionDetails sx={{ p: 0, py: 2, width: '100%' }}>
                    {children}
                </AccordionDetails>
            </StyledAccordion>
        </Box>
    );
};

AccordionProduct.defaultProps = {
    checkboxControl: false,
    classNameCard: '',
    classNameExpandIcon: '',
    defaultExpanded: false,
    disabledCheckbox: false,
    isChecked: false,
    isHidden: false,
    numericalSign: '',
    totalItems: '0',
};

AccordionProduct.propTypes = {
    caption: PropTypes.string,
    cardId: PropTypes.string,
    checkboxControl: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
        PropTypes.string,
    ]),
    classNameCard: PropTypes.string,
    classNameExpandIcon: PropTypes.string,
    defaultBehavior: PropTypes.bool,
    defaultExpanded: PropTypes.bool,
    disabledCheckbox: PropTypes.bool,
    isChecked: PropTypes.bool,
    isHidden: PropTypes.bool,
    isLoading: PropTypes.bool,
    isProductCosting: PropTypes.bool,
    setIsChecked: PropTypes.func,
    svgIcon: PropTypes.object,
    title: PropTypes.string,
    total: PropTypes.string,
    totalItems: PropTypes.string,
};

export default AccordionProduct;
