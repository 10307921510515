import get from 'lodash/get';

import { INITIAL_VALUES } from '../actionTypes';
import { proposalGeneratorConfigModalActions } from '../reducer';

export default (values) => (dispatch) => {
    const considerations = get(values, 'considerations', 'null');

    const newValues = {
        considerations,
        currency: get(values, 'currency.id', null),
        currency_item: values.currency,
        deprecated: values.deprecated,
        discount_rate: values.discount_rate,
        generation_delay: values?.generation_delay?.toString(),
        id: values.id,
        isr_percentage: values.isr_percentage,
        isr_simulation: values.isr_simulation,
        isr_simulation_type: values.isr_simulation_type,
        isr: values.isr,
        kw_opex_amount: values.kw_opex_amount || 0,
        kw_opex_total_amount: values.kw_opex_total_amount || 0,
        kw_opex_system_size: values.kw_opex_system_size || 0,
        kwh_opex_amount: values.kwh_opex_amount || 0,
        kwh_opex_storage_size: values.kwh_opex_storage_size || 0,
        kwh_opex_total_amount: values.kwh_opex_total_amount || 0,
        opex_active: values.opex_active,
        opex_costing_ranges: values.opex_costing_ranges || {},
        opex_costing_type: values.opex_costing_type || 'by_fixed_amount',
        opex_percentage_increase: values.opex_percentage,
        opex_periodicity: values.opex_periodicity,
        opex: values.opex,
        ppa_active: values.ppa_active,
        ppa_percentage_increase: values.ppa_percentage_increase,
        ppa_price: values.ppa_price,
        proposal_plan: values.proposal_plan,
        simulation_years: values.bag_years,
        type_change_offer: values.type_change_offer,
        type_change: values.type_change,
    };

    dispatch(proposalGeneratorConfigModalActions[INITIAL_VALUES](newValues));
};
