import React from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { InputAdornment, TextField } from 'sunwise-ui';

import { hexToRgba } from 'common/utils/helpersChart';

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& fieldset': {
        borderRadius: '20px',
        border: `1px solid ${hexToRgba(theme.palette.primary.main, 0.5)}!important`,
    },
}));

const SearchBar = ({
    clearSearch,
    handleClick,
    open,
    resultBoxRef,
    searchFieldRef,
    searchQuery,
    setSearchQuery,
}) => {
    const { t } = useTranslation();

    let sx = {
        '& input': {
            height: '19px',
            transition: 'width 250ms',
            fontSize: '14px',
        },
    };

    if (open)
        sx = {
            ...sx,
            '& input': {
                ...sx['& input'],
                width: { lg: '350px', md: '300px', sm: '250px' },
            },
        };

    const onInput = (e) => setSearchQuery(e.target.value);

    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            clearSearch();
            searchFieldRef.current.blur();
        }
        if (e.key === 'ArrowDown') {
            resultBoxRef?.current?.selectFirstItem();
        }
    };

    return (
        <StyledTextField
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon fontSize="inherit" />
                    </InputAdornment>
                ),
            }}
            autoComplete="off"
            focused={true}
            inputRef={searchFieldRef}
            onClick={handleClick}
            onFocus={handleClick}
            onInput={onInput}
            onKeyDown={handleKeyDown}
            placeholder={t('Search')}
            size="small"
            sx={sx}
            value={searchQuery}
            variant="outlined"
        />
    );
};

SearchBar.propTypes = {
    clearSearch: PropTypes.func,
    handleClick: PropTypes.func,
    open: PropTypes.bool,
    resultBoxRef: PropTypes.object,
    searchFieldRef: PropTypes.object,
    searchQuery: PropTypes.string,
    setSearchQuery: PropTypes.func,
};

export default SearchBar;
