import API from '../..';
const ENTITY = 'proposals-summary';

export const archiveItem = (id) => API.put(`/api/v1/archive/${ENTITY}/${id}`);
export const createItem = (data) => API.post(`/api/v1/${ENTITY}/`, data);
export const deleteItem = (id) => API.delete(`/api/v1/${ENTITY}/${id}`);
export const duplicateItem = (id, data) =>
    API.put(`/api/v1/duplicate/${ENTITY}/${id}`, data);
export const getItems = (projectId, archived) =>
    API.get(`/api/v1/projects-${ENTITY}/${projectId}`, {
        params: { archived },
    });
export const getSummaryCSV = (id) =>
    API.get(`/api/v1/proposal-summary-end-csv/${id}`);
export const updateItem = (id, data) =>
    API.put(`/api/v1/${ENTITY}/${id}`, data);
