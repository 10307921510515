import React, { useEffect, useRef, useState } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import { debounce, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { Card, DataGrid } from 'sunwise-ui';

import CustomPagination from 'common/components/CustomPagination';
import { DEBOUNCE_TIME_1500 } from 'common/constants';

import Columns from './Columns';

const ListItems = ({
    canDelete,
    canModify,
    filterData,
    filterItems,
    handleClickDelete,
    handleClickUpdate,
    isFetching,
    items,
    paginationData,
    setFilterData,
}) => {
    const [rowCount, setRowCount] = useState(paginationData?.rowCount || 0);
    const [currentFilterData, setCurrentFilterData] = useState(filterData);

    useEffect(() => {
        if (paginationData)
            setRowCount((prevRowCount) =>
                paginationData?.total_rows !== undefined
                    ? paginationData?.total_rows
                    : prevRowCount,
            );
    }, [paginationData?.total_rows, setRowCount]);

    useEffect(() => {
        if (!isEqual(currentFilterData, filterData)) {
            handleOnFilter(filterData);
            setCurrentFilterData(filterData);
        }
    }, [filterData]);

    const handleOnFilter = useRef(
        debounce((filter) => filterItems(filter), DEBOUNCE_TIME_1500),
    ).current;

    const handleOnPageChange = (page) => {
        setFilterData({ ...filterData, page: page });
    };

    return (
        <Card>
            <Card.Body>
                <DataGrid
                    autoHeight
                    columns={Columns({
                        canDelete,
                        canModify,
                        handleClickDelete,
                        handleClickUpdate,
                    })}
                    components={{
                        LoadingOverlay: LinearProgress,
                        Pagination: CustomPagination,
                    }}
                    componentsProps={{
                        pagination: {
                            rowsPerPageOptions: [25, 50, 75, 100],
                        },
                    }}
                    disableColumnMenu
                    disableSelectionOnClick
                    getRowHeight={() => 'auto'}
                    initialState={{
                        pagination: {
                            pageSize: filterData?.pageSize,
                        },
                    }}
                    loading={isFetching}
                    onPageChange={handleOnPageChange}
                    onPageSizeChange={(newPage) =>
                        setFilterData({ ...filterData, pageSize: newPage })
                    }
                    page={paginationData?.current_page - 1 || 0}
                    pageSize={filterData.pageSize}
                    pagination
                    paginationMode="server"
                    rowCount={rowCount}
                    rows={items}
                />
            </Card.Body>
        </Card>
    );
};

ListItems.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    filterData: PropTypes.object,
    filterItems: PropTypes.func,
    handleClickDelete: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    isFetching: PropTypes.bool,
    items: PropTypes.array,
    paginationData: PropTypes.object,
    setFilterData: PropTypes.func,
};

export default ListItems;
