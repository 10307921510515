import React from 'react';

import { Campaign, School } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import { IconButton } from 'sunwise-ui';

import DollarPriceItem from './DollarPriceItem';
import ProjectsCounterTooltip from './ProjectsCounterTooltip';

const OtherInfo = ({ handleDrawerToggle, oppositeCurrency, subscription }) => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const sxIcon = {
        color: isDarkMode
            ? { sx: '#61666b', md: '#61666b' }
            : { sx: '#1f3c53', md: '#1f3c53' },
    };

    return (
        <>
            <DollarPriceItem
                handleDrawerToggle={handleDrawerToggle}
                oppositeCurrency={oppositeCurrency}
            />

            <IconButton
                href={`${import.meta.env.VITE_ACADEMY_URL}es/`}
                size="small"
                target="_blank"
            >
                <School sx={sxIcon} />
            </IconButton>

            <IconButton
                href={import.meta.env.VITE_NEW_URL}
                rel="noopener noreferrer"
                size="small"
                target="_blank"
            >
                <Campaign sx={sxIcon} />
            </IconButton>

            <ProjectsCounterTooltip
                isDarkMode={isDarkMode}
                isHidden={isNull(subscription?.counters)}
                subscription={subscription}
                sxIcon={sxIcon}
            />
        </>
    );
};

OtherInfo.propTypes = {
    handleDrawerToggle: PropTypes.func,
    oppositeCurrency: PropTypes.object,
    subscription: PropTypes.object,
};

export default OtherInfo;
