import React from 'react';

import CircleIcon from '@mui/icons-material/Circle';
import PropTypes from 'prop-types';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from 'sunwise-ui';

import { numberFormat } from 'common/utils/helpers';

const ProjectStatusTransposedTable = ({
    currencyIso,
    currencyLocale,
    projectsSalesStageData,
}) => {
    return (
        <TableContainer sx={{ overflow: 'hidden' }}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {projectsSalesStageData.rows.projectsNumber[0]}
                        </TableCell>
                        <TableCell>
                            {projectsSalesStageData.rows.projectsTotal[0]}
                        </TableCell>
                        <TableCell>
                            {projectsSalesStageData.rows.projectsKw[0]}
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {projectsSalesStageData.columns.map((column, index) => (
                        <React.Fragment key={`projectsSalesStageData-${index}`}>
                            <TableRow>
                                <TableCell colSpan={3}>
                                    <CircleIcon
                                        sx={{
                                            width: '11px',
                                            height: '11px',
                                            color: column.color,
                                            mr: 1,
                                        }}
                                    />
                                    {column.title}
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>
                                    {numberFormat(
                                        projectsSalesStageData.rows
                                            .projectsNumber[index + 1],
                                        {
                                            decimals: 0,
                                            locale: currencyLocale,
                                            style: 'decimal',
                                        },
                                    )}
                                </TableCell>

                                <TableCell>
                                    {numberFormat(
                                        projectsSalesStageData.rows
                                            .projectsTotal[index + 1],
                                        {
                                            currency: currencyIso,
                                            locale: currencyLocale,
                                            style: 'currency',
                                        },
                                    )}
                                </TableCell>

                                <TableCell>
                                    {numberFormat(
                                        projectsSalesStageData.rows.projectsKw[
                                            index + 1
                                        ],
                                        {
                                            decimals: 0,
                                            locale: currencyLocale,
                                            style: 'decimal',
                                            unit: 'kW',
                                        },
                                    )}
                                </TableCell>
                            </TableRow>
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

ProjectStatusTransposedTable.propTypes = {
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    projectsSalesStageData: PropTypes.object,
};

export default ProjectStatusTransposedTable;
