import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Card, Grid } from 'sunwise-ui';

import DashboardCardHeader from 'common/components/cards/DashboardCardHeader';

import * as actions from '../actions';
import * as selectors from '../selectors';

import ProjectsTotals from './ProjectsTotals';
import ProposalsFilter from './ProposalsFilter';
import ProposalsTotals from './ProposalsTotals';

const CardTotalProposalIssued = ({
    agents,
    currencyIso,
    currencyLocale,
    currencySymbol,
    isFetchingProjectsCounters,
    isFetchingProposalTotals,
    menuOptions,
}) => {
    const { t } = useTranslation();
    const [filterBy, setFilterBy] = useState('proposals');

    const showProposals = filterBy === 'proposals';

    return (
        <Card sx={{ mb: 0, minHeight: '100%', width: '100%' }}>
            <Card.Header id="cart-header-total-proposals-issued">
                <Grid
                    alignContent="center"
                    alignItems="center"
                    container
                    gap={2}
                >
                    <Grid item xs={18} lg={6}>
                        <DashboardCardHeader
                            height="auto"
                            title={
                                showProposals
                                    ? t('Total proposals issued')
                                    : t('Total projects issued')
                            }
                        />
                    </Grid>
                    <ProposalsFilter
                        agents={agents}
                        filterBy={filterBy}
                        setFilterBy={setFilterBy}
                        showProposals={showProposals}
                    />
                    {menuOptions}
                </Grid>
            </Card.Header>

            {showProposals ? (
                <ProposalsTotals
                    currencyIso={currencyIso}
                    currencyLocale={currencyLocale}
                    currencySymbol={currencySymbol}
                    isLoading={isFetchingProposalTotals}
                />
            ) : (
                <ProjectsTotals
                    currencyLocale={currencyLocale}
                    isLoading={isFetchingProjectsCounters}
                />
            )}
        </Card>
    );
};

const mapStateToProps = createStructuredSelector({
    isFetchingProjectsCounters: selectors.getIsFetchingProjectsCounters,
    isFetchingProposalTotals: selectors.getProposalsTotalsIsLoading,
});

const mapDispatchToProps = (dispatch) => ({
    fetchProposalsTotals: (values) =>
        dispatch(actions.fetchProposalsTotals(values)),
});

CardTotalProposalIssued.propTypes = {
    agents: PropTypes.array,
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    currencySymbol: PropTypes.string,
    isFetchingProjectsCounters: PropTypes.bool,
    isFetchingProposalTotals: PropTypes.bool,
    menuOptions: PropTypes.object,
};

const FormContainer = CardTotalProposalIssued;

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
