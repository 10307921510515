import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';

import * as companyGeneralSelectors from '../../../../modules/companyGeneral/selectors';

import MenuItemDropdown from './MenuItemDropdown';

const Container = styled(Box)`
    align-items: center;
    display: flex;
`;

const ProfileImage = styled('img')`
    background-color: #202253;
    border-radius: 50%;
    height: 36px;
    margin-right: 6px;
    object-fit: contain;
    width: 36px;
`;

const BranchOfficesNavBarDropdown = ({ companyImage, multiBranchMode }) => {
    const breakpoint = useBreakpoint();
    if (['xs', 'sm'].includes(breakpoint)) return null;
    return (
        <Container>
            {companyImage && <ProfileImage src={companyImage} />}
            <MenuItemDropdown multiBranchMode={multiBranchMode} />
        </Container>
    );
};

const mapStateToProps = createStructuredSelector({
    companyImage: companyGeneralSelectors.getCompanyImage,
});

BranchOfficesNavBarDropdown.propTypes = {
    companyImage: PropTypes.string,
    multiBranchMode: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
};

export default connect(mapStateToProps, null)(BranchOfficesNavBarDropdown);
