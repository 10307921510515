import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import { getProjectHasAfterSales } from '../../project/selectors';

import savePosition from './savePosition';

export default (
        values,
        uid,
        successCallback = () => {},
        failureCallback = () => {},
    ) =>
    (dispatch, getState) => {
        const state = getState();

        const hasAfterSales = getProjectHasAfterSales(state);

        if (hasAfterSales) {
            const dataAlert = {
                confirmText: i18next.t('Continue'),
                messages: [
                    i18next.t(
                        'This change will not affect the monitoring location. To update it, go to the corresponding section',
                    ),
                ],
                onSuccess: () => {
                    dispatch(
                        savePosition(
                            values,
                            uid,
                            successCallback,
                            failureCallback,
                        ),
                    );
                },
                title: i18next.t('Save location'),
                type: alerts.ALERT_TYPE_CONFIRM,
                variant: 'warning',
            };

            dispatch(alerts.actions.show(dataAlert));
        } else {
            dispatch(
                savePosition(values, uid, successCallback, failureCallback),
            );
        }
    };
