import React from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { types } from 'sunwise-template-core';

import yupResolver from 'common/utils/yupResolver';

import * as actions from '../../actions';
import * as selectors from '../../selectors';
import validate from '../../validate';

import TemplateWizard from './TemplateWizard';

const TemplateForm = ({
    branchSelected,
    filterData,
    initialValues,
    languagesDictionary,
    submitChanges,
}) => {
    const { control, handleSubmit, setValue, watch } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });
    const type = watch('type');
    const handleSave = (values) => submitChanges(values, filterData);

    return (
        <form onSubmit={handleSubmit(handleSave)}>
            <TemplateWizard
                branchSelected={branchSelected}
                control={control}
                isForSummaryTemplate={type === types.MULTIPROPOSAL_TYPE}
                languagesDictionary={languagesDictionary}
                setValue={setValue}
                templateType={type}
            />
        </form>
    );
};

TemplateForm.propTypes = {
    branchSelected: PropTypes.string,
    filterData: PropTypes.object,
    initialValues: PropTypes.object,
    languagesDictionary: PropTypes.object,
    submitChanges: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
});

const mapDispatchToProps = (dispatch) => ({
    submitChanges: (values, filterData) =>
        dispatch(actions.submitChanges(values, filterData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplateForm);
