import { INITIALIZE_COSTING_FORM } from '../actionTypes';
import { proposalGeneratorQuoteActions } from '../reducer';

import resetCostingForm from './resetCostingForm';

export default (values) => (dispatch) => {
    dispatch(resetCostingForm());
    const newValues = {
        final_storage_kwh: values.final_storage_kwh,
        kwh_cost: values.kwh_cost,
        kwh_margin: values.kwh_margin,
        kwh_price: values.kwh_price,
        kwh_total_amount: values.kwh_total_amount,
        system_size: values.system_size,
        price_per_watt_local: values.price_per_watt_local,
        type_change: values.type_change,
        watt_cost: values.watt_cost,
        watt_margin: values.watt_margin,
        watt_price: values.watt_price,
        watt_total_amount: values.watt_total_amount,
    };
    dispatch(proposalGeneratorQuoteActions[INITIALIZE_COSTING_FORM](newValues));
};
