import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_CONFIG,
    FETCH_CONFIG_FAILURE,
    FETCH_CONFIG_SUCCESS,
    FETCH_FONTS,
    FETCH_FONTS_FAILURE,
    FETCH_FONTS_SUCCESS,
    INITIALIZE_FORM,
    RESET_FORM,
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
} from './actionTypes';
import { DEFAULT_VALUES, NAME } from './constants';

const INITIAL_STATE = {
    config: { data: null, errors: null, isFetching: false },
    fonts: { data: null, errors: null, isFetching: false },
    initialValues: DEFAULT_VALUES,
    save: { data: {}, errors: null, isSaving: false },
};

const slice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [SAVE]: (state) => {
            state.save = { ...state.save, errors: [], isSaving: true };
        },
        [SAVE_FAILURE]: (state, action) => {
            state.save = {
                ...state.save,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_SUCCESS]: (state) => {
            state.save = { ...state.save, isSaving: false };
        },
        [FETCH_FONTS]: (state) => {
            state.fonts = { ...state.fonts, errors: [], isFetching: true };
        },
        [FETCH_FONTS_FAILURE]: (state, action) => {
            state.fonts = {
                ...state.fonts,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_FONTS_SUCCESS]: (state, action) => {
            state.fonts = {
                ...state.fonts,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CONFIG]: (state) => {
            state.theme = { ...state.config, errors: [], isFetching: true };
        },
        [FETCH_CONFIG_FAILURE]: (state, action) => {
            state.config = {
                ...state.config,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CONFIG_SUCCESS]: (state, action) => {
            state.config = {
                ...state.config,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
    },
});

export const actions = slice.actions;

export default slice.reducer;
