import React from 'react';

import { styled } from '@mui/material/styles';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Card, Grid, Link, Typography } from 'sunwise-ui';

import DateTooltip from './DateTooltip';
import MenuOptions from './MenuOptions';
import ProposalsTooltip from './ProposalsTooltip';

const Wrapper = styled(Card.Body)(
    ({ theme }) => `
    background: ${
        theme.palette.mode === 'dark'
            ? 'rgb(40, 43, 48)'
            : 'rgba(238, 244, 250, 0.35)'
    };
    border-radius: 8px;
    margin-bottom: 16px;
`,
);

const SummaryItem = ({
    canDelete,
    canModify,
    data,
    handleClickDownloadCSV,
    isArchivedFilter,
}) => {
    const { t } = useTranslation();
    const isArchived = get(data, 'archived', false);
    const isObsolete = get(data, 'obsolete', false);
    const templateName = get(data, 'custom_offer_template.title', null);
    const proposalsNumber = get(
        data,
        'custom_offer_template.proposals_number',
        null,
    );

    return (
        <>
            <Wrapper>
                <Grid container spacing={0}>
                    <Grid item xs sx={{ display: 'flex' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                            }}
                        >
                            <Link
                                color="inherit"
                                component={RouterLink}
                                to={`/summary-review/${data.id}`}
                                underline="none"
                            >
                                <strong>{data.name}</strong>
                            </Link>
                            <Typography variant="body2">
                                {t('Template')} {templateName}
                            </Typography>
                        </Box>
                        <MenuOptions
                            canDelete={canDelete}
                            canModify={canModify}
                            data={data}
                            handleClickDownloadCSV={handleClickDownloadCSV}
                            isArchived={isArchived}
                            isArchivedFilter={isArchivedFilter}
                            isObsolete={isObsolete}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={0}>
                    <Grid item xs={18}>
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 2,
                                mt: 1,
                            }}
                        >
                            <DateTooltip createdAt={data.created_at} />
                            <ProposalsTooltip
                                isObsolete={isObsolete}
                                proposals={[]}
                                proposalsNumber={proposalsNumber}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Wrapper>
        </>
    );
};

SummaryItem.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    data: PropTypes.object,
    handleClickDownloadCSV: PropTypes.func,
    isArchivedFilter: PropTypes.bool,
};

export default SummaryItem;
