import React, { useState } from 'react';

import KeyboardCommandKeyIcon from '@mui/icons-material/KeyboardCommandKey';
import { useTheme } from '@mui/material/styles';
import { useKBar } from 'kbar';
import PropTypes from 'prop-types';
import { Button, IconButton, AppBar, Box, Toolbar } from 'sunwise-ui';

import { useBreakpoint, useOrientation } from 'common/hooks';
import BranchOfficesNavBarDropdown from 'common/modules/multiBranches/components/BranchOfficesNavBarDropdown';

import AfterSalesProjectsStatus from '../../../modules/afterSalesProjectsStatus';
import masterButton from '../../modules/masterButton';
import searchBar from '../../modules/searchBar';

import Logo from './Logo';
import NotificationButton from './NotificationButton';
import NotificationsDrawer from './NotificationsDrawer';
import OtherInfo from './OtherInfo';

const TopBar = ({
    handleDrawerToggle,
    multiBranchMode,
    notificationsQuantity,
    oppositeCurrency,
    redirect,
    searchFieldRef,
    subscription,
}) => {
    const { query } = useKBar();
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const [notificationTab, setNotificationTab] = useState('system');

    const theme = useTheme();
    const breakpoint = useBreakpoint();
    const { isPortrait } = useOrientation();

    const handleToggleDrawer = (open, callback) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        )
            return;
        setIsOpenDrawer(open);
        if (!open) setNotificationTab('system');
        if (callback) callback();
    };
    const isDarkMode = theme.palette.mode === 'dark';
    const sxIcon = {
        color: isDarkMode ? '#61666b' : '#1f3c53',
    };

    return (
        <>
            <Box sx={{ zIndex: 3 }}>
                <AppBar
                    position={
                        ['xs', 'sm'].includes(breakpoint)
                            ? isPortrait
                                ? 'fixed'
                                : 'static'
                            : 'static'
                    }
                    sx={{
                        background: isDarkMode ? '#1B1C1F' : '#fff',
                        boxShadow: isDarkMode ? 'none' : null,
                        color: isDarkMode ? '#b8bfc5' : '#1f3c53',
                        height: '52px',
                        justifyContent: 'center',
                    }}
                >
                    <Toolbar sx={{ px: { md: 2, xs: 1 } }}>
                        <IconButton
                            onClick={handleDrawerToggle}
                            sx={{ display: { md: 'none' } }}
                        >
                            <Logo field="logotype_image" />
                        </IconButton>

                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                gap: 1,
                            }}
                        >
                            <masterButton.Container />

                            <searchBar.Container
                                searchFieldRef={searchFieldRef}
                            />

                            {query?.toggle &&
                                !['xs', 'sm'].includes(breakpoint) && (
                                    <Button
                                        onClick={query.toggle}
                                        variant="outlined"
                                    >
                                        <KeyboardCommandKeyIcon fontSize="inherit" />
                                        k
                                    </Button>
                                )}
                        </Box>

                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                flexGrow: 1,
                                justifyContent: 'flex-end',
                            }}
                        >
                            {!['xs', 'sm'].includes(breakpoint) && (
                                <OtherInfo
                                    oppositeCurrency={oppositeCurrency}
                                    subscription={subscription}
                                />
                            )}

                            <AfterSalesProjectsStatus.Container
                                sxIcon={sxIcon}
                            />

                            <NotificationButton
                                handleToggleDrawer={handleToggleDrawer}
                                quantity={notificationsQuantity}
                                sxIcon={{
                                    ...sxIcon,
                                    ml: { xs: 'auto', lg: 0 },
                                }}
                            />

                            <BranchOfficesNavBarDropdown
                                multiBranchMode={multiBranchMode}
                            />
                        </Box>
                    </Toolbar>
                </AppBar>
            </Box>

            <NotificationsDrawer
                handleToggleDrawer={handleToggleDrawer}
                isOpenDrawer={isOpenDrawer}
                notificationTab={notificationTab}
                redirect={redirect}
                setIsOpenDrawer={setIsOpenDrawer}
                setNotificationTab={setNotificationTab}
            />
        </>
    );
};

TopBar.propTypes = {
    handleDrawerToggle: PropTypes.func,
    multiBranchMode: PropTypes.bool,
    notificationsQuantity: PropTypes.number,
    oppositeCurrency: PropTypes.object,
    redirect: PropTypes.func,
    searchFieldRef: PropTypes.object,
    subscription: PropTypes.object,
};

export default TopBar;
