import React from 'react';

import { Typography } from 'sunwise-ui';

const RateFormatter = (params) => {
    const { row } = params;
    return <Typography variant="caption">{row.rate}</Typography>;
};

RateFormatter.propTypes = {};

export default RateFormatter;
