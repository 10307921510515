import i18next from 'i18next';

export const getCatalogFields = (catalogs, proposalsNumber) =>
    catalogs
        .filter((item) => item.order <= proposalsNumber)
        .map((item) => ({
            company_catalog: item.id,
            name: item.name,
            commercial_offer: '',
        }));

export const getCommercialOffersToSelect = (
    commercialOffers,
    selectedOffers,
) => [
    {
        label: i18next.t('Select proposal'),
        value: '',
        disabled: true,
    },
    ...commercialOffers.map((item) => ({
        disabled: selectedOffers.includes(item.id),
        label: item.name,
        value: item.id,
    })),
];

export const getGeneratedCommercialOffers = (commercialOffers) => {
    let filtered = commercialOffers.map(({ commercial_offer, name }) => ({
        ...commercial_offer[0],
        name,
    }));
    filtered.filter((item) => item.is_archived && item.status === 1);
    return filtered.map(({ id, name }) => ({ id, name }));
};

export const getTemplatesToSelect = (templates) => [
    {
        label: i18next.t('Select a template'),
        value: '',
        disabled: true,
    },
    ...templates.map((item) => ({
        label: item.title,
        value: item.id,
        proposalsNumber: item.proposals_number,
    })),
];

export const getRequirements = (isLoading, commercialOffers, templates) => {
    let data = [];
    if (isLoading) return [];
    if (commercialOffers.length <= 1) {
        data = [
            ...data,
            `${i18next.t('Minimum', {
                context: 'male',
            })} 2 ${i18next
                .t('Generated proposal', { count: 2 })
                .toLowerCase()}`,
        ];
    }
    if (templates.length === 0) {
        data = [
            ...data,
            `${i18next.t('Minimum', {
                context: 'male',
            })} 1 ${i18next.t('Summary template').toLowerCase()}`,
        ];
    }
    return data;
};
