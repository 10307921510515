import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography } from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

const ProjectFormatter = ({ canView, row }) => {
    const { contact: contactId, id: projectId, name } = get(row, 'project', {});
    if (canView)
        return (
            <Link
                color="inherit"
                component={RouterLink}
                to={`record/${contactId}?tab=projects&project=${projectId}&view=proposals`}
                underline="none"
            >
                <Typography variant="caption" fontWeight="bold">
                    {name}
                </Typography>
            </Link>
        );
    return <Typography variant="caption">{name}</Typography>;
};

ProjectFormatter.propTypes = {
    canView: PropTypes.bool,
    row: PropTypes.object,
};

export default withPermissions(PERMISSION_LIST.CONTACT_PROJECTS_PERMISSION)(
    ProjectFormatter,
);
