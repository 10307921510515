import { NAME } from './constants';

export const FETCH_CONFIG = `${NAME}/FETCH_CONFIG`;
export const FETCH_CONFIG_FAILURE = `${NAME}/FETCH_CONFIG_FAILURE`;
export const FETCH_CONFIG_SUCCESS = `${NAME}/FETCH_CONFIG_SUCCESS`;

export const FETCH_FONTS = `${NAME}/FETCH_FONTS`;
export const FETCH_FONTS_FAILURE = `${NAME}/FETCH_FONTS_FAILURE`;
export const FETCH_FONTS_SUCCESS = `${NAME}/FETCH_FONTS_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;
export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;
