import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Card, Grid } from 'sunwise-ui';

import ChartJs from 'common/components/ChartJs';
import KeyIndicator from 'common/components/KeyIndicator';
import PlaceholderTemplateChart from 'common/components/placeholder/PlaceholderTemplateChart';
import { numberFormat } from 'common/utils/helpers';
import { scroll } from 'common/utils/mixins';

import { getChartSettings } from '../helpers';
import * as selectors from '../selectors';

const ProposalsTotals = ({
    currencyIso,
    currencyLocale,
    currencySymbol,
    isLoading,
    totals,
}) => {
    const { t } = useTranslation();
    const {
        categories,
        projects_kws,
        projects_number,
        projects_totals,
        series,
    } = totals;
    const { data, options } = getChartSettings({
        currencyIso,
        currencyLocale,
        currencySymbol,
        labels: categories,
        series,
    });

    return (
        <Card.Body
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 'calc(100% - 73px)',
                overflowY: 'auto',
                ...scroll.object,
            }}
        >
            <Grid container>
                <Grid item lg={13} xl={14} xs={18}>
                    <PlaceholderTemplateChart ready={isLoading}>
                        <ChartJs data={data} options={options} />
                    </PlaceholderTemplateChart>
                </Grid>

                <Grid item xs={18} lg={5} xl={4}>
                    <Box display="flex" flexDirection="column" gap={2}>
                        <KeyIndicator
                            isLoading={isLoading}
                            title={t('Proposals issued')}
                            value={numberFormat(projects_number, {
                                decimals: 0,
                                locale: currencyLocale,
                                style: 'decimal',
                            })}
                            widthIcon="20px"
                        />

                        <KeyIndicator
                            isLoading={isLoading}
                            title={t('Value of the proposals')}
                            value={numberFormat(projects_totals, {
                                currency: currencyIso,
                                locale: currencyLocale,
                                style: 'currency',
                                symbol: currencySymbol,
                            })}
                            widthIcon="20px"
                        />

                        <KeyIndicator
                            isLoading={isLoading}
                            title={t('Power of the proposals')}
                            value={numberFormat(projects_kws, {
                                decimals: 0,
                                locale: currencyLocale,
                                style: 'decimal',
                                unit: 'kW',
                            })}
                            widthIcon="20px"
                        />
                    </Box>
                </Grid>
            </Grid>
        </Card.Body>
    );
};

const mapStateToProps = createStructuredSelector({
    totals: selectors.getProposalsTotalsData,
});

ProposalsTotals.propTypes = {
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    currencySymbol: PropTypes.string,
    isLoading: PropTypes.bool,
    totals: PropTypes.object,
};

export default connect(mapStateToProps, null)(ProposalsTotals);
