import React, { useRef, useState } from 'react';

import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card } from 'sunwise-ui';

import { InputSearch } from 'common/components';
import { DEBOUNCE_TIME_1000 } from 'common/constants';

const PowerStationsFilter = ({ disabled, filterData, setFilterData }) => {
    const [searchText, setSearchText] = useState(filterData?.searchText || '');
    const { t } = useTranslation();

    const handleOnFilter = useRef(
        debounce(
            (searchText) =>
                setFilterData({
                    ...filterData,
                    searchText: searchText,
                    pageNo: 1,
                }),
            DEBOUNCE_TIME_1000,
        ),
    ).current;

    return (
        <Card>
            <Card.Body>
                <InputSearch
                    disabled={disabled}
                    onChange={(e) => {
                        setSearchText(e.target.value);
                        handleOnFilter(e.target.value);
                    }}
                    placeholder={t('Search power station')}
                    value={searchText}
                />
            </Card.Body>
        </Card>
    );
};

PowerStationsFilter.propTypes = {
    disabled: PropTypes.bool,
    filterData: PropTypes.object,
    setFilterData: PropTypes.func,
};

export default PowerStationsFilter;
