import React, { useState } from 'react';

import PropTypes from 'prop-types';

import InputSearch from 'common/components/InputSearch';
import { DEBOUNCE_TIME_500 } from 'common/constants';

const SearchInput = ({ handleOnSearchChange, icon, placeholderSearch }) => {
    const [timer, setTimer] = useState(null);

    const handleChange = (value) => {
        clearTimeout(timer);
        setTimer(setTimeout(() => triggerChange(value), DEBOUNCE_TIME_500));
    };

    const triggerChange = (value) => handleOnSearchChange(value);

    return (
        <InputSearch
            placeholder={placeholderSearch}
            icon={icon}
            onChange={(e) => handleChange(e.target.value)}
        />
    );
};

SearchInput.propTypes = {
    handleOnSearchChange: PropTypes.func,
    icon: PropTypes.string,
    placeholderSearch: PropTypes.string,
};

export default SearchInput;
