import React from 'react';

import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import { Box } from 'sunwise-ui';

const WrraperEdit = ({ children, disabledForm, onClick }) => {
    if (disabledForm)
        return (
            <Box display="flex" justifyContent="flex-end">
                {children}
            </Box>
        );
    return (
        <Box
            id="wrapper"
            display="flex"
            justifyContent="flex-end"
            onClick={onClick}
            sx={{
                cursor: 'pointer',
                ml: 'auto',
                '&:hover .edit-icon': {
                    opacity: 1,
                    transform: 'translateX(0)',
                },
            }}
        >
            <Box
                display="flex"
                alignItems="center"
                sx={{
                    position: 'relative',
                }}
            >
                <Box
                    className="edit-icon"
                    sx={{
                        position: 'absolute',
                        left: '-18px',
                        opacity: 0,
                        transform: 'translateX(-10px)',
                        transition: 'opacity 0.3s, transform 0.3s',
                    }}
                >
                    <EditIcon sx={{ fontSize: 14 }} />
                </Box>
                {children}
            </Box>
        </Box>
    );
};

WrraperEdit.propTypes = {
    children: PropTypes.object,
    disabledForm: PropTypes.bool,
    onClick: PropTypes.func,
};

export default WrraperEdit;
