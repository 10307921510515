import React from 'react';

import PropTypes from 'prop-types';
import { Box, Skeleton, Typography } from 'sunwise-ui';

import { numberFormat } from 'common/utils/helpers';

import SubtotalForm from './SubtotalForm';
import WrraperEdit from './WrraperEdit';

const SubtotalValue = ({
    currencyIso,
    currencyLocale,
    currencySymbol,
    disabledForm,
    isFetching,
    isHidden = false,
    isProductCosting,
    proposalId,
    setSubtotal2Editable,
    subtotal,
    subtotal2Editable,
    value,
}) => {
    if (isHidden) return null;

    if (isFetching)
        return (
            <Box display="flex" justifyContent="flex-end">
                <Skeleton
                    variant="text"
                    sx={{
                        fontSize: '1rem',
                        width: 200,
                    }}
                />
            </Box>
        );

    if (subtotal2Editable)
        return (
            <SubtotalForm
                currencySymbol={currencySymbol}
                isProductCosting={isProductCosting}
                proposalId={proposalId}
                setEditable={setSubtotal2Editable}
                subtotal={subtotal}
            />
        );

    return (
        <WrraperEdit
            disabledForm={disabledForm}
            onClick={() => {
                if (!disabledForm) {
                    setSubtotal2Editable(true);
                }
            }}
        >
            <Typography
                sx={{
                    fontWeight: { xs: 'normal', lg: 'bold' },
                }}
                variant="body2"
            >
                {numberFormat(value, {
                    currency: currencyIso,
                    decimals: 2,
                    locale: currencyLocale,
                    style: 'currency',
                })}
            </Typography>
        </WrraperEdit>
    );
};

SubtotalValue.propTypes = {
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    currencySymbol: PropTypes.string,
    disabledForm: PropTypes.bool,
    isFetching: PropTypes.bool,
    isHidden: PropTypes.bool,
    isProductCosting: PropTypes.bool,
    proposalId: PropTypes.string,
    setSubtotal2Editable: PropTypes.func,
    subtotal: PropTypes.number,
    subtotal2Editable: PropTypes.bool,
    value: PropTypes.number,
};

export default SubtotalValue;
