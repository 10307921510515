import React, { useCallback } from 'react';

import { debounce, get, toNumber } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from 'sunwise-ui';

import { ReactHookFormIntlNumberInput } from 'common/components/form/bootstrap';
import TitleWithDetail from 'common/components/TitleWithDetail';
import { DEBOUNCE_TIME_1000 } from 'common/constants';

import PriceRangeDropDown from './PriceRangeDropDown';

const OpexRangesFields = ({
    canModify,
    control,
    currencyIso,
    currencyLocale,
    currencySymbol,
    isLocked,
    opexActive,
    opexCostingRanges,
    opexCostingType,
    setValue,
    watch,
}) => {
    const { t } = useTranslation();

    const handleChangekwAmount = (value) => {
        if (!value) return false;
        const newValue = value * watch('kw_opex_system_size');
        setValue('kw_opex_total_amount', toNumber(newValue).toFixed(2));
    };

    const handleChangekwhAmount = (value) => {
        if (!value) return false;
        const newValue = value * watch('kwh_opex_storage_size');
        setValue('kwh_opex_total_amount', toNumber(newValue).toFixed(2));
    };

    const handleDebouncedChangekwAmount = useCallback(
        debounce((value) => handleChangekwAmount(value), DEBOUNCE_TIME_1000),
        [],
    );

    const handleDebouncedChangekwhAmount = useCallback(
        debounce((value) => handleChangekwhAmount(value), DEBOUNCE_TIME_1000),
        [],
    );

    if (opexCostingType === 'by_fixed_amount') return null;

    return (
        <>
            <Box sx={{ mb: 2 }}>
                <TitleWithDetail variant="body2">
                    {t('Cost per kW')}
                </TitleWithDetail>
            </Box>

            <Grid container>
                <Grid item xs={18} md={6}>
                    <Box
                        alignItems="center"
                        display="flex"
                        sx={{ width: '100%' }}
                    >
                        <ReactHookFormIntlNumberInput
                            append={currencyIso}
                            control={control}
                            disabled={isLocked || !opexActive || !canModify}
                            fullWidth
                            label={t('Price')}
                            name="kw_opex_amount"
                            onChange={({ target: { value } }) =>
                                handleDebouncedChangekwAmount(value)
                            }
                            prepend={currencySymbol}
                            step="any"
                        />
                        <PriceRangeDropDown
                            currencyIso={currencyIso}
                            currencyLocale={currencyLocale}
                            currencySymbol={currencySymbol}
                            ranges={get(
                                opexCostingRanges,
                                'cost_ranges_kw',
                                [],
                            )}
                        />
                    </Box>
                </Grid>
                <Grid item xs={18} md={6}>
                    <ReactHookFormIntlNumberInput
                        allowNegativeValue={false}
                        append="kW"
                        control={control}
                        disabled
                        fullWidth
                        label={t('System size')}
                        min={0}
                        name="kw_opex_system_size"
                        step="any"
                    />
                </Grid>
                <Grid item xs={18} md={6}>
                    <ReactHookFormIntlNumberInput
                        allowNegativeValue={false}
                        append={currencyIso}
                        control={control}
                        disabled
                        fullWidth
                        label={t('Subtotal')}
                        min={0}
                        name="kw_opex_total_amount"
                        prepend={currencySymbol}
                        step="any"
                    />
                </Grid>
            </Grid>

            <Box sx={{ mb: 2 }}>
                <TitleWithDetail variant="body2">
                    {t('Cost per kWh')}
                </TitleWithDetail>
            </Box>

            <Grid container>
                <Grid item xs={18} md={6}>
                    <Box
                        alignItems="center"
                        display="flex"
                        sx={{ width: '100%' }}
                    >
                        <ReactHookFormIntlNumberInput
                            append={currencyIso}
                            control={control}
                            disabled={isLocked || !opexActive || !canModify}
                            fullWidth
                            label={t('Price')}
                            name="kwh_opex_amount"
                            onChange={({ target: { value } }) =>
                                handleDebouncedChangekwhAmount(value)
                            }
                            prepend={currencySymbol}
                            step="any"
                        />
                        <PriceRangeDropDown
                            currencyIso={currencyIso}
                            currencyLocale={currencyLocale}
                            currencySymbol={currencySymbol}
                            ranges={get(
                                opexCostingRanges,
                                'cost_ranges_kwh',
                                [],
                            )}
                        />
                    </Box>
                </Grid>
                <Grid item xs={18} md={6}>
                    <ReactHookFormIntlNumberInput
                        allowNegativeValue={false}
                        append="kWh"
                        control={control}
                        disabled
                        fullWidth
                        label={t('Storage size')}
                        min={0}
                        name="kwh_opex_storage_size"
                        step="any"
                    />
                </Grid>
                <Grid item xs={18} md={6}>
                    <ReactHookFormIntlNumberInput
                        allowNegativeValue={false}
                        append={currencyIso}
                        control={control}
                        disabled
                        fullWidth
                        label={t('Subtotal')}
                        min={0}
                        prepend={currencySymbol}
                        name="kwh_opex_total_amount"
                        step="any"
                    />
                </Grid>
            </Grid>
        </>
    );
};

OpexRangesFields.propTypes = {
    canModify: PropTypes.bool,
    control: PropTypes.object,
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    currencySymbol: PropTypes.string,
    isLocked: PropTypes.bool,
    opexActive: PropTypes.bool,
    opexCostingRanges: PropTypes.object,
    opexCostingType: PropTypes.string,
    setValue: PropTypes.func,
    watch: PropTypes.func,
};

export default OpexRangesFields;
