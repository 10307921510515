import { createSlice } from '@reduxjs/toolkit';

import orm from 'common/orm';
import { formatDate, subDate } from 'common/utils/dates';

import {
    FETCH_AGENTS,
    FETCH_AGENTS_FAILURE,
    FETCH_AGENTS_SUCCESS,
    FETCH_COMMERCIAL_OFFERS,
    FETCH_COMMERCIAL_OFFERS_FAILURE,
    FETCH_COMMERCIAL_OFFERS_SUCCESS,
    FETCH_COMMERCIAL_OFFERS_BY_RATE,
    FETCH_COMMERCIAL_OFFERS_BY_RATE_FAILURE,
    FETCH_COMMERCIAL_OFFERS_BY_RATE_SUCCESS,
    FETCH_CONTACTS_TRACKINGS,
    FETCH_CONTACTS_TRACKINGS_FAILURE,
    FETCH_CONTACTS_TRACKINGS_SUCCESS,
    FETCH_CONTACTS_TRACKINGS_AGENTS,
    FETCH_CONTACTS_TRACKINGS_AGENTS_FAILURE,
    FETCH_CONTACTS_TRACKINGS_AGENTS_SUCCESS,
    FETCH_PROJECTS_COUNTERS,
    FETCH_DASHBOARD_CONFIG,
    FETCH_DASHBOARD_CONFIG_FAILURE,
    FETCH_DASHBOARD_CONFIG_SUCCESS,
    FETCH_PROJECTS_COUNTERS_FAILURE,
    FETCH_PROJECTS_COUNTERS_SUCCESS,
    FETCH_PROJECTS_SALES_STAGE,
    FETCH_PROJECTS_SALES_STAGE_FAILURE,
    FETCH_PROJECTS_SALES_STAGE_SUCCESS,
    FETCH_PROPOSALS_TOTALS,
    FETCH_PROPOSALS_TOTALS_FAILURE,
    FETCH_PROPOSALS_TOTALS_SUCCESS,
    FETCH_RATES,
    FETCH_RATES_FAILURE,
    FETCH_RATES_SUCCESS,
    INITIALIZE_FORM,
    SAVE_DASHBOARD_CONFIG,
    SAVE_DASHBOARD_CONFIG_FAILURE,
    SAVE_DASHBOARD_CONFIG_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const initialDate = formatDate(subDate(new Date(), { years: 1 }), 'dd/MM/yyyy');
const finalDate = formatDate(new Date(), 'dd/MM/yyyy');

const INITIAL_STATE = {
    entities: orm.getEmptyState(),
    agents: {
        data: [],
        error: null,
        isEmpty: false,
        isLoading: false,
    },
    dashboardConfig: {
        data: {},
        errors: [],
        isFetching: true,
    },
    rates: {
        errors: [],
        isLoading: false,
    },
    commercialOffers: {
        data: [],
        error: null,
        isEmpty: false,
        isLoading: false,
    },
    contactsTrackings: {
        data: [],
        error: null,
        isEmpty: false,
        isLoading: false,
    },
    initialValues: {
        initial_date: initialDate,
        final_date: finalDate,
        agent: '',
    },
    lastContacts: {
        data: [],
        error: null,
        isEmpty: false,
        isLoading: false,
    },
    project: {
        data: {},
        error: null,
        isEmpty: false,
        isLoading: false,
    },
    projectLocations: {
        data: [],
        error: null,
        isEmpty: false,
        isLoading: false,
    },
    projects: {
        data: [],
        error: null,
        isEmpty: false,
        isLoading: false,
    },
    projectStatusType: {
        data: [],
        error: null,
        isEmpty: false,
        isLoading: false,
    },
    projectsCounters: {
        data: {
            categories: [],
            series: [],
            projects_totals: 0,
        },
        error: null,
        isEmpty: false,
        isLoading: false,
    },
    projectsSalesStage: {
        data: [],
        error: null,
        isEmpty: false,
        isLoading: false,
    },
    proposalsTotals: {
        data: {
            categories: [],
            series: [],
            projects_number: 0,
            projects_totals: 0,
            projects_kws: 0,
        },
        error: null,
        isEmpty: false,
        isLoading: false,
    },
    saveDashboardConfig: {
        errors: [],
        isSaving: false,
    },
    selectedStatusProject: null,
};

const dashboardSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_AGENTS]: (state) => {
            state.agents.isLoading = true;
        },
        [FETCH_AGENTS_FAILURE]: (state, action) => {
            state.agents = {
                ...state.agents,
                error: action.payload,
                isLoading: false,
            };
        },
        [FETCH_AGENTS_SUCCESS]: (state, action) => {
            state.agents = {
                ...state.agents,
                data: action.payload,
                isLoading: false,
            };
        },
        [FETCH_DASHBOARD_CONFIG]: (state) => {
            state.dashboardConfig.isFetching = true;
        },
        [FETCH_DASHBOARD_CONFIG_FAILURE]: (state, action) => {
            state.dashboardConfig = {
                ...state.dashboardConfig,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_DASHBOARD_CONFIG_SUCCESS]: (state, action) => {
            state.dashboardConfig = {
                ...state.dashboardConfig,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_COMMERCIAL_OFFERS]: (state) => {
            state.commercialOffers.isLoading = true;
        },
        [FETCH_COMMERCIAL_OFFERS_FAILURE]: (state, action) => {
            state.commercialOffers = {
                ...state.commercialOffers,
                error: action.payload,
                isLoading: false,
            };
        },
        [FETCH_COMMERCIAL_OFFERS_SUCCESS]: (state, action) => {
            state.commercialOffers = {
                ...state.commercialOffers,
                data: action.payload,
                isLoading: false,
            };
        },
        [FETCH_COMMERCIAL_OFFERS_BY_RATE]: (state) => {
            state.commercialOffers.isLoading = true;
        },
        [FETCH_COMMERCIAL_OFFERS_BY_RATE_FAILURE]: (state, action) => {
            state.commercialOffers = {
                ...state.commercialOffers,
                error: action.payload,
                isLoading: false,
            };
        },
        [FETCH_COMMERCIAL_OFFERS_BY_RATE_SUCCESS]: (state, action) => {
            state.commercialOffers = {
                ...state.commercialOffers,
                data: action.payload,
                isLoading: false,
            };
        },
        [FETCH_CONTACTS_TRACKINGS]: (state) => {
            state.contactsTrackings.isLoading = true;
        },
        [FETCH_CONTACTS_TRACKINGS_FAILURE]: (state, action) => {
            state.contactsTrackings = {
                ...state.contactsTrackings,
                error: action.payload,
                isLoading: false,
            };
        },
        [FETCH_CONTACTS_TRACKINGS_SUCCESS]: (state, action) => {
            state.contactsTrackings = {
                ...state.contactsTrackings,
                data: action.payload,
                isEmpty: action.payload.length === 0,
                isLoading: false,
            };
        },
        [FETCH_CONTACTS_TRACKINGS_AGENTS]: (state) => {
            state.contactsTrackings.isLoading = true;
        },
        [FETCH_CONTACTS_TRACKINGS_AGENTS_FAILURE]: (state, action) => {
            state.contactsTrackings = {
                ...state.contactsTrackings,
                error: action.payload,
                isLoading: false,
            };
        },
        [FETCH_CONTACTS_TRACKINGS_AGENTS_SUCCESS]: (state, action) => {
            state.contactsTrackings = {
                ...state.contactsTrackings,
                data: action.payload,
                isEmpty: action.payload.length === 0,
                isLoading: false,
            };
        },
        [FETCH_PROJECTS_COUNTERS]: (state) => {
            state.projectsCounters.isLoading = true;
        },
        [FETCH_PROJECTS_COUNTERS_FAILURE]: (state, action) => {
            state.projectsCounters = {
                ...state.projectsCounters,
                error: action.payload,
                isLoading: false,
            };
        },
        [FETCH_PROJECTS_COUNTERS_SUCCESS]: (state, action) => {
            state.projectsCounters = {
                ...state.projectsCounters,
                data: action.payload,
                isLoading: false,
            };
        },
        [FETCH_PROJECTS_SALES_STAGE]: (state) => {
            state.projectsSalesStage.isLoading = true;
        },
        [FETCH_PROJECTS_SALES_STAGE_FAILURE]: (state, action) => {
            state.projectsSalesStage = {
                ...state.projectsSalesStage,
                error: action.payload,
                isLoading: false,
            };
        },
        [FETCH_PROJECTS_SALES_STAGE_SUCCESS]: (state, action) => {
            state.projectsSalesStage = {
                ...state.projectsSalesStage,
                data: action.payload,
                isLoading: false,
            };
        },
        [FETCH_PROPOSALS_TOTALS]: (state) => {
            state.proposalsTotals.isLoading = true;
        },
        [FETCH_PROPOSALS_TOTALS_FAILURE]: (state, action) => {
            state.proposalsTotals = {
                ...state.proposalsTotals,
                error: action.payload,
                isLoading: false,
            };
        },
        [FETCH_PROPOSALS_TOTALS_SUCCESS]: (state, action) => {
            state.proposalsTotals = {
                ...state.proposalsTotals,
                data: action.payload,
                isLoading: false,
            };
        },
        [FETCH_RATES]: (state) => {
            state.rates = { ...state.rates, errors: [], isLoading: true };
        },
        [FETCH_RATES_FAILURE]: (state, action) => {
            state.rates = {
                ...state.rates,
                errors: action.payload,
                isLoading: false,
            };
        },
        [FETCH_RATES_SUCCESS]: (state, action) => {
            const session = orm.session(state.entities);
            const { Rate } = session;

            Rate.all().delete();
            action.payload.forEach((element) => {
                Rate.generate(element);
            });

            state.rates.isLoading = false;
            state.entities = session.state;
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [SAVE_DASHBOARD_CONFIG]: (state) => {
            state.saveDashboardConfig.isSaving = true;
        },
        [SAVE_DASHBOARD_CONFIG_FAILURE]: (state, action) => {
            state.saveDashboardConfig = {
                ...state.saveDashboardConfig,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_DASHBOARD_CONFIG_SUCCESS]: (state) => {
            state.saveDashboardConfig.isSaving = false;
        },
    },
});

export const dashboardActions = dashboardSlice.actions;

export default dashboardSlice.reducer;
