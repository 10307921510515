import React, { useEffect, useState } from 'react';

import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Box, Card, Tab, Tabs, TabPanel } from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { validateAccessWithPermissions } from 'common/utils/helpers/permissionsV2';
import parseQueryString from 'common/utils/parseQueryString';

import projectCommercialOfferTable from '../projectCommercialOfferTable';
import proposalSummaryTable from '../proposalSummaryTable';

const Container = ({
    getPermissionsByCode,
    history,
    location,
    projectId,
    projectName,
}) => {
    const { t } = useTranslation();
    const { search: locationSearch = '' } = location;
    const { type = 'proposals', view = 'proposals' } =
        parseQueryString(locationSearch);
    const [selectedTab, setSelectedTab] = useState(type);

    const handleChange = (_, newValue) => {
        setSelectedTab(newValue);
        history.push({
            pathname: location.pathname,
            search: `?tab=projects&project=${projectId}&view=${view}&type=${newValue}`,
        });
    };

    const showProposalsTabs = validateAccessWithPermissions([
        PERMISSION_LIST.CONTACT_PROPOSALS_PERMISSION,
        PERMISSION_LIST.PROPOSAL_SUMMARIES_PERMISSION,
    ]);

    const { canView: canViewProposals } = getPermissionsByCode(
        PERMISSION_LIST.CONTACT_PROPOSALS_PERMISSION,
    );
    const { canView: canViewSummaries } = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_SUMMARIES_PERMISSION,
    );

    useEffect(() => {
        switch (type) {
            case 'proposals':
                if (canViewProposals) handleChange(null, type);
                else handleChange(null, 'summaries');
                break;
            case 'summaries':
                if (canViewSummaries) handleChange(null, type);
                else if (canViewProposals) handleChange(null, 'proposals');
                else handleChange(null, 'none');
                break;
            default:
                handleChange(null, 'none');
                break;
        }
    }, [type]);

    if (isNull(projectId) || !showProposalsTabs) return null;

    return (
        <Card>
            <Card.Body>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={selectedTab} onChange={handleChange}>
                        {canViewProposals && (
                            <Tab
                                key="tab-proposals"
                                id="tab-proposals"
                                label={t('Proposal', { count: 2 })}
                                value="proposals"
                            />
                        )}
                        {canViewSummaries && (
                            <Tab
                                key="tab-summaries"
                                id="tab-summaries"
                                label={t('Summary', { count: 2 })}
                                value="summaries"
                            />
                        )}
                    </Tabs>
                </Box>

                {canViewProposals && (
                    <TabPanel
                        key="tab-panel-proposals"
                        selectedTab={selectedTab}
                        value="proposals"
                    >
                        <projectCommercialOfferTable.Container
                            projectId={projectId}
                            projectName={projectName}
                        />
                    </TabPanel>
                )}

                {canViewSummaries && (
                    <TabPanel
                        key="tab-panel-summaries"
                        selectedTab={selectedTab}
                        value="summaries"
                    >
                        <proposalSummaryTable.Container
                            projectId={projectId}
                            projectName={projectName}
                        />
                    </TabPanel>
                )}
            </Card.Body>
        </Card>
    );
};

Container.propTypes = {
    getPermissionsByCode: PropTypes.func,
    history: PropTypes.object,
    location: PropTypes.object,
    projectId: PropTypes.string,
    projectName: PropTypes.string,
};

export default compose(
    withPermissions([
        PERMISSION_LIST.CONTACT_PROPOSALS_PERMISSION,
        PERMISSION_LIST.PROPOSAL_SUMMARIES_PERMISSION,
    ]),
    withRouter,
)(Container);
