import React, { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import { createStructuredSelector } from 'reselect';
import { Box, Chip, Grid, Skeleton, Stack } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';

import PdfDocument from 'resources/pdfDocument.svg';

import { sliderSettings } from '../constants';
import { getCarouselTemplates, getCategories } from '../helpers';
import * as selectors from '../selectors';

import DefaultTemplatePreview from './DefaultTemplatePreview';

const Container = styled(Box)`
    display: flex;
    margin: 32px 0 0 0;

    .slick-slider {
        padding: 0 32px;
        width: 100%;

        .slick-next {
            right: 0;
        }

        .slick-prev {
            left: 0;
        }
    }
`;

const TemplateCarousel = ({
    branchSelected,
    canModify,
    carouselTemplatesData,
    handleClickCreate,
    isFetchingTemplates,
    selectedCountry,
    templateCategories,
    templateType,
}) => {
    const breakpoint = useBreakpoint();
    const [items, setItems] = useState(6);
    const [selectedCategory, setSelectedCategory] = useState(null);

    useEffect(() => {
        if (['xs', 'sm'].includes(breakpoint)) setItems(2);
        if (['md'].includes(breakpoint)) setItems(3);
        setItems(6);
    }, [breakpoint]);

    useEffect(() => {
        setSelectedCategory(null);
    }, [templateType]);

    const handleOnClickChip = (categoryId) => {
        if (categoryId === selectedCategory) {
            setSelectedCategory(null);
            return;
        }
        setSelectedCategory(categoryId);
    };

    const carouselTemplates = getCarouselTemplates(
        carouselTemplatesData,
        selectedCategory,
        templateType,
    );

    const categories = getCategories(
        getCarouselTemplates(carouselTemplatesData, null, templateType),
        templateCategories,
    );

    if (!selectedCountry) return null;

    if (isFetchingTemplates)
        return (
            <Grid container mb={2}>
                {Array.from(new Array(items)).map((_, index) => (
                    <Grid item key={`skeleton-${index}`} lg={3} md={6} xs={9}>
                        <Skeleton height={218} variant="rounded" />
                    </Grid>
                ))}
            </Grid>
        );

    if (isEmpty(carouselTemplatesData)) return null;

    return (
        <>
            {categories.length > 0 && (
                <Stack direction="row" spacing={0} flexWrap="wrap" gap="8px">
                    {categories.map((category) => {
                        return (
                            <Chip
                                key={category.id}
                                label={category.name}
                                onClick={() => handleOnClickChip(category.id)}
                                variant={
                                    selectedCategory === category.id
                                        ? 'default'
                                        : 'outlined'
                                }
                            />
                        );
                    })}
                </Stack>
            )}

            {carouselTemplates.length > 0 && (
                <Container id="templates-carousel">
                    <Slider {...sliderSettings}>
                        {carouselTemplates.map((carouselTemplate) => (
                            <DefaultTemplatePreview
                                canModify={canModify}
                                onClick={() =>
                                    canModify &&
                                    handleClickCreate(
                                        branchSelected,
                                        carouselTemplate,
                                        templateType,
                                    )
                                }
                                imgSrc={
                                    carouselTemplate?.image_thumbnail
                                        ? carouselTemplate.image_thumbnail
                                        : PdfDocument
                                }
                                isLocked={carouselTemplate.locked === 1}
                                isNew={carouselTemplate.is_new}
                                isOustanding={carouselTemplate.is_featured}
                                key={carouselTemplate.id}
                                title={carouselTemplate.title}
                            />
                        ))}
                    </Slider>
                </Container>
            )}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    carouselTemplatesData: selectors.getCarouselTemplatesData,
    isFetchingTemplates: selectors.getIsFetchingCarouselTemplates,
    templateCategories: selectors.getTemplateCategoriesData,
});

TemplateCarousel.propTypes = {
    branchSelected: PropTypes.string,
    canModify: PropTypes.bool,
    carouselTemplatesData: PropTypes.object,
    handleClickCreate: PropTypes.func,
    isFetchingTemplates: PropTypes.bool,
    selectedCountry: PropTypes.string,
    templateCategories: PropTypes.array,
    templateType: PropTypes.number,
};

export default connect(mapStateToProps, null)(TemplateCarousel);
