import React, { useEffect, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid } from 'sunwise-ui';

import { InfoToolTip } from 'common/components';
import {
    ReactHookFormInput,
    ReactHookFormInputDatePicker,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';
import { DEBOUNCE_TIME_500 } from 'common/constants';
import { getIsOnlyContacts } from 'common/utils/helpers/session';

import * as actions from '../actions';
import * as selectors from '../selectors';

import InfoList from './InfoList';

const MIN_DATE = new Date(2005, 0, 1);

const Filters = ({
    advisors,
    fetchAdvisors,
    fetchFinanciers,
    filterQuery,
    financiers,
    setFilterQuery,
}) => {
    const { t } = useTranslation();
    const [timer, setTimer] = useState(null);
    const initialValues = { ...filterQuery };
    const isOnlyContacts = getIsOnlyContacts();

    const { control, reset } = useForm({ defaultValues: initialValues });

    useEffect(() => reset(initialValues), []);

    const handleChange = (value) => {
        clearTimeout(timer);
        setTimer(
            setTimeout(
                () => handleOnChangeValue(value, 'search'),
                DEBOUNCE_TIME_500,
            ),
        );
    };

    const handleOnChangeValue = (value, name) =>
        setFilterQuery((prev) => ({ ...prev, [name]: value }));

    useEffect(() => {
        fetchAdvisors();
        fetchFinanciers();
    }, []);

    return (
        <form>
            <Grid container>
                <Grid item xs={18} md={6}>
                    <ReactHookFormInput
                        control={control}
                        label={' '}
                        name="search"
                        onChange={(e) => handleChange(e.target.value)}
                        placeholder={t('Search')}
                        prepend={<SearchIcon />}
                        variant="standard"
                    />
                </Grid>

                {!isOnlyContacts && (
                    <Grid item xs={18} md>
                        <ReactHookFormSelect
                            control={control}
                            name="user"
                            label={t('User')}
                            onChange={(e) =>
                                handleOnChangeValue(e.target.value, 'user')
                            }
                            options={advisors}
                            variant="standard"
                        />
                    </Grid>
                )}

                <Grid item xs={18} md>
                    <ReactHookFormSelect
                        control={control}
                        name="financier"
                        label={t('Financier')}
                        onChange={(e) =>
                            handleOnChangeValue(e.target.value, 'financier')
                        }
                        options={financiers}
                        variant="standard"
                    />
                </Grid>

                <Grid item xs={18} md>
                    <ReactHookFormInputDatePicker
                        control={control}
                        disableFuture
                        label={t('Initial date')}
                        minDate={MIN_DATE}
                        name="initial_date"
                        onChange={(e) => handleOnChangeValue(e, 'initial_date')}
                        variant="standard"
                    />
                </Grid>

                <Grid item xs={18} md>
                    <ReactHookFormInputDatePicker
                        control={control}
                        disableFuture
                        label={t('Final date')}
                        minDate={MIN_DATE}
                        name="final_date"
                        onChange={(e) => handleOnChangeValue(e, 'final_date')}
                        variant="standard"
                    />
                </Grid>

                <Grid item sx={{ display: 'flex' }}>
                    <InfoToolTip variant="no-wrapper">
                        <InfoList />
                    </InfoToolTip>
                </Grid>
            </Grid>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    advisors: selectors.getAdvisorsToSelect,
    financiers: selectors.getFinanciersToSelect,
});

const mapDispatchToProps = (dispatch) => ({
    fetchAdvisors: () => dispatch(actions.fetchAdvisors()),
    fetchFinanciers: () => dispatch(actions.fetchFinanciers()),
});

Filters.propTypes = {
    advisors: PropTypes.array,
    fetchAdvisors: PropTypes.func,
    fetchFinanciers: PropTypes.func,
    filterQuery: PropTypes.object,
    financiers: PropTypes.array,
    setFilterQuery: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
