import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import OpexArrayRanges from './OpexArrayRanges';

const TabOpexCostRanges = ({
    control,
    currencyIso,
    currencySymbol,
    getValues,
    isDisabled,
    opexActive,
    setValue,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <OpexArrayRanges
                control={control}
                currencyIso={currencyIso}
                currencySymbol={currencySymbol}
                getValues={getValues}
                isDisabled={isDisabled || !opexActive}
                name="opex_cost_ranges_kw"
                setValue={setValue}
                title={t('Cost per kW')}
            />
            <OpexArrayRanges
                control={control}
                currencyIso={currencyIso}
                currencySymbol={currencySymbol}
                getValues={getValues}
                isDisabled={isDisabled || !opexActive}
                name="opex_cost_ranges_kwh"
                setValue={setValue}
                title={t('Cost per kWh')}
            />
        </>
    );
};

TabOpexCostRanges.propTypes = {
    control: PropTypes.object,
    currencyIso: PropTypes.string,
    currencySymbol: PropTypes.string,
    getValues: PropTypes.func,
    isDisabled: PropTypes.bool,
    opexActive: PropTypes.bool,
    setValue: PropTypes.func,
};

export default TabOpexCostRanges;
