import React, { useEffect, useRef, useState } from 'react';

import { debounce, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Box, TextField } from 'sunwise-ui';

import { filterContacts } from 'common/api/v1/contacts';
import { DEBOUNCE_TIME_1500 } from 'common/constants';

const ContactSelector = ({ onSubmit, values }) => {
    const { t } = useTranslation();
    const [options, setOptions] = useState([values?.tmpContact ?? {}]);
    const [value, setValue] = useState('');

    const handleOnFilter = useRef(
        debounce((filter) => filterItems(filter), DEBOUNCE_TIME_1500),
    ).current;

    useEffect(() => {
        handleOnFilter(value);
    }, [value]);

    useEffect(() => {
        filterItems('');
        if (values.tmpContact)
            setOptions((prev) => [...prev, values.tmpContact]);
    }, []);

    const filterOptions = (x) => x;
    const filterItems = async (value) => {
        filterContacts({
            orderBy: 'name',
            perPage: 100,
            searchText: value,
            sortBy: 'asc',
        }).then((contacts) => {
            const map = contacts.data.data.map((item) => {
                return {
                    branch_office: item.branch_office?.id ?? null,
                    label: `${item.name} ${item.first_lastname}`,
                    value: item.id,
                };
            });
            setOptions(map);
        });
    };
    const getOptionLabel = (option) => option?.label || '';
    const isOptionEqualToValue = (option, selected) =>
        selected && !isEmpty(selected) && option?.value === selected.value;
    const onChange = (_, value) => {
        let contact = null;
        if (value && !isEmpty(value))
            contact = { branch_office: value.branch_office, id: value.value };
        onSubmit('tmpContact', value);
        onSubmit('contact', contact);
    };
    const onInputChange = (_, newValue) =>
        setValue(newValue === t('Create contact') ? '' : newValue);
    const renderInput = (params) => (
        <TextField
            {...params}
            inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
            }}
            label={t('Search contact')}
        />
    );
    const renderOption = (props, option) => (
        <Box component="li" {...props} key={option?.value ?? 'empty'}>
            {option.label}
        </Box>
    );

    const fieldValue = values?.tmpContact || null;

    return (
        <Autocomplete
            filterOptions={filterOptions}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            onChange={onChange}
            onInputChange={onInputChange}
            options={[
                { label: t('Create contact'), value: 'new-contact' },
                ...options,
            ]}
            renderInput={renderInput}
            renderOption={renderOption}
            value={fieldValue}
        />
    );
};

ContactSelector.propTypes = {
    onSubmit: PropTypes.func,
    values: PropTypes.object,
};

export default ContactSelector;
