import i18next from 'i18next';
import get from 'lodash/get';

import { archiveItem } from 'common/api/v1/proposalsSummary';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    ARCHIVE_SUMMARY,
    ARCHIVE_SUMMARY_FAILURE,
    ARCHIVE_SUMMARY_SUCCESS,
} from '../actionTypes';
import { proposalSummaryActions } from '../reducer';

import fetchSummaries from './fetchSummaries';

export default (values, isArchivedFilter) => (dispatch) => {
    const id = get(values, 'id', null);
    const projectId = get(values.project, 'id', null);

    const message = values.archived
        ? i18next.t('Successfully unarchived')
        : i18next.t('Successfully archived');

    dispatch(proposalSummaryActions[ARCHIVE_SUMMARY]());

    archiveItem(id)
        .then(() => {
            dispatch(proposalSummaryActions[ARCHIVE_SUMMARY_SUCCESS]());
            dispatch(alerts.actions.close());
            dispatch(
                fetchSummaries({ isArchived: isArchivedFilter, projectId }),
            );
            showToast({
                body: message,
            });
        })
        .catch((error) => {
            dispatch(
                proposalSummaryActions[ARCHIVE_SUMMARY_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
