import React, { useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import proposalGeneratorTaxesTable from '../../proposalGeneratorTaxesTable';

import ConceptValue from './ConceptValue';
import DiscountValue from './DiscountValue';
import SubtotalValue from './SubtotalValue';

const ConceptRow = ({ children, isHidden = false }) => {
    if (isHidden) return null;
    return <>{children}</>;
};

ConceptRow.propTypes = {
    children: PropTypes.object,
    isHidden: PropTypes.bool,
};

const Totals = ({
    currencyIso,
    currencyLocale,
    currencySymbol,
    customerMode = false,
    getPermissionsByCode,
    isFetching,
    isLocked,
    isProductCosting,
    offerDiscount,
    profit,
    profitPercentage,
    proposalId,
    showTaxIcon = true,
    subtotal,
    subtotalWithDiscount,
    total,
    totalCost,
    totalTaxes,
}) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [discountEditable, setDiscountEditable] = useState(false);
    const [subtotal2Editable, setSubtotal2Editable] = useState(false);

    const { canModify: canModifyCost } = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_PRODUCTS_COST_PERMISSION,
    );
    const { canModify: canModifyMargin } = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_PRODUCTS_MARGIN_PERMISSION,
    );
    const { canModify: canModifyBreakdown } = getPermissionsByCode(
        PERMISSION_LIST.SEE_FINAL_BREAKDOWN,
    );

    const { discount_amount, discount_percentage } = offerDiscount;
    const hasIncrement = discount_percentage < 0;
    const discountPercentage = hasIncrement
        ? discount_percentage * -1
        : discount_percentage;

    if (!canModifyBreakdown) return null;

    return (
        <>
            <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table size="small">
                    <TableBody>
                        <ConceptRow isHidden={customerMode || !canModifyCost}>
                            <TableRow sx={{ '.MuiTableCell-body': { py: 1 } }}>
                                <TableCell>
                                    <Typography
                                        fontWeight="bold"
                                        variant="body2"
                                    >
                                        {t('Cost')}
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <ConceptValue
                                        currencyIso={currencyIso}
                                        currencyLocale={currencyLocale}
                                        isFetching={isFetching}
                                        value={totalCost}
                                    />
                                </TableCell>
                            </TableRow>
                        </ConceptRow>
                        <TableRow
                            sx={{
                                backgroundColor: 'action.hover',
                                '.MuiTableCell-body': { py: 1 },
                            }}
                        >
                            <TableCell>
                                <Typography fontWeight="bold" variant="body2">
                                    {t('Subtotal')}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <ConceptValue
                                    currencyIso={currencyIso}
                                    currencyLocale={currencyLocale}
                                    isFetching={isFetching}
                                    value={subtotal}
                                />
                            </TableCell>
                        </TableRow>
                        <ConceptRow isHidden={customerMode}>
                            <TableRow sx={{ '.MuiTableCell-body': { py: 1 } }}>
                                <TableCell>
                                    <Typography
                                        fontWeight="bold"
                                        variant="body2"
                                    >
                                        {hasIncrement
                                            ? t('Increase')
                                            : t('Discount')}{' '}
                                        ({discountPercentage}%)
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <DiscountValue
                                        currencyIso={currencyIso}
                                        currencyLocale={currencyLocale}
                                        currencySymbol={currencySymbol}
                                        disabledForm={
                                            isFetching ||
                                            isLocked ||
                                            subtotal2Editable
                                        }
                                        discountEditable={discountEditable}
                                        hasIncrement={hasIncrement}
                                        isFetching={isFetching}
                                        proposalId={proposalId}
                                        setDiscountEditable={
                                            setDiscountEditable
                                        }
                                        subtotal={subtotal}
                                        value={
                                            hasIncrement
                                                ? discount_amount * -1
                                                : discount_amount
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                        </ConceptRow>
                        <ConceptRow isHidden={customerMode}>
                            <TableRow
                                sx={{
                                    backgroundColor: 'action.hover',
                                    '.MuiTableCell-body': { py: 1 },
                                }}
                            >
                                <TableCell>
                                    <Typography
                                        fontWeight="bold"
                                        variant="body2"
                                    >
                                        {`${t('Subtotal')} (2)`}
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <SubtotalValue
                                        currencyIso={currencyIso}
                                        currencyLocale={currencyLocale}
                                        currencySymbol={currencySymbol}
                                        disabledForm={
                                            isFetching ||
                                            isLocked ||
                                            discountEditable
                                        }
                                        isFetching={isFetching}
                                        isProductCosting={isProductCosting}
                                        proposalId={proposalId}
                                        setSubtotal2Editable={
                                            setSubtotal2Editable
                                        }
                                        subtotal={subtotal}
                                        subtotal2Editable={subtotal2Editable}
                                        value={subtotalWithDiscount}
                                    />
                                </TableCell>
                            </TableRow>
                        </ConceptRow>
                        <TableRow>
                            <TableCell>
                                <Box alignItems="center" display="flex" gap={2}>
                                    <Typography
                                        fontWeight="bold"
                                        variant="body2"
                                    >
                                        {t('Tax', { count: 2 })}
                                    </Typography>
                                    {showTaxIcon && (
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => setOpen(!open)}
                                        >
                                            {open ? (
                                                <KeyboardArrowUpIcon />
                                            ) : (
                                                <KeyboardArrowDownIcon />
                                            )}
                                        </IconButton>
                                    )}
                                </Box>
                            </TableCell>
                            <TableCell align="right">
                                <ConceptValue
                                    currencyIso={currencyIso}
                                    currencyLocale={currencyLocale}
                                    isFetching={isFetching}
                                    value={totalTaxes}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={2}
                            >
                                <Collapse
                                    in={open}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <proposalGeneratorTaxesTable.Container
                                        currencyIso={currencyIso}
                                        currencyLocale={currencyLocale}
                                        currencySymbol={currencySymbol}
                                        isLoading={isFetching}
                                        isLocked={isFetching || isLocked}
                                        proposalId={proposalId}
                                    />
                                </Collapse>
                            </TableCell>
                        </TableRow>
                        <TableRow
                            sx={{
                                backgroundColor: 'action.hover',
                                '.MuiTableCell-body': { py: 1 },
                            }}
                        >
                            <TableCell>
                                <Typography fontWeight="bold" variant="body2">
                                    {t('Total')}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <ConceptValue
                                    currencyIso={currencyIso}
                                    currencyLocale={currencyLocale}
                                    isFetching={isFetching}
                                    value={total}
                                />
                            </TableCell>
                        </TableRow>
                        <ConceptRow isHidden={customerMode || !canModifyMargin}>
                            <TableRow sx={{ '.MuiTableCell-body': { py: 1 } }}>
                                <TableCell>
                                    <Typography
                                        fontWeight="bold"
                                        variant="body2"
                                    >
                                        {`${t('Profit')} (${profitPercentage}%)`}
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <ConceptValue
                                        currencyIso={currencyIso}
                                        currencyLocale={currencyLocale}
                                        isFetching={isFetching}
                                        value={profit}
                                    />
                                </TableCell>
                            </TableRow>
                        </ConceptRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

Totals.propTypes = {
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    currencySymbol: PropTypes.string,
    customerMode: PropTypes.bool,
    getPermissionsByCode: PropTypes.func,
    isFetching: PropTypes.bool,
    isLocked: PropTypes.bool,
    isProductCosting: PropTypes.bool,
    offerDiscount: PropTypes.object,
    profit: PropTypes.number,
    profitPercentage: PropTypes.number,
    proposalId: PropTypes.string,
    showTaxIcon: PropTypes.bool,
    subtotal: PropTypes.number,
    subtotalWithDiscount: PropTypes.number,
    total: PropTypes.number,
    totalCost: PropTypes.number,
    totalTaxes: PropTypes.number,
};

export default withPermissions([
    PERMISSION_LIST.PROPOSAL_PRODUCTS_COST_PERMISSION,
    PERMISSION_LIST.PROPOSAL_PRODUCTS_MARGIN_PERMISSION,
    PERMISSION_LIST.SEE_FINAL_BREAKDOWN,
])(Totals);
