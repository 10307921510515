import React, { useEffect, useRef, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Grow, Popper } from '@mui/material';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import {
    Button,
    ButtonGroup,
    Paper,
    ClickAwayListener,
    MenuList,
    MenuItem,
} from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { useBreakpoint } from 'common/hooks';
import { arraytoDictionary } from 'common/utils/helpers';

import CreateContact from './components/CreateContact';
import CreateProject from './components/CreateProject';
import CreateProposal from './components/CreateProposal';
import CreateTracking from './components/CreateTracking';
import { BUTTONS_ACTIONS } from './constants';
import {
    getShowMainButton,
    handleSelectButton,
    validateButtonBasedOnPermissions,
} from './helpers';

const getOptions = ({
    canModifyProjects,
    canModifyProposals,
    canModifyContacts,
    canModifyTrackings,
}) => [
    {
        button: BUTTONS_ACTIONS.CREATE_PROPOSAL,
        component: ({ onExited, onSucccess }) => (
            <CreateProposal onExited={onExited} onSucccess={onSucccess} />
        ),
        label: i18next.t('Create proposal'),
        visible: canModifyProposals,
    },
    {
        button: BUTTONS_ACTIONS.CREATE_PROJECT,
        component: ({ onExited, onSucccess }) => (
            <CreateProject onExited={onExited} onSucccess={onSucccess} />
        ),
        label: i18next.t('Create project'),
        visible: canModifyProjects,
    },
    {
        button: BUTTONS_ACTIONS.CREATE_CONTACT,
        component: ({ onExited, onSucccess }) => (
            <CreateContact onExited={onExited} onSucccess={onSucccess} />
        ),
        label: i18next.t('Create contact'),
        visible: canModifyContacts,
    },
    {
        button: BUTTONS_ACTIONS.CREATE_TRACKING,
        component: ({ onExited, onSucccess }) => (
            <CreateTracking onExited={onExited} onSucccess={onSucccess} />
        ),
        label: i18next.t('Create tracking'),
        visible: canModifyTrackings,
    },
];

const Container = ({
    defaultButton = BUTTONS_ACTIONS.CREATE_PROJECT,
    getPermissionsByCode,
    masterButtonMode = true,
    onSucccess = null,
    variantButton = 'outlined',
}) => {
    const breakpoint = useBreakpoint();
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [RenderComponent, setRenderComponent] = useState(null);
    const location = useLocation();
    const [selectedButton, setSelectedButton] = useState(defaultButton);

    const { canModify: canModifyProjects } = getPermissionsByCode(
        PERMISSION_LIST.CONTACT_PROJECTS_PERMISSION,
    );
    const { canModify: canModifyProposals } = getPermissionsByCode(
        PERMISSION_LIST.CONTACT_PROPOSALS_PERMISSION,
    );
    const { canModify: canModifyContacts } = getPermissionsByCode(
        PERMISSION_LIST.CONTACTS_PERMISSION,
    );
    const { canModify: canModifyTrackings } = getPermissionsByCode(
        PERMISSION_LIST.TRACKING_CONTACTS_PERMISSION,
    );

    const options = getOptions({
        canModifyProjects,
        canModifyProposals,
        canModifyContacts,
        canModifyTrackings,
    });
    const dictionaryOptions = arraytoDictionary(options, 'button');

    const handleValidateAction = (action) =>
        validateButtonBasedOnPermissions(
            action,
            canModifyContacts,
            canModifyProjects,
            canModifyProposals,
            canModifyTrackings,
        );

    useEffect(() => {
        if (masterButtonMode) {
            handleSelectButton(
                defaultButton,
                handleValidateAction,
                setSelectedButton,
            );
        }
    }, [location.pathname, location.search]);

    const handleMainClick = () =>
        handleMenuItemClick(dictionaryOptions[selectedButton]);

    const handleMenuItemClick = ({ component }) => {
        if (component)
            setRenderComponent(
                component({
                    onExited: () => setRenderComponent(null),
                    onSucccess,
                }),
            );
        setOpen(false);
    };

    const handleToggleMenu = () => setOpen((prevOpen) => !prevOpen);

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target))
            return;
        setOpen(false);
    };

    const showMainButton = getShowMainButton(
        canModifyContacts,
        canModifyProjects,
        canModifyProposals,
        canModifyTrackings,
        defaultButton,
        masterButtonMode,
        options,
    );

    return (
        <>
            {showMainButton && (
                <ButtonGroup ref={anchorRef}>
                    <Button
                        onClick={handleMainClick}
                        variant={variantButton}
                        sx={{ whiteSpace: 'nowrap' }}
                    >
                        {['xs', 'sm'].includes(breakpoint) ? (
                            <AddIcon />
                        ) : (
                            dictionaryOptions[selectedButton]?.label
                        )}
                    </Button>

                    {masterButtonMode && (
                        <Button onClick={handleToggleMenu}>
                            <ArrowDropDownIcon />
                        </Button>
                    )}
                </ButtonGroup>
            )}

            <Popper
                anchorEl={anchorRef.current}
                disablePortal
                open={open}
                role={undefined}
                sx={{ zIndex: 1 }}
                transition
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom'
                                    ? 'center top'
                                    : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem>
                                    {options
                                        .filter(({ visible }) => visible)
                                        .map((option) => (
                                            <MenuItem
                                                key={option.label}
                                                onClick={() =>
                                                    handleMenuItemClick(option)
                                                }
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>

            {RenderComponent}
        </>
    );
};

Container.propTypes = {
    defaultButton: PropTypes.string,
    getPermissionsByCode: PropTypes.func,
    masterButtonMode: PropTypes.bool,
    onSucccess: PropTypes.func,
    variantButton: PropTypes.string,
};

export default withPermissions([
    PERMISSION_LIST.CONTACT_PROJECTS_PERMISSION,
    PERMISSION_LIST.CONTACT_PROPOSALS_PERMISSION,
    PERMISSION_LIST.CONTACTS_PERMISSION,
    PERMISSION_LIST.TRACKING_CONTACTS_PERMISSION,
])(Container);
