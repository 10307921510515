import { number, object } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const {
        getMaxValueText,
        getMinValueText,
        NUMBER_TYPE_TEXT,
        REQUIRED_TEXT,
    } = getValidationTexts();
    return object().shape({
        watt_cost: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .min(0, getMinValueText(0))
            .nullable(),
        watt_margin: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .min(0, getMinValueText(0))
            .max(100, getMaxValueText(100))
            .nullable(),
        watt_price: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .min(0, getMinValueText(0))
            .nullable(),
        watt_total_amount: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .min(0, getMinValueText(0))
            .nullable(),
        kwh_cost: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .min(0, getMinValueText(0))
            .nullable(),
        kwh_margin: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .min(0, getMinValueText(0))
            .max(100, getMaxValueText(100))
            .nullable(),
        kwh_price: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .min(0, getMinValueText(0))
            .nullable(),
        kwh_total_amount: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .min(0, getMinValueText(0))
            .nullable(),
    });
};
