import React from 'react';

import { Typography } from 'sunwise-ui';

import { formatDate, parseDate } from 'common/utils/dates';
import { getDateFormatByLocale } from 'common/utils/helpers/multiregion';

const DateFormatter = (params) => {
    const { row } = params;
    return (
        <Typography variant="caption">
            {`${formatDate(
                parseDate(row.created_at, 'dd/MM/yyyy HH:mm:ss'),
                getDateFormatByLocale(),
            )}`}
        </Typography>
    );
};

export default DateFormatter;
