import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getConfigModel = createSelector(getModel, (model) => model.config);

export const getConfigData = createSelector(
    getConfigModel,
    (model) => model?.data?.configs || {},
);

export const getFontModel = createSelector(getModel, (model) => model.fonts);

export const getFontData = createSelector(
    getFontModel,
    (model) =>
        model.data?.map(({ name }) => ({
            label: name,
            value: name,
        })) || [],
);

export const getFontIsFetching = createSelector(
    getFontModel,
    (model) => model.isFetching,
);

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues,
);
