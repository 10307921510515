import React, { useEffect, useRef, useState } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { Card, DataGrid } from 'sunwise-ui';

import CustomPagination from 'common/components/CustomPagination';
import { DEBOUNCE_TIME_1500 } from 'common/constants';
import CustomColumns from 'common/modules/customColumns';
import { getOrderedColumns, getHiddenColumns } from 'common/utils/helpers';

import { COLUMNS_CONFIG } from '../constants';

import BulkOperationsCard from './BulkOperationsCard';
import Columns from './Columns';

const ListItems = ({
    filterData,
    filterItems,
    handleClickBulkItems,
    handleClickView,
    isFetching,
    items,
    paginationData,
    rates,
    setFilterData,
    tableSettings,
    users,
}) => {
    const [rowCount, setRowCount] = useState(paginationData?.rowCount || 0);
    const [rowModesModel, setRowModesModel] = useState({});
    const [selectionModel, setSelectionModel] = useState([]);

    useEffect(() => {
        if (paginationData)
            setRowCount((prevRowCount) =>
                paginationData?.total_rows !== undefined
                    ? paginationData?.total_rows
                    : prevRowCount,
            );
    }, [paginationData?.total_rows, setRowCount]);

    useEffect(() => {
        handleOnFilter(filterData);
    }, [filterData]);

    const handleOnFilter = useRef(
        debounce((filter) => filterItems(filter), DEBOUNCE_TIME_1500),
    ).current;

    const handleClickEndDate = (value) => {
        setFilterData({
            ...filterData,
            endDate: value,
        });
    };

    const handleClickStartDate = (value) => {
        setFilterData({
            ...filterData,
            startDate: value,
        });
    };

    const handleOnChangeSearch = (event) => {
        setFilterData({
            ...filterData,
            page: 0,
            searchText: event.target.value,
        });
    };

    const handleOnPageChange = (page) => {
        setFilterData({ ...filterData, page: page });
    };

    const handleOnSelectRate = (item) => {
        setFilterData({
            ...filterData,
            rateName: item.value,
        });
    };

    const handleOnSelectStatus = (item) => {
        const filter = {
            ...filterData,
            status: item.value,
            page: 0,
        };
        setFilterData(filter);
    };

    const handleOnSelectStorage = (item) => {
        const filter = {
            ...filterData,
            storageRange: item.value,
            page: 0,
        };
        setFilterData(filter);
    };

    const handleOnSelectSystem = (item) => {
        const filter = {
            ...filterData,
            systemRange: item.value,
            page: 0,
        };
        setFilterData(filter);
    };

    const handleOnSelectUser = (item) => {
        const filter = {
            ...filterData,
            createdBy: item.value,
            page: 0,
        };
        setFilterData(filter);
    };

    const handleOnSortModelChange = (sortModel) => {
        if (!sortModel[0]) return;

        setFilterData({
            ...filterData,
            orderBy: sortModel[0].field.toLocaleLowerCase(),
            sortBy: sortModel[0].sort,
        });
    };

    return (
        <>
            <BulkOperationsCard
                filterData={filterData}
                handleClickBulkItems={handleClickBulkItems}
                handleClickEndDate={handleClickEndDate}
                handleClickStartDate={handleClickStartDate}
                handleOnChangeSearch={handleOnChangeSearch}
                handleOnSelectRate={handleOnSelectRate}
                handleOnSelectStatus={handleOnSelectStatus}
                handleOnSelectStorage={handleOnSelectStorage}
                handleOnSelectSystem={handleOnSelectSystem}
                handleOnSelectUser={handleOnSelectUser}
                initialDates={{
                    start_date: filterData.startDate,
                    end_date: filterData.endDate,
                }}
                rates={rates}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
                users={users}
            />
            <Card>
                <Card.Body>
                    <CustomColumns.Container
                        columnsConfig={COLUMNS_CONFIG}
                        tableKey="proposals"
                    />
                    <DataGrid
                        autoHeight
                        checkboxSelection={false}
                        columns={Columns({
                            handleClickView,
                            hiddenColumns: getHiddenColumns(tableSettings),
                            orders: getOrderedColumns(tableSettings),
                        })}
                        components={{
                            LoadingOverlay: LinearProgress,
                            Pagination: CustomPagination,
                        }}
                        componentsProps={{
                            pagination: {
                                rowsPerPageOptions: [25, 50, 75, 100],
                            },
                        }}
                        disableColumnMenu
                        disableSelectionOnClick
                        getRowHeight={() => 'auto'}
                        initialState={{
                            pagination: {
                                pageSize: filterData?.pageSize,
                            },
                        }}
                        loading={isFetching}
                        onPageChange={handleOnPageChange}
                        onPageSizeChange={(newPage) =>
                            setFilterData({ ...filterData, pageSize: newPage })
                        }
                        onRowModesModelChange={(newModel) =>
                            setRowModesModel(newModel)
                        }
                        onSelectionModelChange={(newModel) =>
                            setSelectionModel(newModel)
                        }
                        onSortModelChange={handleOnSortModelChange}
                        page={paginationData?.current_page - 1 || 0}
                        pageSize={filterData.pageSize}
                        pagination
                        paginationMode="server"
                        rowCount={rowCount}
                        rowModesModel={rowModesModel}
                        rows={items}
                        selectionModel={selectionModel}
                        sortingMode="server"
                    />
                </Card.Body>
            </Card>
        </>
    );
};

ListItems.propTypes = {
    filterData: PropTypes.object,
    filterItems: PropTypes.func,
    handleClickBulkItems: PropTypes.func,
    handleClickView: PropTypes.func,
    isFetching: PropTypes.bool,
    items: PropTypes.array,
    paginationData: PropTypes.object,
    rates: PropTypes.array,
    setFilterData: PropTypes.func,
    tableSettings: PropTypes.array,
    users: PropTypes.array,
};

export default ListItems;
