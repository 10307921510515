import openModal from './openModal';
import resetForm from './resetForm';
import resetProducts from './resetProducts';
import setBulkAction from './setBulkAction';

export default () => (dispatch) => {
    dispatch(setBulkAction(null));
    dispatch(resetForm());
    dispatch(resetProducts());
    dispatch(openModal(false));
};
