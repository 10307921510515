import { getThemeConfig } from 'common/api/v1/templates';

import {
    FETCH_CONFIG,
    FETCH_CONFIG_FAILURE,
    FETCH_CONFIG_SUCCESS,
    INITIALIZE_FORM,
} from '../actionTypes';
import { actions } from '../reducer';

export default () => (dispatch) => {
    dispatch(actions[FETCH_CONFIG]());

    getThemeConfig().then(
        (response) => {
            dispatch(actions[FETCH_CONFIG_SUCCESS](response.data));
            dispatch(actions[INITIALIZE_FORM](response.data.configs));
        },
        (error) => {
            dispatch(actions[FETCH_CONFIG_FAILURE](error?.response?.data));
        },
    );
};
