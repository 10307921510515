import React from 'react';

import { TableCell } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';

import { ReactHookFormIntlNumberInputConsumption } from 'common/components/form/bootstrap';
import { getBilledDemandFromEditableFormat } from 'common/utils/helpers/rates';
import { COLOR_DELTA, generateColorTone } from 'common/utils/helpersChart';

import { BASE_COLORS } from '../../constants';
import { getCellInputStyle } from '../../helpers';

import CollapsedElements from './CollapsedElements';

const DemandCells = ({
    control,
    demandFields,
    demandTiers,
    disabled,
    handleOnPaste,
    hasBottomRightRadius,
    inputVariant,
    isCollapsed,
    isHourlyRate,
    name,
    period,
    selectedRate,
    sxWarning,
}) => (
    <CollapsedElements
        collapsedItem={
            <TableCell
                sx={{
                    ...getCellInputStyle(BASE_COLORS.DEMAND),
                    borderBottomRightRadius: hasBottomRightRadius ? '10px' : 0,
                }}
            >
                {getBilledDemandFromEditableFormat({
                    finalDate: period?.final_date,
                    initialDate: period?.initial_date,
                    rate: selectedRate,
                    total: period?.total,
                    values: Object.values(demandFields),
                })}
            </TableCell>
        }
        isCollapsed={isCollapsed}
    >
        {!isHourlyRate && (
            <TableCell
                align="center"
                sx={{
                    ...getCellInputStyle(BASE_COLORS.DEMAND),
                    borderBottomRightRadius: hasBottomRightRadius ? '10px' : 0,
                }}
            >
                <ReactHookFormIntlNumberInputConsumption
                    control={control}
                    disabled={disabled}
                    fullWidth
                    label=""
                    name={`${name}.kW.peak`}
                    onPaste={(event) => handleOnPaste(event, 'kW.peak')}
                    sx={sxWarning}
                    variant={inputVariant}
                />
            </TableCell>
        )}

        {isHourlyRate &&
            demandTiers?.map((tier, column) => {
                const tierKey = tier.name.toLowerCase();

                const bgColor = generateColorTone(
                    BASE_COLORS.DEMAND,
                    column * COLOR_DELTA,
                );
                const isLastCell = column + 1 === demandTiers.length;
                const style = getCellInputStyle(bgColor);

                return (
                    <TableCell
                        align="center"
                        key={tier.identifier}
                        sx={{
                            ...style,
                            borderBottomRightRadius:
                                hasBottomRightRadius && isLastCell ? '10px' : 0,
                        }}
                    >
                        {demandFields[tierKey] && (
                            <ReactHookFormIntlNumberInputConsumption
                                control={control}
                                disabled={disabled}
                                fullWidth
                                label=""
                                name={`${name}.kW.${tierKey}`}
                                onPaste={(event) =>
                                    handleOnPaste(event, `kW.${tierKey}`)
                                }
                                sx={sxWarning}
                                variant={inputVariant}
                            />
                        )}
                    </TableCell>
                );
            })}
    </CollapsedElements>
);

DemandCells.propTypes = {
    control: PropTypes.object,
    demandFields: PropTypes.object,
    demandTiers: PropTypes.array,
    disabled: PropTypes.bool,
    handleOnPaste: PropTypes.func,
    hasBottomRightRadius: PropTypes.bool,
    inputVariant: PropTypes.string,
    isCollapsed: PropTypes.bool,
    isHourlyRate: PropTypes.bool,
    name: PropTypes.string,
    period: PropTypes.object,
    selectedRate: PropTypes.object,
    sxWarning: PropTypes.object,
};

export default DemandCells;
